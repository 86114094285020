import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import axios from "axios";
export interface ZipCodeState {
    success: string;
    zipCode: number
    distance: number
}

const initialState: ZipCodeState = {
    success: "",
    zipCode: 0,
    distance: 0
}

export const ameZipCode = createAsyncThunk(
    "ameZipCode/zipCode",
    async (data: ZipCodeState, thunkAPI) => {
        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `https://zipcodedownload.com/Radius?firstzipcode=${data.zipCode}&radiuscoverage=${data.distance}&format=json&key=e5077780bde74f9f994dddcc1e8102c5`,
            };

            const response = await axios.request(config);

            if (response.data) {
                return response.data;
            } else {
                return thunkAPI.rejectWithValue(response.data);
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ success: false, error: error.message });
        }
    }
);

export const ameZipCodeSlice = createSlice({
    name: "ameZipCode",
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(ameZipCode.pending, (state: any) => {
                state.status = false;
            })
            .addCase(ameZipCode.fulfilled, (state: any, action: any) => {
                state.status = true;
                state.error = "";
                state.response = action.payload;
            })
            .addCase(ameZipCode.rejected, (state: any, action: any) => {
                state.status = false;
                state.error = action.error.message;
            });
    },
});

export const userList = (state: RootState) => state.zipCode;
export default ameZipCodeSlice.reducer;
