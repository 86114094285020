import React, { RefObject, useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../hook";
import { toast } from "react-toastify";
import { cancelPlan, currentPlan } from "../../../Features/PlansSlice";
import { Loader } from "../RepeatedComponent/Loader";
import { forgotEmail } from "../../../Features/authSlice";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import "./retrieveUserIdPopUp.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { event } from "jquery";

export const RetrieveUserIdPopUp = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(0);
  const closeRef: RefObject<HTMLImageElement> = useRef(null);
  const defaultValue ={
    firstName: "",
    lastName: "",
  };

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .matches(/^[A-Za-z]+$/, "First Name must contain only letters")
      .required("First Name is required"),
    lastName: yup
      .string()
      .matches(/^[A-Za-z]+$/, "Last Name must contain only letters")
      .required("Last Name is required"),
  });

  const handleSubmit = (
    values: any,
    { resetForm }: { resetForm: () => void }
  ) => {
    setLoading(1);
    dispatch(forgotEmail(values))
      .then((res: any) => {
        if (res.payload.success) {
          toast.success(`Your Email ID is ${res.payload.response}`, {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 8000,
            pauseOnFocusLoss: true,
            theme: "dark",
            // onClose: () => navigate("/login"),
          });
          // Reset form values
          resetForm();
          if (closeRef.current !== null) {
            closeRef.current.click();
          }
        } else {
          toast.error(" Unfortunately, No Matching Email Address Was Found.", {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 8000,
            pauseOnFocusLoss: true,
            theme: "dark",
          });
        }
        setLoading(0);
      })
      .catch((err) => console.log(err, "error in api net"));
  };

  const register = () => {
    navigate("/register");
    if (closeRef.current !== null) {
      closeRef.current.click();
    }
  };

  return (
    <>
      {loading ? <Loader /> : ""}
      <Formik
        initialValues={defaultValue}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        // enableReinitialize
      >
        <div
          className="modal fade forget-popup"
          id="retrieveUserId"
          role="dialog"
          aria-labelledby="retrieveUserId"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content cancel-Subscription">
              <div className="modal-header">
                <div className="col-12 d-flex font-inter p-0">
                  <h4
                    className="modal-title font-inter"
                    id="cancelSubscriptionsLabel"
                  >
                    Enter Your User Information
                  </h4>
                </div>
                <div className="cross-img-black">
                  <img
                    src="images/modal-img/cross-img-black.svg"
                    className="close custom-close "
                    data-dismiss="modal"
                    aria-label="Close"
                    ref={closeRef}
                    style={{ height: "4rem" }}
                    alt="Close"
                  ></img>
                </div>
              </div>
              <Form>
                <div className="modal-body text-left">
                  <strong>
                    We will help you retrieve your User Id in just a few steps.
                  </strong>
                  <div className="pt-3">
                    <div className="pb-2">
                      <Field
                        type="text"
                        id="first-name-2"
                        name="firstName"
                        style={{
                          border: "none",
                          borderBottom: "1px solid #F16722",
                        }}
                        className=" w-100 inputs pt-1"
                        placeholder="First Name"
                      />
                      <p className="text-danger field-errorMessage mb-3 text-left ">
                        <ErrorMessage name="firstName" />
                      </p>
                    </div>
                    <div className="pb-3">
                      <Field
                        type="text"
                        id="last-name-2"
                        name="lastName"
                        className=" w-100 inputs"
                        style={{
                          border: "none",
                          borderBottom: "1px solid #F16722",
                        }}
                        placeholder="Last Name"
                      />
                      <p className="text-danger field-errorMessage mb-3 text-left ">
                        <ErrorMessage name="lastName" />
                      </p>
                    </div>
                  </div>
                  <div className="">
                    If you do not have a subscription with us, please
                    <strong
                      onClick={register}
                      style={{ color: "#F16722", cursor: "pointer" }}
                    >
                      {" "}
                      register
                    </strong>{" "}
                    first
                  </div>
                </div>
                <div className="modal-footer border-0">
                  <button type="button" className="btn " data-dismiss="modal">
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    // data-dismiss="modal"
                    // aria-label="Close"
                  >
                    Continue
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Formik>
    </>
  );
};
