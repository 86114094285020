import React, { useEffect } from "react";
import "./loader.css";
export const Loader = () => {
  
  return (
    <>
      <div className=" loader-body">
        <div className="col-12 custom-loader"></div>
      </div>
    </>
  );
};
export {};
