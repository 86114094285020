import { configureStore } from '@reduxjs/toolkit';
import authSlice from './Features/authSlice';
import downloadHistorySlice from './Features/downloadHistorySlice';
import fetchUserSlice from './Features/fetchUserSlice';
import downloadFileSlice from './Features/downloadFileSlice';
import exportsRecordsSlice from './Features/exportsRecordsSlice';
import searchSlice from './Features/searchSlice';
import ameZipCodeSlice from './Features/ameZipCodeSlice';
import pilotRecordSlice from './Features/pilotRecordSlice';
import PlansSlice from './Features/PlansSlice';
import contactUsSlice from './Features/contactUsSlice';
export const store = configureStore({
  reducer: {
    auth: authSlice,
    downloadHistory:downloadHistorySlice,
    user:fetchUserSlice,
    downloadFile:downloadFileSlice,
    exportsRecords:exportsRecordsSlice,
    search:searchSlice,
    zipCode:ameZipCodeSlice,
    pilotRecords:pilotRecordSlice,
    plans:PlansSlice,
    contactUs:contactUsSlice
    // Add other reducers as needed
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;