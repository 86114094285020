import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { toast } from "react-toastify";
import * as yup from "yup";
import "./ameHealthPopup.css";
import { useAppDispatch, useAppSelector } from "../../../hook";
import { Loader } from "./Loader";
import { Field, Formik, Form } from "formik";
import { exportsRecordsCertificate } from "../../../Features/exportsRecordsSlice";
import { userList } from "../../../Features/fetchUserSlice";
import { ameZipCode } from "../../../Features/ameZipCodeSlice";
import { currentPlan, plans } from "../../../Features/PlansSlice";
import { TaskStatus } from "../../../Features/pilotRecordSlice";

interface CheckboxState {
  firstClass: boolean;
  secondClass: boolean;
  thirdClass: boolean;
  airTrafficController: boolean;
  aircraftMechanics: boolean;
  parachuteRiggers: boolean;
  dropZoneManagement: boolean;
  jumpPilots: boolean;
  [key: string]: boolean;
}

interface Pilot {
  firstClass: boolean;
  secondClass: boolean;
  thirdClass: boolean;
  allClasses: boolean;
}

interface Payload {
  region_type: string;
  last_name: string;
  last_name_check: string;
  first_name: string;
  first_name_check: string;
  address: string;
  address_check: string;
  city_name: string;
  city_name_check: string;
  zip_code: string[];
  certificate_expire_date: string;
  airman_type:string,
  airman_class?: string[];
  expire: string;
  sort_type: string;
  sort_type_order: string;
  export_file: string;
  task_type: string;
}
const animatedComponents = makeAnimated();
export const AMEHealthPopup = () => {
  const [distance, setDistance] = useState<any>({});
  const closeRef: any = useRef(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const { values } = useFormikContext();
  const [zip_code, setZipCode] = useState([""]);
  const [loading, setLoading] = useState(0);
  const [selectedTab, setSelectedTab] = useState("pilot");
  const [selectedCSV, setSelectedCSV] = useState("CSV");
  const [selectedOneMonth, setselectedOneMonth] = useState("30 Days");
  const [subscriptionMonth, setSubscriptionMonth] = useState<any>(0);
  const [zipCodeDefalut, setZipCodeDefalut] = useState("");
  const activeSubscription = useAppSelector(
    (state: any) => state.plans.message
  );
  const export_work = useAppSelector(userList);

  const [zipData2, setZipData2] = useState<any>({
    zipCode: 0,
    distance: 0,
  });

  var intervalId: any = "";
  const taskStatus = useAppSelector(
    (state: any) => state.exportsRecords.taskStatus
  );
  const loggedin_user = useAppSelector((state: any) => state.user.user);
  // const { task_log_id} = taskStatus;

  // const status = useAppSelector((state: any) => state.auth.status);

  const allPlans: any = useAppSelector(
    (state: any) => state.plans.subscriptions
  );

  const activePlan: any = useAppSelector((state: any) => state.plans.message);

  let zipData: any = {
    zipCode: "",
    distance: "",
  };

  useEffect(() => {
    return () => clearInterval(intervalId);
  }, []);

  const [checkboxes, setCheckboxes] = useState<Pilot>({
    firstClass: false,
    secondClass: false,
    thirdClass: false,
    allClasses: false,
  });

  const [allRadioCheck, setAllRadioCheck] = useState<CheckboxState>({
    firstClass: false,
    secondClass: false,
    thirdClass: false,
    airTrafficController: false,
    aircraftMechanics: false,
    parachuteRiggers: false,
    dropZoneManagement: false,
    jumpPilots: false,
    // Add more checkboxes as needed
  });

  // defaultValue form values
  const defaultValue: CheckboxState = {
    firstClass: false,
    secondClass: false,
    thirdClass: false,
    airTrafficController: false,
    aircraftMechanics: false,
    parachuteRiggers: false,
    dropZoneManagement: false,
    jumpPilots: false,
  };

  const initialValues: Pilot = {
    ...defaultValue,
    firstClass: false,
    secondClass: false,
    thirdClass: false,
    allClasses: false,
  };

  const [zipResponse, setZipResponse] = useState<string[]>([]);

  const initialValues1 = {
    airman: "pilot",
    airman_class: [],
    expire: "",
    export_file: "",
    ameOfficeZipCode: "",
  };

  // Validation schema using yup
  const validationSchema = yup.object().shape({}); 

  useEffect(() => {
    setLoading(1);
    dispatch(plans()).then((plans: any) => {
      if (
        plans?.payload?.success &&
        Array.isArray(plans.payload.subscriptions) &&
        plans.payload.subscriptions.length
      ) {
        setSubscriptionMonth(plans.payload.subscriptions[1]);
      }
      dispatch(currentPlan()).then((currentPlan: any) => {
        setLoading(0);
        if (
          plans?.payload?.success &&
          Array.isArray(plans.payload.subscriptions) &&
          plans.payload.subscriptions.length
        ) {
          plans.payload.subscriptions.forEach((plan: any) => {
            if (
              plan.id ===
              currentPlan.payload?.current_subscription?.subscription
                ?.subscription_plan_id
            ) {
              setSubscriptionMonth(plan);
            }
          });
        }
      });
    });
  }, []);
  function getClassList(classes: any) {
    const classList = [];
    if (classes.firstClass) classList.push("1st");
    if (classes.secondClass) classList.push("2nd");
    if (classes.thirdClass) classList.push("3rd");
    return classList;
  }
  /// Handle form submission
  const handleSubmitSetData = (values: any) => {
    const payload: any = {
      zipCode: zipCodeDefalut,
      distance: distance?.value,
      success: "", // Ensure that the payload matches the expected shape of ZipCodeState
    };

    // setLoading(1);
    if (export_work.can_download) {
      let airmenclass = getClassList(checkboxes);
      let valued: Payload = {
        region_type: "state",
        last_name: "",
        last_name_check: "sub_string",
        first_name: "",
        first_name_check: "sub_string",
        address: "",
        address_check: "sub_string",
        city_name: "",
        city_name_check: "sub_string",
        zip_code: zipResponse || [],
        certificate_expire_date: "",
        airman_type: selectedTab,
        // "airman_class":airmenclass || [],
        expire: selectedOneMonth,
        sort_type: "first_middle_name",
        sort_type_order: "asc",
        export_file: selectedCSV || "CSV",
        task_type: "pilot_certificate",
      };
      if (airmenclass.length > 0) {
        // delete valued.airman_class;
        valued = { ...valued, airman_class: airmenclass };
      }
      values = valued;

      setLoading(1);
      dispatch(ameZipCode(payload)).then((res: any) => {
        if (res.payload.length > 0) {
          // Handle the result of the async thunk action
          const zipCodes = res.payload
            .map((item: any) => item.ZipCode.toString());
          valued.zip_code = zipCodes;
        }

        dispatch(exportsRecordsCertificate(values)).then((res: any) => {
          setLoading(0);

          if (res.payload && res.payload.task_log_id) {
            // navigate('download-history')
            setLoading(0);
            closeRef.current.click();
            const body: any = {
              task_log_id: res.payload.task_log_id,
              worker_job_id: res.payload.worker_job_id,
            };
            dispatch(TaskStatus(body))
              .then((res: any) => {
                let data = res.payload;
                if (
                  data.file_info &&
                  data.file_info.path &&
                  data.active_status == false
                ) {
                  if (data.records_found > 0) {
                    toast.success(
                      "Export request has been submitted successfully. Your file will download shortly.",
                      {
                        position: "top-center",
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 8000,
                        pauseOnFocusLoss: true,
                        theme: "dark",
                      }
                    );
                    setZipCodeDefalut("");
                    setCheckboxes({
                      firstClass: false,
                      secondClass: false,
                      thirdClass: false,
                      allClasses: false,
                    });
                    setSelectedTab("pilot");
                    setSelectedCSV("CSV");
                    setselectedOneMonth("30 Days");
                  } else {
                    toast.error("No criteria match for this query.", {
                      position: "top-center",
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      draggablePercent: 8000,
                      pauseOnFocusLoss: true,
                      theme: "dark",
                    });
                    setZipCodeDefalut("");
                    setCheckboxes({
                      firstClass: false,
                      secondClass: false,
                      thirdClass: false,
                      allClasses: false,
                    });
                    setSelectedTab("pilot");
                    setSelectedCSV("CSV");
                    setselectedOneMonth("30 Days");
                  }
                } else if (data.active_status == true) {
                  toast.success(
                    "Export request has been submitted successfully. Your file will download shortly.",
                    {
                      position: "top-center",
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      draggablePercent: 8000,
                      pauseOnFocusLoss: true,
                      theme: "dark",
                    }
                  );
                  setZipCodeDefalut("");
                  setCheckboxes({
                    firstClass: false,
                    secondClass: false,
                    thirdClass: false,
                    allClasses: false,
                  });
                  setSelectedTab("pilot");
                  setSelectedCSV("CSV");
                  setselectedOneMonth("30 Days");
                }
              })
              .catch((err: any) => {
                console.log(err);
              });
          } else {
            setLoading(0);

            closeRef.current.click();
          }
        });
      });
    } else {
      toast.error(export_work.can_download_msg, {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 8000,
        pauseOnFocusLoss: true,
        theme: "dark",
      });
    }
  };

  const handlReset = () => {
    setCheckboxes({
      firstClass: false,
      secondClass: false,
      thirdClass: false,
      allClasses: false,
    });
    setSelectedTab("pilot");
    setSelectedCSV("CSV");
    setselectedOneMonth("30 Days");
  };

  const marketingDistance = [
    {
      value: "selected",
      label: "Please select",
      isDisabled: true,
    },
  ];

  // Generating miles from 10 to 200
  for (let miles = 10; miles <= 200; miles += 10) {
    marketingDistance.push({
      value: miles.toString(),
      label: `${miles} Miles`,
      isDisabled: false,
    });
  }

  const checkedAllClass = (event: any) => {
    const { name, checked } = event.target;
    // Update individual checkbox state
    setCheckboxes((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
    // If "All Class" checkbox is checked, update all other checkboxes accordingly
    if (name === "allClasses") {
      setCheckboxes({
        firstClass: checked,
        secondClass: checked,
        thirdClass: checked,
        allClasses: checked,
      });
    } else {
      // If any other checkbox is unchecked, uncheck "All Class" checkbox
      if (!checked) {
        setCheckboxes((prevState) => ({
          ...prevState,
          allClasses: false,
        }));
      }
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling animation
    });
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTab(event.target.value);
    const { value } = event.target;

    if (value === "all") {
      const updatedCheckboxes: CheckboxState = { ...allRadioCheck };
      for (const key in updatedCheckboxes) {
        if (Object.prototype.hasOwnProperty.call(updatedCheckboxes, key)) {
          updatedCheckboxes[key] = true;
        }
      }
      setAllRadioCheck(updatedCheckboxes);
    } else {
      const updatedCheckboxes: CheckboxState = { ...allRadioCheck };
      for (const key in updatedCheckboxes) {
        if (Object.prototype.hasOwnProperty.call(updatedCheckboxes, key)) {
          updatedCheckboxes[key] = false;
        }
      }
      setAllRadioCheck(updatedCheckboxes);
    }
  };

  const handleZipCodeChange = (e: any) => {
    let check = e.target.value.replace(/[^0-9]/g, "");
    if (check.charAt(0) === "0") {
      check = check.slice(1); // Remove the first character if it's '0'
    }
    setZipCodeDefalut(check); // Update the state with the modified value
  };

  const handleRadioCSV = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCSV(event.target.value);
  };
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setselectedOneMonth(event.target.value);
  };

  const naviii = () => {
    navigate("/your-downloads-history");
  };

  const singleSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: "white",
      borderColor: "white",
      margin: 0,
      marginLeft: 0,
      border: "0px solid black",
      fontSize: 13,
      outline: "none !important",
      boxShadow: "none",
      "&:hover": {
        borderColor: "white",
      },
    }),
    input: (styles: any) => ({ ...styles, color: "black" }),
    placeholder: (styles: any) => ({
      ...styles,
      color: "black",
      fontSize: "14",
    }),
    menuList: (base: any) => ({
      ...base,
      padding: 0,
      color: "black",
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "white",
      },
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: "black",
      fontSize: "1rem",
    }),
  };

  return (
    <>
      {loading ? <Loader /> : ""}
      {/* Popup */}
      <Formik
        initialValues={initialValues1}
        validationSchema={validationSchema}
        onSubmit={handleSubmitSetData}
      >
        {() => (
          <div
            className="modal fade bd-example-modal-lg"
            role="dialog"
            id="ameHealthModal"
            aria-labelledby="ameHealthModal"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg ">
              <div className="modal-content" style={{ borderRadius: "1rem" }}>
                <div className="modal-header modal-order-summary-header">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-8 text-left">
                        <h5
                          className="modal-title  modal-order-summary-header-text "
                          id="exampleModalLabel"
                        >
                          AME Health Certificate List
                        </h5>
                      </div>
                    </div>
                  </div>
                  <img
                    src="images/modal-img/cross-img.svg"
                    className="close custom-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    ref={closeRef}
                    onClick={handlReset}
                  ></img>
                </div>
                {!export_work?.can_download && (
                  <a
                    href="/subscriptions-list"
                    className=" d-flex justify-content-center w-100 py-2 popupbgcolor mt-2"
                  >
                    <center>
                      <div className="Toastify__toast-body w-100">
                        <div className="Toastify__toast-icon Toastify--animate-icon Toastify__zoom-enter">
                          <svg
                            viewBox="0 0 24 24"
                            width="100%"
                            height="100%"
                            fill="#fff"
                          >
                            <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm.25 5a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm2.25 13.5h-4a1 1 0 010-2h.75a.25.25 0 00.25-.25v-4.5a.25.25 0 00-.25-.25h-.75a1 1 0 010-2h1a2 2 0 012 2v4.75a.25.25 0 00.25.25h.75a1 1 0 110 2z"></path>
                          </svg>
                        </div>
                        <div className="text-white">
                          {export_work?.can_download_msg}
                        </div>
                      </div>
                    </center>
                  </a>
                )}
                <div className="modal-body ">
                  <div className="container-fluid">
                    <div className="row text-black">
                      {/* content*/}
                      <div className="col-md-12 col-sm-12 ">
                        <div className="col-12  px-1 py-1 px-lg-4 py-lg-3 text-left font-inter mb-3">
                          <Form>
                            {/* Zip Code & Distance*/}
                            <div className="row pt-2">
                              <div className="col-md-6 col-sm-12 p-0 px-0 px-sm-1">
                                <div
                                  className="col-12 d-flex  align-items-center custom-border-orange-field"
                                  style={{
                                    paddingBottom: ".52rem",
                                    paddingTop: ".57rem",
                                  }}
                                >
                                  <div className="custom-form-sub-heading">
                                    AME Office Zip Code
                                  </div>
                                  <Field
                                    type="text"
                                    className="form-control bg-white text-black border-0 "
                                    name="zipCode"
                                    maxLength={9}
                                    placeholder="e.g.12345"
                                    style={{ font: "inherit" }}
                                    autoComplete="off"
                                    value={zipCodeDefalut}
                                    onChange={handleZipCodeChange}
                                  />
                                </div>
                              </div>

                              <div className="col-md-6 col-sm-12 p-0 px-0 px-sm-1 pt-3 pt-md-0">
                                <div className="col-12 d-flex  align-items-center custom-border-orange-field py-2">
                                  <div className="custom-form-sub-heading">
                                    Marketing Distance From Office
                                  </div>
                                  <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    options={marketingDistance}
                                    styles={singleSelectStyles}
                                    placeholder="Please select"
                                    className="w-100"
                                    onChange={(e: any) => setDistance(e)}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25: "#E35D22",
                                        primary: "black",
                                      },
                                    })}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* Airmen Type */}
                            <div className="row pt-4">
                              <div className="col-12 d-flex justify-content-around ame-boxShadow px-0 py-4">
                                <div className="custom-form-sub-heading">
                                  Airmen Type
                                </div>
                                <div className="form-check amePopup-label d-flex align-items-lg-center">
                                  <div>
                                    {" "}
                                    <Field
                                      className="form-check-input"
                                      type="radio"
                                      name="airman"
                                      id="pilot"
                                      value="pilot"
                                      checked={selectedTab === "pilot"}
                                      onClick={handleRadioChange}
                                    />
                                  </div>
                                  <label
                                    className="form-check-label pl-1 pl-lg-1"
                                    htmlFor="pilot"
                                  >
                                    Pilot
                                  </label>
                                </div>
                                <div className="form-check amePopup-label  d-flex align-items-lg-center">
                                  <div>
                                    {" "}
                                    <Field
                                      className="form-check-input"
                                      type="radio"
                                      name="airman"
                                      id="nonPilot"
                                      value="non pilot"
                                      checked={selectedTab === "non pilot"}
                                      onClick={handleRadioChange}
                                    />
                                  </div>
                                  <label
                                    className="form-check-label pl-1 pl-lg-1 text-nowrap"
                                    htmlFor="nonPilot"
                                  >
                                    Non-Pilot
                                  </label>
                                </div>
                                <div className="form-check amePopup-label  d-flex align-items-lg-center">
                                  <div>
                                    <Field
                                      className="form-check-input"
                                      type="radio"
                                      name="airman"
                                      id="airman"
                                      value=""
                                      checked={selectedTab === ""}
                                      onClick={handleRadioChange}
                                    />
                                  </div>
                                  <label
                                    className="form-check-label pl-1 pl-lg-1"
                                    htmlFor="airman"
                                  >
                                    All
                                  </label>
                                </div>
                                <div className="form-check amePopup-label"></div>
                              </div>
                            </div>

                            {/* Airmen Medical Class */}
                            {/* {selectedTab === "pilot" ? ( */}
                            <div
                              className="row mx-0 mx-lg-3  popUp-tab-transition"
                              id="airmenMedical"
                            >
                              <div className="col-12  ame-sub-boxShadow">
                                <div className="col-12 pt-2 sub-heading">
                                  Airmen Medical Class
                                </div>

                                <div className="d-flex justify-content-center align-items-center  flex-column pt-2 pb-3">
                                  <div className="col-11 col-md-12 col-lg-11 d-flex flex-column flex-lg-row ">
                                    <div className="form-check amePopup-label col-12 col-md-3  pt-2 pt-md-0 d-flex align-items-lg-center">
                                      <div>
                                        {" "}
                                        <Field
                                          className="form-check-input"
                                          type="checkbox"
                                          name="airman_class"
                                          id="firstClassCheckbox"
                                          value="1st"
                                          checked={checkboxes.firstClass}
                                          onChange={() =>
                                            setCheckboxes({
                                              ...checkboxes,
                                              firstClass:
                                                !checkboxes.firstClass,
                                            })
                                          }
                                        />
                                      </div>
                                      <label
                                        className="form-check-label pl-1 pl-lg-1 text-nowrap"
                                        htmlFor="firstClassCheckbox"
                                      >
                                        1st Class
                                      </label>
                                    </div>
                                    <div className="form-check amePopup-label col-12 col-md-3  pt-2 pt-md-0  d-flex align-items-lg-center">
                                      <div>
                                        {" "}
                                        <Field
                                          className="form-check-input"
                                          type="checkbox"
                                          name="airman_class"
                                          id="secondClassCheckbox"
                                          value="2nd"
                                          checked={checkboxes.secondClass}
                                          onChange={() =>
                                            setCheckboxes({
                                              ...checkboxes,
                                              secondClass:
                                                !checkboxes.secondClass,
                                            })
                                          }
                                        />
                                      </div>
                                      <label
                                        className="form-check-label pl-1 pl-lg-1 text-nowrap "
                                        htmlFor="secondClassCheckbox"
                                      >
                                        2nd Class
                                      </label>
                                    </div>

                                    <div className="form-check amePopup-label col-12 col-md-3  pt-2 pt-md-0  d-flex align-items-lg-center">
                                      <div>
                                        <Field
                                          className="form-check-input"
                                          type="checkbox"
                                          name="airman_class"
                                          id="thirdClassCheckbox"
                                          value="3rd"
                                          checked={checkboxes.thirdClass}
                                          onChange={() =>
                                            setCheckboxes({
                                              ...checkboxes,
                                              thirdClass:
                                                !checkboxes.thirdClass,
                                            })
                                          }
                                        />{" "}
                                      </div>
                                      <label
                                        className="form-check-label pl-1 pl-lg-1 text-nowrap"
                                        htmlFor="thirdClassCheckbox"
                                      >
                                        3rd Class
                                      </label>
                                    </div>
                                    <div className="form-check amePopup-label col-12 col-md-3  pt-2 pt-md-0  d-flex align-items-lg-center">
                                      <div>
                                        {" "}
                                        <Field
                                          className="form-check-input"
                                          type="checkbox"
                                          name="allClasses"
                                          id="allClassesCheckbox"
                                          value="all"
                                          checked={
                                            checkboxes.firstClass &&
                                            checkboxes.secondClass &&
                                            checkboxes.thirdClass
                                          }
                                          onChange={checkedAllClass}
                                        />{" "}
                                      </div>
                                      <label
                                        className="form-check-label pl-1 pl-lg-1  d-flex align-items-lg-center text-nowrap"
                                        htmlFor="allClassesCheckbox"
                                      >
                                        All Class
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Medical Expiring Within: */}
                            <div className="row pt-4">
                              {activeSubscription?.current_subscription
                                ?.plan === null ||
                              subscriptionMonth?.plan_name === "ONE YEAR" ||
                              subscriptionMonth?.plan_name === "THREE MONTH" ? (
                                <>
                                  {/*  */}

                                  {/* Medical Expiring Within: */}

                                  <div className="col-12 d-flex offmarket-output-format  flex-wrap ame-boxShadow px-0 py-4">
                                  <div className="custom-form-sub-heading">
                                      Medical Expiring Within
                                    </div>
                                    <div className=" amePopup-label col-6 d-flex align-items-lg-center">
                                      <div className="pl-4">
                                        <Field
                                          className="form-check-input"
                                          type="radio"
                                          name="expire"
                                          id="30 Days"
                                          value="30 Days"
                                          checked={
                                            selectedOneMonth === "30 Days"
                                          }
                                          onClick={handleDateChange}
                                        />
                                      </div>
                                      <label
                                        className="form-check-label pl-0 pl-lg-1"
                                        htmlFor="30 Days"
                                      >
                                        30 Days
                                      </label>
                                    </div>
                                    <div className=" amePopup-label  col-6 d-flex  align-items-lg-center">
                                      <div className="pl-4">
                                      <Field
                                          className="form-check-input"
                                          type="radio"
                                          name="expire"
                                          id="60 Days"
                                          value="60 Days"
                                          checked={
                                            selectedOneMonth === "60 Days"
                                          }
                                          onClick={handleDateChange}
                                        />
                                      </div>
                                      <label
                                        className="form-check-label pl-0 pl-lg-1"
                                        htmlFor="60 Days"
                                      >
                                        60 Days
                                      </label>
                                    </div>
                                    <div className=" amePopup-label  col-6 d-flex  align-items-lg-center">
                                      <div className="pl-4">
                                      <Field
                                          className="form-check-input"
                                          type="radio"
                                          name="expire"
                                          id="90 Days"
                                          value="90 Days"
                                          checked={
                                            selectedOneMonth === "90 Days"
                                          }
                                          onClick={handleDateChange}
                                        />
                                      </div>
                                      <label
                                        className="form-check-label pl-0 pl-lg-1"
                                        htmlFor="90 Days"
                                      >
                                        90 Days
                                      </label>
                                    </div>
                                    <div className=" amePopup-label  col-6 d-flex  align-items-lg-center">
                                      <div className="pl-4">
                                      <Field
                                          className="form-check-input"
                                          type="radio"
                                          name="expire"
                                          id="expireall"
                                          value="all"
                                          checked={selectedOneMonth === "all"}
                                          onClick={handleDateChange}
                                        />
                                      </div>
                                      <label
                                        className="form-check-label pl-0 pl-lg-1"
                                        htmlFor="all"
                                      >
                                        All
                                      </label>
                                    </div>
                                    <div className=" amePopup-label  col-6 d-flex  align-items-lg-center">
                                      <div className="pl-4">
                                      <Field
                                          className="form-check-input"
                                          type="radio"
                                          name="expire"
                                          id="expired"
                                          value="Expired"
                                          checked={
                                            selectedOneMonth === "Expired"
                                          }
                                          onClick={handleDateChange}
                                        />
                                      </div>
                                      <label
                                        className="form-check-label pl-0 pl-lg-1"
                                        htmlFor="expired"
                                      >
                                        Expired
                                      </label>
                                    </div>
                                    <div className=" amePopup-label  col-6 d-flex   align-items-center"></div>
                                  </div>

                                </>
                              ) : subscriptionMonth?.plan_name === "MONTHLY" ? (
                                <>
                                  <div className="col-12 d-flex justify-content-around ame-boxShadow px-0 py-4">
                                    <div className="amePopup-sub-heading">
                                      Medical Expiring Within
                                    </div>
                                    <div className=" amePopup-label text-left ml-2 ml-md-3 d-flex align-items-lg-center">
                                      <div>
                                        <Field
                                          className="form-check-input "
                                          type="radio"
                                          name="expire"
                                          id="30 Days"
                                          value="30 Days"
                                          checked={
                                            selectedOneMonth === "30 Days"
                                          }
                                          onClick={handleDateChange}
                                        />
                                      </div>
                                      <label
                                        className="form-check-label pl-1 pl-lg-1"
                                        htmlFor="30 Days"
                                      >
                                        30 Days
                                      </label>
                                    </div>
                                    <div className=" amePopup-label"></div>
                                    <div className=" amePopup-label"></div>
                                    <div className=" amePopup-label"></div>
                                    <div className=" amePopup-label"></div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}

                              {/* <p className="text-danger field-errorMessage ">
                                <ErrorMessage name="expire" />
                              </p> */}
                            </div>

                            {/* Output Format */}
                            <div className="row pt-4 pb-4">
                              <div className="col-12 d-flex offmarket-output-format flex-column flex-sm-row flex-md-row flex-lg-row ame-boxShadow px-0 py-4">
                                <div className="custom-form-sub-heading">
                                  Output Format
                                </div>
                                <div className="col-2 ml-3 ml-lg-0 amePopup-label d-flex align-items-lg-center justify-content-start">
                                  <div>
                                    <Field
                                      className="form-check-input"
                                      type="radio"
                                      name="export_file"
                                      id="CSV"
                                      value="CSV"
                                      checked={selectedCSV === "CSV"}
                                      onClick={handleRadioCSV}
                                    />
                                  </div>
                                  <label
                                    className="form-check-label pl-1 pl-lg-1"
                                    htmlFor="CSV"
                                  >
                                    CSV
                                  </label>
                                </div>
                                {/* <div className=" amePopup-label  d-flex align-items-center">
                                <div> <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="export_file"
                                    id="TXT"
                                    value="TXT"
                                    checked={selectedCSV === "TXT"}
                                    onClick={handleRadioCSV}
                                  /></div>
                                  <label
                                    className="form-check-label pl-2 "
                                    htmlFor="TXT"
                                  >
                                    TXT
                                  </label>
                                </div> */}
                                {/* <div className=" amePopup-label  d-flex align-items-center">
                                <div><Field
                                    className="form-check-input"
                                    type="radio"
                                    name="export_file"
                                    id="RTF"
                                    value="RTF"
                                    checked={selectedCSV === "RTF"}
                                    onClick={handleRadioCSV}
                                  /></div>
                                  <label
                                    className="form-check-label pl-2 "
                                    htmlFor="RTF"
                                  >
                                    RTF
                                  </label>
                                </div> */}

                                <div className="col-8 ml-3 m-lg-0 amePopup-label  d-flex align-items-lg-center justify-content-start">
                                  <div>
                                    <Field
                                      className="form-check-input"
                                      type="radio"
                                      name="export_file"
                                      id="PDF"
                                      value="PDF"
                                      checked={selectedCSV === "PDF"}
                                      onClick={handleRadioCSV}
                                    />
                                  </div>
                                  <label
                                    className="form-check-label pl-1 pl-lg-1 text-nowrap"
                                    htmlFor="PDF"
                                  >
                                    Avery 5163 Label PDF
                                  </label>
                                </div>

                                <div className="amePopup-label mobile-hide">
                                  <label
                                    className="form-check-label pl-2 pl-md-0"
                                    htmlFor="outputFormat"
                                  ></label>
                                </div>
                              </div>
                              {/* <p className="text-danger field-errorMessage">
                                <ErrorMessage name="export_file" />
                              </p> */}
                            </div>

                            <div className="text-center ">
                              <div className=" col-12 btn-wrapper btn-primary-wrapper d-xl-inline mt-3 mt-xl-0 ml-xl-3">
                                {/* <Link to="/your-downloads-history"> */}
                                <button
                                  type="submit"
                                  // onClick={() => {
                                  //   handleSubmitSetData();
                                  // }}
                                  className="exports-Records-btn custom-freeTrial-btn"
                                  // data-dismiss="modal"
                                >
                                  Export Records
                                </button>
                                {/* </Link> */}
                              </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};
