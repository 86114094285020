import React, { useState } from "react";
import "../Login/login.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { FreeTrial } from "../RepeatedComponent/FreeTrial";
import { Loader } from "../RepeatedComponent/Loader";
import ReCAPTCHA from "react-google-recaptcha";

export const EnquiryForm: React.FC = () => {
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(0);

  // defaultValue form values
  const defaultValue = {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    subject: "",
    description: "",
  };

  // Validation schema using yup
  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .trim()
      .required("Name is required")
      .matches(/\S+/, "Name must not contain only white spaces"),
    lastName: yup.string().required("Name is required"),
    email: yup
      .string()
      .required("Email is required")
      .email("Invalid email address"),
    mobile: yup
      .string()
      .required("Enter your number")
      .matches(/^.{10,}$/, "Number must contain at least 10 number"),
    subject: yup.string().required("Please enter your subject"),
    description: yup.string(),
  });

  /// Handle form submission
  const handleSubmit = (values: any) => {
    setLoading(1);
    // dispatch(userLogin(values)).then((res: any) => {
    //   setLoading(0);
    // }); // Dispatching the signInUser action with form values
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling animation
    });
  };
  const handleRecaptchaChange = (token: any) => {
    setToken(token);
  };

  return (
    <>
      {loading ? <Loader /> : ""}

      <div className="position-relative">
        <Formik
          initialValues={defaultValue}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <div className=" bg-body-tertiary bg-black  d-flex flex-row gray-bg  ">
            <div className="container  mt-sm-5  align-items-stretch">
              <div className="row justify-content-center  p-0 pt-md-5 pb-md-5">
                <div className="col-md-10  overflow-hidden login-container p-0">
                  <div className="d-flex justify-content-center">
                    <div className=" card p-2 m-3 m-sm-0 ">
                      <div className="card-body">
                        <Form>
                          <div className="p-md-4 p-2">
                            <ToastContainer />
                            <p
                              style={{
                                textAlign: "center",
                                color: "#7E7E7E",
                                fontSize: "1.7rem",
                                marginBottom: "2.25rem",
                                marginTop: "0.15rem",
                              }}
                            >
                              <span
                                style={{ color: "black", fontWeight: "bold" }}
                              >
                                Enquiry Form
                              </span>{" "}
                              <br />{" "}
                            </p>
                            <div className="d-flex">
                              <div className="col-6">
                                <Field
                                  type="text"
                                  className="form-control small"
                                  placeholder="First Name"
                                  aria-label="First Name"
                                  aria-describedby="basic-addon1"
                                  name="firstName"
                                />
                                <p className="text-danger field-errorMessage mb-2">
                                  <ErrorMessage name="firstName" />
                                </p>
                              </div>

                              <div className="col-6">
                                <Field
                                  type="text"
                                  className="form-control small"
                                  placeholder="Last Name"
                                  aria-label="Last Name"
                                  aria-describedby="basic-addon1"
                                  name="lastName"
                                />
                                <p className="text-danger field-errorMessage mb-2">
                                  <ErrorMessage name="lastName" />
                                </p>
                              </div>
                            </div>
                            <div className="d-flex">
                              <div className=" col-6 mb-1 ">
                                <Field
                                  type="email"
                                  className="form-control small w-100"
                                  name="email"
                                  placeholder="Enter Your Email ID"
                                  aria-label="Enter Your Email ID"
                                  aria-describedby="basic-addon1"
                                  autoComplete="on"
                                />
                                <p className="text-danger field-errorMessage mb-2">
                                  <ErrorMessage name="email" />
                                </p>
                              </div>

                              {/* number */}
                              <div className=" col-6 mb-1">
                                <Field
                                  type="text"
                                  className="form-control small w-100"
                                  placeholder="Mobile number"
                                  aria-label="Mobile number"
                                  aria-describedby="basic-addon1"
                                  name="mobile"
                                />
                                <p className="text-danger field-errorMessage mb-2">
                                  <ErrorMessage name="mobile" />
                                </p>
                              </div>
                            </div>

                            {/* subject */}
                            <div className="col-12">
                              <Field
                                type="text"
                                className="form-control small"
                                placeholder="Subject"
                                aria-label="Subject"
                                aria-describedby="basic-addon1"
                                name="subject"
                              />
                              <p className="text-danger field-errorMessage mb-2">
                                <ErrorMessage name="subject" />
                              </p>
                            </div>

                            {/* description */}

                            <div className="col-12">
                              {/* <label htmlFor="exampleFormControlTextarea1">
                                Example textarea
                              </label> */}
                              <textarea
                                className="form-control"
                                id="description"
                                rows={3}
                                placeholder="Provide Description"
                                name="description"
                              ></textarea>
                              <p className="text-danger field-errorMessage mb-2">
                                <ErrorMessage name="description" />
                              </p>
                            </div>

                            <div className="mb-3 col-12 d-flex justify-content-start ">
                              <ReCAPTCHA
                                // sitekey="your site key "
                                sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
                                onChange={handleRecaptchaChange}
                              />

                              <Field
                                type="hidden"
                                className="form-control small"
                                name="tokencap"
                                value={token}
                                autoComplete="on"
                              />
                            </div>

                            <div className="d-flex justify-content-center ">
                              <button
                                type="submit"
                                className="btn btn-primary w-100  justify-content-center"
                                onClick={scrollToTop}
                              >
                                <span className="text-white">Submit</span>
                              </button>
                            </div>
                            {/* <div className="d-flex justify-content-center col-12 pt-4">
                              <p className="not-a-member">Not a member?</p>
                              <Link to="/register" onClick={scrollToTop}>
                                <p className="register pl-1">Register</p>
                              </Link>
                            </div> */}
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Formik>
        <FreeTrial />
      </div>
    </>
  );
};
