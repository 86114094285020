import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs } from "../RepeatedComponent/Breadcrumb";
import { useAppDispatch, useAppSelector } from "../../../hook";
import { currentPlan } from "../../../Features/PlansSlice";
import { Loader } from "../RepeatedComponent/Loader";
import { fetchUser } from "../../../Features/fetchUserSlice";
import { ToastContainer, toast } from "react-toastify";
import { paymentHistoryPdf } from "../../../Features/downloadHistorySlice";

export const YourPaymentsHistory = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [exportData, setExportData] = useState([]);

  const paymentsHistory = useAppSelector(
    (state: any) => state?.plans?.response
  );
  const status = useAppSelector((state: any) => state.auth.status);
  const userEmail = useAppSelector(
    (state: any) => state?.user?.userInfo?.user?.email
  );

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const handleFromDateChange = (e: any) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e: any) => {
    setToDate(e.target.value);
  };

  function formatDateString(dateString: any) {
    // Create a new Date object from the provided date string
    const date = new Date(dateString);

    // Define an array to store the month names
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Extract day, month, and year components from the date object
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    // Format the date as "day/Month/Year"
    const formattedDate = `${month}-${day}-${year}`;

    return formattedDate;
  }

  const handleFilter = () => {
    const filterExportData = paymentsHistory?.subscriptions_history.filter(
      (item: any) => {
        const paymentDate = new Date(item.start_date);
        const fromDateObj = fromDate ? new Date(fromDate) : null;
        const toDateObj = toDate ? new Date(toDate) : null;

        if (fromDateObj && toDateObj) {
          toDateObj.setDate(toDateObj.getDate() + 1);
          return paymentDate >= fromDateObj && paymentDate <= toDateObj;
        } else if (fromDateObj) {
          return paymentDate >= fromDateObj;
        } else if (toDateObj) {
          toDateObj.setDate(toDateObj.getDate() + 1);
          return paymentDate <= toDateObj;
        }

        return true;
      }
    );
    if (filterExportData.length > 0) {
      // const csvData = transformData(filterExportData)
      //   .map((item: any) => userEmail+","+Object.values(item).join(","))
      //   .join("\n");
      const newData = filterExportData.map(
        (ele: any) =>
          `${userEmail},${ele.plan_name},${
            ele?.plan_type === "basic"
              ? "Sliver"
              : ele?.plan_type === "standard"
              ? "Gold"
              : "Platinum"
          },${formatDateString(ele?.start_date)},${
            ele.is_active ? "Active" : "Inactive"
          },$${ele.price}`
      );
      const CsvWithHeader =
        "Email,Plan Name,Plan Type,Payment Date,Validity,Status,Price" +
        "\n" +
        filterExportData
          .map(
            (ele: any) =>
              `${userEmail},${ele.plan_name},${
                ele?.plan_type === "basic"
                  ? "Sliver"
                  : ele?.plan_type === "standard"
                  ? "Gold"
                  : "Platinum"
              },${formatDateString(ele?.start_date)},${formatDateString(
                ele?.end_date
              )},${ele.is_active ? "Active" : "Inactive"},$${ele.price}`
          )
          .join("\n");

      // Create a Blob object with CSV data
      const blob = new Blob([CsvWithHeader], { type: "text/csv" });

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "payments_history.csv";
      link.click();

      // Log the data in JSON format to console
      // console.log(JSON.stringify(paymentsHistory.subscriptions_history));
    } else {
      toast.error("No Payment History found!", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 8000,
        pauseOnFocusLoss: true,
      });
    }
  };

  useEffect(() => {
    dispatch(fetchUser());
    dispatch(currentPlan());
  }, [dispatch]);

  useEffect(() => {
    if (!status) {
      navigate("/");
    }
  }, [status, navigate]);

  const downloadInvoice = (id: string, date: any) => {
    const fromatedDate = formatDate(date);
    if (fromatedDate) {
      setLoading(true);
      dispatch(paymentHistoryPdf(id))
        .then((res: any) => {
          setLoading(false);
          let data = res.payload;
          if (data) {
            // Create a blob for the file download
            const blob = new Blob([data], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${fromatedDate} Invoice.pdf`;  // Name of the downloaded file
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        })
        .catch((err: any) => {
          setLoading(false); // Ensure loading state is reset
          console.log(err);
        });
    }
  };
    
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: any = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const capitalizeFirstLetterOfWords = (str: string) => {
    return str.replace(/\b\w/g, (char: string) => char.toUpperCase());
  };

  return (
    <>
      {loading && <Loader />}
      <ToastContainer />
      <div className="sr-multicol-media pt-1 pt-lg-4 text-center prototype-no-background widget_1600979911688 global">
        <div className="container-fluid content-wrapper pt-2 pt-lg-4">
          <div className="row d-flex justify-content-center pt-1 pt-md-3 pt-lg-5">
            <div className="col-md-10">
              <Breadcrumbs textColor={"text-white"} />
            </div>
          </div>
        </div>
      </div>
      {/* Payment History */}
      <div
        id="hs_cos_wrapper_widget_1604099686647"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
        <span
          id="hs_cos_wrapper_main_flexible_column"
          className="hs_cos_wrapper hs_cos_wrapper_widget_container hs_cos_wrapper_type_widget_container bg-secondary"
          style={{}}
          data-hs-cos-general-type="widget_container"
          data-hs-cos-type="widget_container"
        >
          <div
            id="hs_cos_wrapper_widget_1604340283000"
            className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
            style={{ background: "#EDF1D1" }}
            data-hs-cos-general-type="widget"
            data-hs-cos-type="module"
          >
            <div className="sr-cards-grid-02 bg-black sr-padding-full prototype-no-background widget_1604340283000">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <div className="cards row">
                      <div className="container-fluid">
                        <div className="row ">
                          <div className="col-md-12">
                            <h2
                              className=" section-heading font-inter text-white text-center dash-heading"
                              style={{
                                backgroundColor: "black",
                                color: "white",
                              }}
                            >
                              Payment History
                            </h2>
                          </div>
                          <div className="col-md-12 pt-3 d-flex justify-content-between border-0">
                            <div className="col-12 mobile-hide col-sm-12 col-md-11 col-lg-11 d-flex border-0 flex-row flex-sm-row flex-md-row flex-lg-row">
                              <div className="col-5 col-sm-5 col-lg-4 input-group  input-small-screen">
                                <span
                                  className="input-group-text payment-history-inputes"
                                  id="basic-addon1"
                                >
                                  From Date
                                </span>
                                <input
                                  type="date"
                                  className="form-control small h-100"
                                  name="from-date"
                                  id="from-date"
                                  autoComplete="on"
                                  value={fromDate}
                                  onChange={handleFromDateChange}
                                />
                              </div>
                              <div className="col-4 col-sm-5 col-lg-4 input-group input-small-screen">
                                <span
                                  className="input-group-text payment-history-inputes"
                                  id="basic-addon1"
                                >
                                  To Date
                                </span>
                                <input
                                  type="date"
                                  className="form-control small h-100"
                                  name="to-date"
                                  id="to-date"
                                  autoComplete="on"
                                  value={toDate}
                                  onChange={handleToDateChange}
                                />
                              </div>
                              <div className="col-2 col-sm-2 col-lg-2 d-flex input-small-screen">
                                <button
                                  type="button"
                                  className="btn btn-primary payment-history-inputes"
                                  onClick={handleFilter}
                                >
                                  <span className="text-white">EXPORT</span>
                                </button>
                              </div>
                            </div>
                            <div className="col-12 mobile-show col-sm-12 col-md-11 col-lg-11 d-flex border-0 flex-column flex-sm-row flex-md-row flex-lg-row">
                              <div className="col-12">
                                <div className="col-12  col-lg-4 input-group  input-small-screen">
                                  <span
                                    className="input-group-text payment-history-inputes"
                                    id="basic-addon1"
                                  >
                                    From Date
                                  </span>
                                  <input
                                    type="date"
                                    className="form-control small h-100"
                                    name="from-date"
                                    id="from-date"
                                    autoComplete="on"
                                    value={fromDate}
                                    onChange={handleFromDateChange}
                                  />
                                </div>
                                <div className="col-12  col-lg-4 input-group input-small-screen">
                                  <span
                                    className="input-group-text payment-history-inputes"
                                    id="basic-addon1"
                                  >
                                    To Date
                                  </span>
                                  <input
                                    type="date"
                                    className="form-control small h-100"
                                    name="to-date"
                                    id="to-date"
                                    autoComplete="on"
                                    value={toDate}
                                    onChange={handleToDateChange}
                                  />
                                </div>
                                <div className="col-12 col-lg-2 d-flex input-small-screen ">
                                  <button
                                    type="button"
                                    className="btn btn-primary payment-history-inputes"
                                    onClick={handleFilter}
                                  >
                                    <span className="text-white">EXPORT</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 overflow-auto border mb-5 m-3">
                            <table className="table custom-downloads-table bg-black text-white">
                              <thead className="thead custom-downloads-thead">
                                <tr>
                                  <th scope="col" className="text-nowrap">
                                    Email
                                  </th>
                                  <th scope="col" className="text-nowrap">
                                    Plan Name
                                  </th>
                                  <th scope="col" className="text-nowrap">
                                    Plan Type
                                  </th>
                                  <th scope="col" className="text-nowrap">
                                    Payment Date
                                  </th>
                                  <th scope="col" className="text-nowrap">
                                    Validity
                                  </th>
                                  <th scope="col">Status</th>
                                  <th scope="col">Price</th>
                                  <th scope="col">Invoice</th>
                                </tr>
                              </thead>
                              <tbody>
                                {paymentsHistory?.subscriptions_history &&
                                paymentsHistory?.subscriptions_history.length >
                                  0 ? (
                                  paymentsHistory?.subscriptions_history
                                    .filter((item: any) => {
                                      const paymentDate = new Date(
                                        item.start_date
                                      );
                                      const fromDateObj = fromDate
                                        ? new Date(fromDate)
                                        : null;
                                      const toDateObj = toDate
                                        ? new Date(toDate)
                                        : null;

                                      if (fromDateObj && toDateObj) {
                                        toDateObj.setDate(
                                          toDateObj.getDate() + 1
                                        );
                                        return (
                                          paymentDate >= fromDateObj &&
                                          paymentDate <= toDateObj
                                        );
                                      } else if (fromDateObj) {
                                        return paymentDate >= fromDateObj;
                                      } else if (toDateObj) {
                                        toDateObj.setDate(
                                          toDateObj.getDate() + 1
                                        );
                                        return paymentDate <= toDateObj;
                                      }
                                      // setExportData(exportData);
                                      return true;
                                    })
                                    .map((item: any, index: number) => (
                                      <tr key={index}>
                                        <td scope="row" className="text-nowrap">
                                          {userEmail}
                                        </td>
                                        <td scope="row" className="text-nowrap">
                                          {item?.plan_name}
                                        </td>
                                        <td scope="row" className="text-nowrap">
                                          {item?.plan_type === "basic"
                                            ? "Sliver"
                                            : item?.plan_type === "standard"
                                            ? "Gold"
                                            : "Platinum"}
                                        </td>
                                        <td scope="row" className="text-nowrap">
                                          {formatDate(item?.start_date)}
                                        </td>
                                        <td scope="row" className="text-nowrap">
                                          {formatDate(item?.end_date)}
                                        </td>
                                        <td scope="row" className="text-nowrap">
                                          {item?.is_active
                                            ? "Active"
                                            : "Inactive"}
                                        </td>
                                        <td scope="row" className="text-nowrap">
                                          ${item?.price}
                                        </td>
                                        <td
                                          className="text-nowrap"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            downloadInvoice(
                                              item.subscription_id,
                                              item?.start_date
                                            )
                                          }
                                        >
                                          Download
                                        </td>

                                      </tr>
                                    ))
                                ) : (
                                  <tr>
                                    <td colSpan={6}>
                                      No Payment History found
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </span>{" "}
      </div>
    </>
  );
};
