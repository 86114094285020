import "../History/history.css";
import { FreeTrial } from "../RepeatedComponent/FreeTrial";
import { useAppSelector } from "../../../hook";
import { Breadcrumbs } from "../RepeatedComponent/Breadcrumb";
export const Blog = () => {
  const status = useAppSelector((state) => state.auth.status);
  return (
    <>
      <div className="sr-multicol-media bg-black pt-1 pt-lg-4 text-center   prototype-no-background widget_1600979911688 ">
        <div className="container-fluid content-wrapper pt-2 pt-lg-4">
          <div className="row d-flex justify-content-center pt-1 pt-md-3 pt-lg-5">
            <div className="col-md-10">
              <Breadcrumbs textColor={"text-white"} />
            </div>
          </div>
        </div>
      </div>
      <div
        id="hs_cos_wrapper_widget_1604012269835"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
        {/* Label For Modules Data Page */}
        <div
          className="sr-multicol-media  bg-black  sr-padding-half text-center prototype-no-background widget_1604012269835 "
          id="research-property"
        >
          <div className="container-fluid content-wrapper">
            <div className="row text-white">
              <div className="col-md-10 offset-md-1">
              <h2 className=" section-heading font-inter">
              Blogs
                </h2>
                <div className="row  sr-valign-center">
                  {/* Blogs*/}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-1">
                    <div className="content-wrapper">
                      <div className="container ">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start pt-2 pb-2 pl-0">
                            <div className="px-3 py-2 font-weight-bold text-white h4 ">
                              PLANELISTS.COM, CREATE AND DOWNLOAD TARGETED
                              AVIATION MAILING LISTS
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12  pt-2 pb-4">
                            <p>
                              <strong>Welcome to Planelists.com</strong>
                              <br></br>
                              Designed by and for aviation professionals;
                              PlaneLists.com can provide you the most accurate
                              and up to date aviation mailing lists,
                              comprehensive research and custom queries to help
                              you with your marketing needs. Our database is
                              updated daily to ensure that you have the most
                              accurate and profitable information available.
                            </p>
                            <p>
                              Our customizable search function will search US
                              Aircraft Owners, pilots, A&P, drone operators and
                              other aviation professionals and businesses. For
                              marketing needs, you can query based on a variety
                              of needs and create a powerful and effective
                              marketing tool.
                            </p>
                            <p>
                              <strong>
                                If you are an Aviation Medical Examiner, or AME;
                                our site will allow you to search and download
                                pilots within a range from your office who's
                                medicals are about to expire, and print labels
                                for easy mailing.
                              </strong>
                            </p>

                            <p>
                              Designed by and for aviation professionals;
                              PlaneLists.com can provide you the most accurate
                              and up to date aviation mailing lists,
                              comprehensive research and custom queries to help
                              you with your marketing needs. Our database is
                              updated daily to ensure that you have the most
                              accurate and profitable information available.
                            </p>
                            <p>
                              At PlaneLists.com, we can also offer a full
                              in-house solution for your mailing list services.
                              From printed the mailing labels, postcards or
                              multifaceted queries. Our powerful Amazon Based
                              Web Server can handle the most comprehensive as
                              well as custom queries. Call us today with your
                              specific needs; and we will create marketing
                              program for you.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* FOLLOW US ON LINKEDIN.COM */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-1">
                    <div className="content-wrapper">
                      <div className="container custom-blog-outline ">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center mt-3 px-5 ">
                            <div className="blog-para-header pt-3 text-white">
                              FOLLOW US ON LINKEDIN.COM
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-5 pt-4 pb-2">
                            <p>
                              <strong>
                                Follow the latest updates in our world!
                              </strong>
                              <br></br>
                              https://www.linkedin.com/in/david-dillinger-93b84917b/
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* FURLOUGHED AIRLINE PILOTS */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-4">
                    <div className="content-wrapper">
                      <div className="container custom-blog-outline ">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center mt-3 px-5 ">
                            <div className="blog-para-header pt-3 text-white">
                              FURLOUGHED AIRLINE PILOTS
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-5 pt-4 pb-2">
                            <p>
                              <strong>Help is here</strong>
                            </p>
                            <p>
                              If you are a furloughed airline pilot, and would
                              like to access our database for free, please call
                              or email us and we will set you up for full
                              access.
                            </p>
                            <p>
                              You will be able to instantly search for any
                              aircraft in your area(s) including newly
                              registered. From experimental to Gulfstreams and
                              all the way up to wide body airliners, with one
                              click you can see any potential opportunities that
                              may exist.
                            </p>
                            <p>
                              I’ve been furloughed 3 times, so trust me; I know
                              it’s hard, but it does get better. If there’s
                              anything I can personally do to help; please reach
                              out.David
                            </p>
                            <div className="d-flex justify-content-center pb-4">
                            <img src="images/blog-chart-img.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ISSUES? */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-4">
                    <div className="content-wrapper">
                      <div className="container custom-blog-outline ">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center mt-3 px-5 ">
                            <div className="blog-para-header pt-3 text-white">
                              ISSUES?
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-5 pt-4 pb-2">
                            <p>
                              <strong>It's not perfect... Yet.</strong>
                            </p>
                            <p>
                              OK. Yes, it's not 100% perfect, but our phone
                              (843-321-8973) and email is always on. Or reach
                              out to us on Linkedin and let us know how we can
                              help you.
                            </p>
                            <p>
                              We can also do custom queries outside of what we
                              offer online. Contact us, and we are here to help.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* NAMES NOT SHOWING UP IN DATABASE QUERY */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-4">
                    <div className="content-wrapper">
                      <div className="container custom-blog-outline ">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center mt-3 px-5 ">
                            <div className="blog-para-header pt-3 text-white">
                              NAMES NOT SHOWING UP IN DATABASE QUERY
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-5 pt-4 pb-2">
                            <p>
                              <strong>Where's my pilot info?</strong>
                            </p>
                            <p>
                              If a pilot has 'opted" out of the FAA public
                              database; the information concerning them is not
                              transmitted from the FAA public database. Ergo,
                              not exportable.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* FAA BASIC MED QUERY */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-4">
                    <div className="content-wrapper">
                      <div className="container custom-blog-outline ">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center mt-3 px-5 ">
                            <div className="blog-para-header pt-3 text-white">
                              FAA BASIC MED QUERY
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-5 pt-4 pb-2">
                            <p>
                              <strong>Basic Med information for pilots</strong>
                            </p>
                            <p>
                              We are currently adding the Basic Med search
                              functionality into the pilot database records.
                              This should be available within the next few days.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
           status ?"":
           
          //  7-day free trial component 
          <FreeTrial/>
         }
      </div>
    </>
  );
};
