import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hook";
import { pilotRecord } from "../../../Features/pilotRecordSlice";
import { Loader } from "./Loader";
import "./ameHealthPopup.css";
import "./personalInfoPopup.css";

export const Personal_InformationPopup = (props: any) => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [navi, setNavi] = useState(0);
  const [loading, setLoading] = useState(0);
  const [btnClick, setBtnClick] = useState(true);

  const certificatRecord = useAppSelector(
    (state: any) => state.pilotRecords.records
  );
  const status = useAppSelector((state: any) => state.auth.status);

  useEffect(() => {
    if (!status) {
      navigate("/");
    }
  }, [status, navigate]);

  const records = {
    id: props?.data?.unique_id,
    designation: props?.type?.replace(/\s+/g, "_").toLowerCase(),
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling animation
    });
  };

  const certificateRecord = () => {};
  useEffect(() => {
    setLoading(1);
    dispatch(pilotRecord(records)).then((res: any) => {
      setLoading(0);
    });
  }, [props.data]);
  return (
    <>
      {loading ? <Loader /> : ""}
      {/* <button
        type="button"
        className="btn btn-primary"
        data-toggle="modal"
        data-target="#personalInformation"
      >
        Launch demo modal
      </button> */}

      <div
        className="modal fade"
        id="personalInformation"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog pimaxwid" role="document">
          <div className="modal-content">
            <div className="modal-header modal-order-summary-header  rounded-top">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-8 text-left p-0">
                    <h5
                      className="modal-title  modal-order-summary-header-text "
                      id="exampleModalLabel"
                    >
                      Personal Information
                    </h5>
                  </div>
                </div>
              </div>
              <img
                src="images/modal-img/cross-img.svg"
                className="close custom-close"
                data-dismiss="modal"
                aria-label="Close"
              ></img>
            </div>
            <div className="modal-body">
              <div className="container-fluid">
                <div className="row text-black">
                  <div className="col-12 d-flex justify-content-center p-0">
                    {/* btn */}
                    <div
                      className={
                        btnClick
                          ? " col-sm-6   col-lg-6 text-center custom-btn mt-md-0 mt-3  personalInfo-btn-clicked  custom-btn-information"
                          : " col-sm-6  col-lg-6 text-center custom-btn mt-md-0 mt-3 custom-btn-information"
                      }
                      onClick={() => {
                        setBtnClick(true);
                      }}
                    >
                      Information
                    </div>

                    <div
                      className={
                        btnClick
                          ? "  col-sm-6   col-lg-6 text-center custom-btn mt-md-0 mt-3 custom-btn-Certificates"
                          : "col-sm-6  col-lg-6 text-center custom-btn personalInfo-btn-clicked custom-btn-Certificates mt-md-0 mt-3 "
                      }
                      onClick={() => {
                        certificateRecord();
                        setBtnClick(false);
                      }}
                    >
                      Certificates
                    </div>
                  </div>
                </div>
                {btnClick ? (
                  <>
                    <div className="row text-black personalInfo-popup-header pt-4">
                      {/* sub-Heading*/}
                      <div>
                        {props?.data?.first_middle_name +
                          ", " +
                          props?.data?.last_name_suffix}
                      </div>
                    </div>

                    <div className="row text-black pt-3">
                      {/* Content*/}
                      <div className="col-3 personalInfo-popup-text-orange p-0 text-left">
                        Medical Information
                      </div>
                      <div className="col-9  plborderleft  pl-3 p-0 d-flex">
                      <div className="col-6 personalInfo-popup-text  p-0">
                        <div className="mb-2 text-left ">
                          Medical Class: {props?.data?.medical_class || "N/A"}
                        </div>
                        <div className="pt-1 text-left">
                          BasicMed Course
                        </div>
                        <div className="pt-1 text-left  mb-2">
                         Date: {props?.data?.basic_med_course_date || "N/A"}
                        </div>
                      </div>
                      <div className="col-6 personalInfo-popup-text  p-0">
                        <div className="mb-2 d-block text-left ">
                          Medical Date: {props?.data?.medical_date || "N/A"}
                        </div>
                        <div className="pt-1 text-left ">
                          BasicMed CMEC
                        </div>
                        <div className="pt-1 text-left ">
                          Date: {props?.data?.basic_med_cmec_date || "N/A"}
                        </div>
                      </div>
                      </div>
                     
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row pt-3 flex-column">
                      {certificatRecord.length > 0 ? (
                        <>
                          <div className="col-12 d-flex personal-certificates p-0 ">
                            {certificatRecord.map(
                              (item: any, index: number) => (
                                <div
                                  key={item.record.cert_name}
                                  className={navi===index?`custom-pilot-colorChange text-black text-nowrap p-1`:`text-black text-nowrap p-1 sub-header-border`}
                                  onClick={() => setNavi(index)}
                                >
                                  {item.record.cert_name}
                                </div>
                              )
                            )}
                          </div>

                          {/* sub-Heading*/}
                          <div className="row text-black personalInfo-popup-header pt-4">
                            <div className="col text-left">
                            {props?.data?.first_middle_name +
                          ", " +
                          props?.data?.last_name_suffix}
                              <span className="fw-base">
                                (Certificate: {certificatRecord?.[navi]?.record?.cert_name})
                              </span>
                            </div>
                          </div>

                          {/* Rating */}
                          <div className="col">
                            {certificatRecord?.[navi]?.record?.rating_names && (
                              <div className="row text-black pt-3">
                                <div className="col-2 personalInfo-popup-text-orange p-0 text-left">
                                  Rating
                                </div>
                                <div className="col-10 personalInfo-popup-text  plborderleft p-0">
                                  {certificatRecord?.[
                                    navi
                                  ]?.record?.rating_names?.map((items: any) => {
                                    return (
                                      <div className="mb-2 text-left pl-4">
                                        {items}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}

                            {/* Type Rating */}
                            {certificatRecord?.[navi]?.record?.type_ratings?.length>0  && (
                              <div className="row text-black pt-3">
                                <div className="col-2 personalInfo-popup-text-orange p-0 text-left">
                                  Type Rating
                                </div>
                                <div className="col-10 personalInfo-popup-text  plborderleft p-0 d-flex justify-content-around text-wrap">
                                  {certificatRecord?.[
                                    navi
                                  ]?.record?.type_ratings?.map((items: any) => {
                                    return (
                                      <div className="mb-2 text-left pl-4 ">
                                        {items}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
