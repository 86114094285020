import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import apiClient from "./FetchClient";

interface PilotRecords {
  success: "",
  records:[]
}
interface Records{
    id:"",
    designation:""
}
const initialState:PilotRecords = {
  success: "",
  records:[]
};

// ------- Pilot Record  -----
export const pilotRecord = createAsyncThunk(
  "pilotRecord/record",
  async (body:Records, thunkAPI) => {
    const {id,designation}=body;
    try {
      const response = await apiClient.get(
        `${process.env.REACT_APP_API_URL}/free_pilot_cert_record?id=${id}&type=${designation}`,
      );
      if (response.data) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);

export const Export_Page = createAsyncThunk(
  "export/record",
  async (body:any, thunkAPI) => {
    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/new_pilot_record`,body
      );
      if (response.data) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);

export const ExportQuickAircraftRecords = createAsyncThunk(
  "export/aircraft",
  async (body:any, thunkAPI) => {
    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/new_aircraft_record`,body
      );
      if (response.data) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);

export const TaskStatus = createAsyncThunk(
  "export/quickSearchTaskStatus",
  async (body:any, thunkAPI) => {
    try {
      const response = await apiClient.get(
        `${process.env.REACT_APP_API_URL}/get_task_status?task_log_id=${body.task_log_id}&worker_job_id=${body.worker_job_id}`
      );
      if (response.data) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);

// ------- pilot Record Slice  ----
export const pilotRecordSlice = createSlice({
  name: "pilotRecord",
  initialState,
  reducers: {
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(pilotRecord.pending, (state: any) => {
        state.status = false;
      })
      .addCase(pilotRecord.fulfilled, (state: any, action: any) => {
        state.status = true;
        state.error = "";
        state.records=action.payload.records;
        state.response = action.payload;
      })
      .addCase(pilotRecord.rejected, (state: any, action: any) => {
        state.status = false;
        state.error = action.error.message;
      });


      builder
      .addCase(Export_Page.pending, (state: any) => {
        state.status1 = false;
      })
      .addCase(Export_Page.fulfilled, (state: any, action: any) => {
        state.status1 = true;
        state.error1 = "";
        state.records1=action.payload.records;
        state.response1 = action.payload;
      })
      .addCase(Export_Page.rejected, (state: any, action: any) => {
        state.status1 = false;
        state.error1 = action.error.message;
      });

      builder
      .addCase(ExportQuickAircraftRecords.pending, (state: any) => {
        state.status2 = false;
      })
      .addCase(ExportQuickAircraftRecords.fulfilled, (state: any, action: any) => {
        state.status2 = true;
        state.error2 = "";
        state.records2=action.payload.records;
        state.response2 = action.payload;
      })
      .addCase(ExportQuickAircraftRecords.rejected, (state: any, action: any) => {
        state.status2 = false;
        state.error2 = action.error.message;
      });

      builder
      .addCase(TaskStatus.pending, (state: any) => {
        state.taskStatus = false;
      })
      .addCase(TaskStatus.fulfilled, (state: any, action: any) => {
        state.taskStatus = true;
        state.taskError = "";
        state.taskResponse=action.payload.records;
        state.taskResponse = action.payload;
      })
      .addCase(TaskStatus.rejected, (state: any, action: any) => {
        state.taskStatus = false;
        state.taskError = action.error.message;
      });

  },
});

export const userList = (state: RootState) => state.pilotRecords;
export default pilotRecordSlice.reducer;