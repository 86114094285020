import { useAppDispatch, useAppSelector } from "../../../hook";
import { ToastContainer, toast } from "react-toastify";
import { FreeTrial } from "../RepeatedComponent/FreeTrial";
import { Link } from "react-router-dom";
import { AMEHealthPopup } from "../RepeatedComponent/AMEHealthPopup";
import { useEffect, useState } from "react";
import { callToaster } from "../../../Features/authSlice";
import { useSelector } from "react-redux";

const HomePage = () => {
  const dispatch = useAppDispatch();
  const [showComponent, setShowComponent] = useState(false);
  const fulfilled = useAppSelector((state: any) => state.auth.response);
  const status = useAppSelector((state: any) => state.auth.status);
  const reduxData = useSelector((state: any) => {
    return state;
  });
  const handleClick = () => {
    setShowComponent(true);
  };

  useEffect(() => {
    if (reduxData?.auth?.showToast) {
      toast.success(reduxData?.auth?.showToastMessage, {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 8000,
        pauseOnFocusLoss: true,
        theme: "dark",
        onClose: (e) => closingToast(e),
      });
    } else {
    }
  }, []);

  const closingToast = (e: any) => {
    const changeData: object = { show: false, msg: "" };
    e = { ...e, changeData };
    dispatch(callToaster(e));
  };

  return (
    <>
      {/*Home Window  */}
      <div
        id="hs_cos_wrapper_widget_34472035839"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_custom_widget"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="custom_widget"
      >
        {/* Label For Modules Data Page */}
        <div className="widget_1601501547743 sr-hero-01 sr-cover-parallax  prototype-no-background  homebg">
          <div className="sr-cover-image home-window-bg-img" />
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="col-12">
                  <div className="sr-cover d-flex align-items-center  text-center height-medium">
                    <div className="sr-cover-inner text-white">
                      <h6 className="font-inter text-white">
                        Databases that offer you more than just data.
                      </h6>
                      <h1 className="section-heading text-white">
                        <span className="ft-lg">Most Trusted </span> Provider of{" "}
                        <br></br> Aviation Information
                      </h1>
                      <div className="sr-spacer-topx-0 py-25 cta-wrapper">
                        <div className="btn-wrapper btn-primary-wrapper">
                          {status == true ? (
                            <>
                              <Link
                                to="#"
                                onClick={handleClick}
                                data-toggle="modal"
                                data-target="#ameHealthModal"
                                className="cta_button"
                                style={{
                                  letterSpacing: " 1px",
                                  wordSpacing: "1px",
                                }}
                              >
                                AME Health Certificate List
                              </Link>
                              {showComponent && <AMEHealthPopup />}
                            </>
                          ) : (
                            <Link
                              to="/register"
                              className="cta_button"
                              style={{
                                letterSpacing: " 1px",
                                wordSpacing: "1px",
                              }}
                            >
                              START YOUR FREE 7-DAY TRIAL TODAY
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="hs_cos_wrapper_widget_34472035840"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_custom_widget"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="custom_widget"
      >
        <div className="sr-multicol-media sr-spacer-50 sr-three-col-01 bg-none  text-center prototype-no-background widget_34472035840 ">
          <div className="container-fluid">
            <div className="row d-flex justify-content-center">
              <div className="col-10">
                <h2 className="h2 section-heading">
                  Powerful Aviation Data + Targeted Marketing
                </h2>
                <div className="section-description mob-textsize">
                  <p>
                    Increase efficiency and find more opportunities.
                    PlaneLists.com helps you locate, evaluate and market to
                    highly-targeted aviation prospects.
                  </p>
                </div>
                <div className="row sr-valign-top">
                  <div className="col-md-4 sr-col text-left  text-  sr-repeater-1 ">
                    <div className="content-wrapper">
                      <ToastContainer />
                      <div className="responsive-image mb-4">
                        <img
                          src="images/HomePageImg/HomeImg4.jpg"
                          alt="identify-leads@2x"
                          width={740}
                          height={548}
                          sizes="(max-width: 740px) 100vw, 740px"
                        />
                      </div>
                      <h4 className="col-heading font-inter text-center ">
                        Discover AME Patients
                      </h4>
                      <div className="col-content mob-textsize">
                        <p>
                          Designed for Aviation Medical Examiners (AMEs),
                          PlaneLists.com simplifies the search for pilot and
                          non-pilot aviation groups, including air traffic
                          controllers, mechanics, and inspectors.
                        </p>
                        <div className="unorder-list pl-3 pl-sm-home  font-bold">
                          <strong>
                            {" "}
                            <ul>
                              <li>Choose certifications and medical dates.</li>
                              <li>Centralize data by location.</li>
                              <li>Focus on specific qualifications.</li>
                              <li>
                                Streamline identification of individuals needing
                                medical examinations.
                              </li>
                            </ul>
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 sr-col text-left  text-  sr-repeater-2 ">
                    <div className="content-wrapper">
                      <div className="responsive-image mb-4">
                        <img
                          src="images/HomePageImg/HomePage2.png"
                          alt="research-property@2x"
                          width={740}
                          height={548}
                          sizes="(max-width: 740px) 100vw, 740px"
                        />
                      </div>
                      <h4 className="col-heading font-inter  text-center ">
                        Find Off-Market Aircraft
                      </h4>
                      <div className="col-content mob-textsize">
                        <p>
                          Plane flippers can easy searches for potentially
                          available aircraft. Effortlessly locate planes with
                          expired certifications or registrations using the
                          intuitive sorting options like manufacture, class,
                          power plant, and more.
                        </p>
                        <div className="unorder-list pl-3 pl-sm-home font-bold">
                          <strong>
                            <ul>
                              <li>Simple Search and Sorting.</li>
                              <li>Custom Access to Aircraft Owners</li>
                              <li>Intuitive Design for User-Friendliness.</li>
                              <li>Organize by Aircraft Type Easily.</li>
                            </ul>
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 sr-col text-left  text-  sr-repeater-3 ">
                    <div className="content-wrapper">
                      <div className="responsive-image mb-4">
                        <img
                          src="images/HomePageImg/HomePage3.png"
                          alt="market-to-lead@2x"
                          width={740}
                          height={548}
                          sizes="(max-width: 740px) 100vw, 740px"
                        />
                      </div>
                      <h4 className="col-heading font-inter text-center">
                        Top Sales Opportunities
                      </h4>
                      <div className="col-content mob-textsize">
                        <p>
                          Aviation sales professionals unlock targeted
                          opportunities with a blend of pilot certifications,
                          strategic locations, and aircraft types. Catering to
                          insurance, real estate, investment advising, and
                          flight school sales, ensuring efficient access to:
                        </p>
                        <div className="unorder-list pl-3 pl-sm-home font-bold">
                          <strong>
                            <ul>
                              <li>Identify New Aircraft Owners.</li>
                              <li>Reach Pilots in High Net-Worth Areas.</li>
                              <li>Target Aircraft Owners by Type.</li>
                              <li>Locate Pilots in Specific Zip Codes</li>
                            </ul>
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div
        id="hs_cos_wrapper_widget_34472050333"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_custom_widget"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="custom_widget"
      >
        {status ? (
          ""
        ) : (
          //  7-day free trial component
          <FreeTrial />
        )}
      </div>
    </>
  );
};

export default HomePage;
