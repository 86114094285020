import React, { useState } from "react";
import { BiBuildings } from "react-icons/bi";
import { FaWhatsapp, FaPhoneAlt } from "react-icons/fa";
import "./contact.css";
import Iframe from "react-iframe";
import { FreeTrial } from "../RepeatedComponent/FreeTrial";
import { useAppDispatch, useAppSelector } from "../../../hook";
import { Breadcrumbs } from "../RepeatedComponent/Breadcrumb";
import ReCAPTCHA from "react-google-recaptcha";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { contactUs, ContactUsData } from "../../../Features/contactUsSlice";
import { ToastContainer, toast } from "react-toastify";
import { Loader } from "../RepeatedComponent/Loader";

export const Contact = () => {
  const [loading, setLoading] = useState(false);
  const status = useAppSelector((state) => state.auth.status);
  const iconSize = 40;

  const dispatch = useAppDispatch();

  const recaptchaRef = React.createRef<ReCAPTCHA>();

  const handleSubmit = (values: ContactUsData, actions: any) => {
    setLoading(true);

    if (values.first_name && values.last_name && values.message) {
      if (!values.tokencap) {
        toast.error("ReCaptcha Validation Failed", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 8000,
          pauseOnFocusLoss: true,
          theme: "dark",
        });
        setLoading(false);
        return;
      } else {
        dispatch(contactUs(values)).then((res: any) => {
          if (res.payload.id) {
            document.getElementById("resetReacptcha")?.click();
            setLoading(false);
            actions.resetForm();
            actions.resetForm({ tokencap: "" });
            toast.dismiss();
            toast.success("Uploaded Successfully", {
              position: "top-center",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 8000,
              pauseOnFocusLoss: true,
              theme: "dark",
            });
          }
        });
      }
    } else {
      toast.dismiss();
      toast.error("Please fill your details", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 8000,
        pauseOnFocusLoss: true,
        theme: "dark",
      });
      setLoading(false);
    }
  };
  return (
    <>
      <ToastContainer />
      {loading ? <Loader /> : ""}
      <div className="sr-multicol-media bg-black pt-1 pt-lg-4 text-center  prototype-no-background widget_1600979911688 ">
        <div className="container-fluid content-wrapper pt-2 pt-lg-4">
          <div className="row d-flex justify-content-center pt-1 pt-md-3 pt-lg-5 ">
            <div className="col-md-10">
              <Breadcrumbs textColor={"text-white"} />
            </div>
          </div>
        </div>
      </div>
      <div
        className="sr-multicol-media bg-black sr-padding-half text-center prototype-no-background 
        widget_1604012269835 text-white"
        id="research-property"
      >
        <div className="container-fluid content-wrapper">
          <div className="row justify-content-center  ">
            <div className="col-md-10">
              <h2 className=" section-heading font-inter">Contact US</h2>
              <button
                id="resetReacptcha"
                onClick={() => recaptchaRef.current!.reset()}
              ></button>
              <div className="row pt-0 pt-lg-2 d-flex flex-column-reverse flex-lg-row">
                <div className="col-12 col-lg-6 sr-col text-left  sr-repeater-1">
                  <div className="container">
                    <h2
                      className="text-center text-lg-left pt-3 pt-lg-0 text-white"
                      style={{ fontFamily: "Inter, sans-serif" }}
                    >
                      Questions? We are here to help!
                    </h2>
                    <p className="text-white">
                      <b>
                        If you would like a personalized tour of the system or
                        have general questions; please contact us at any time.
                      </b>
                    </p>
                    <div className="content-wrapper">
                      <div className="row pt-2">
                        <div className="col-6 col-lg-6 d-flex  flex-column justify-content-evenly">
                          <p className="text-white pb-1">
                            <BiBuildings size={iconSize} className="pb-1" />
                          </p>
                          <p className="text-white pb-1">
                            <b>Mailing Address</b>
                          </p>
                          <p className="text-white text-break pb-1">
                            <b>
                              PlaneLists.com
                              <br />
                              336 Airport Road
                              <br />
                              Ridgeland, SC 29936
                            </b>
                          </p>
                        </div>
                        <div className="col-6 col-lg-6 d-flex  flex-column justify-content-evenly">
                          <p className="text-white pb-1">
                            <FaWhatsapp size="25" /> <b>Planelists.com</b>
                          </p>
                          <p className="text-white pb-1">
                            <FaPhoneAlt size="20" className="ml-1" />{" "}
                            <b>(843) 321-8973</b>
                          </p>
                          <p className="text-white text-break pb-1">
                            <b>support@planelists.com</b>
                          </p>
                        </div>
                      </div>

                      <p className="text-white pt-1">
                        <b>
                          Hours of operation:
                          <br />
                          0900- 1700 Eastern Time, Monday through Friday
                          (generally...)
                        </b>
                      </p>
                      <div
                        className="row d-flex flex-column  justify-content-start  
                          align-items-sm-center flex-xs-column flex-sm-column flex-md-row"
                      >
                        <div className="container d-flex justify-content-start">
                          <Formik
                            initialValues={{
                              success: "",
                              first_name: "",
                              last_name: "",
                              message: "",
                              phone_number: "",
                              email: "",
                              tokencap: "",
                            }}
                            onSubmit={handleSubmit}
                            enableReinitialize
                          >
                            {(formikProps) => (
                              <Form className="w-100">
                                <div className="row ">
                                  <div className="col-lg-5 col-12">
                                    <div className="form-group">
                                      <Field
                                        type="text"
                                        name="first_name"
                                        className="custom-form-control w-100  p-0 pt-3 pb-1 bg-black text-white"
                                        placeholder="First Name"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-5 col-12">
                                    <div className="form-group">
                                      <Field
                                        type="text"
                                        name="last_name"
                                        className="custom-form-control w-100 p-0 pt-3 pb-1 bg-black text-white"
                                        placeholder="Last Name"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="mb-1  form-floating">
                                  <Field
                                    as="textarea"
                                    name="message"
                                    className="form-control bg-black text-white"
                                    placeholder="Your message here..."
                                    style={{ height: "100px" }}
                                  />
                                </div>

                                <div className="row d-flex justify-content-center ">
                                  <div className="g-recaptcha col-12 py-2 py-lg-3">
                                    <ReCAPTCHA
                                      ref={recaptchaRef}
                                      sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
                                      onChange={(token) =>
                                        formikProps.setFieldValue(
                                          "tokencap",
                                          token
                                        )
                                      }
                                      onExpired={() => {
                                        recaptchaRef.current!.reset();
                                      }}
                                    />
                                  </div>
                                  <ErrorMessage
                                    name="tokencap"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>

                                <button
                                  type="submit"
                                  className="btn justify-content-center"
                                  style={{
                                    backgroundColor: "#FF7125",
                                    color: "#FFFFFF",
                                    textAlign: "center",
                                    display: "block",
                                    border: "none",
                                    borderRadius: "8px",
                                    width: "90px",
                                    height: "36px",
                                  }}
                                  // disabled={formikProps.isSubmitting}
                                >
                                  <span className="text-white">Send</span>
                                </button>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-6 sr-col  sr-repeater-2 d-flex justify-content-center ">
                  <div className=" ">
                    <Iframe
                      url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3365.2773874487975!2d-80.99345422552769!3d32.49201449849858!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88fbefccdb82a00f%3A0x24b83f6ec7ce51d6!2sPlaneLists.com!5e0!3m2!1sen!2sin!4v1707132449244!5m2!1sen!2sin"
                      width="640px"
                      frameBorder={0}
                      id=""
                      className="contact-iframe "
                      display="block"
                      position="relative"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {status ? "" : <FreeTrial />}
    </>
  );
};
