import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import apiClient from "./FetchClient";
import axios from "axios";

interface LoginState {
  user: {
    email: "",
    password: ""
  },
  showToast: Boolean,
  showToastMessage: string,
  status: Boolean,
  success: "";
  error: ""
  response: ""
}
interface SignupBody {
  email: string;
  password: string;
  password_confirmation: string;
  name: string;
  temp_sub_id?: string;
  coupon_code?: string;
}

const initialState: LoginState = {
  user: {
    email: "",
    password: ""
  },
  showToast: false,
  showToastMessage: "",
  status: false,
  success: "",
  error: "",
  response: ""
};

interface LoginBody {
  email: string;
  password: string;
}


interface ChangePasswordState {
  user: {
    // email: "",
    password: ""
    password_confirmation: '',
  },
  showToast: Boolean,
  showToastMessage: string,
  status: Boolean,
  success: "";
  error: ""
  response: ""
}

interface ChangePasswordBody {
  password: string;
  password_confirmation: string;
}

// ------- Login -----
export const userLogin = createAsyncThunk(
  "userLogin/login",
  async (body: LoginBody, thunkAPI) => {
    const { email, password } = body;

    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/authentication_tokens/create`,
        {
          user: {
            email,
            password,
          }
        }
      );

      if (response.data.success) {
        localStorage.setItem("_authToken", response.data.jwt);
        return response.data.success;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "user/Forgotpassword",
  async (body: LoginBody, thunkAPI) => {
    const { email } = body;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/forgot_user`, {
        email: email
      });

      if (response.data.success) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);

export const forgotEmail = createAsyncThunk(
  "user/ForgotEmail",
  async (body: any, thunkAPI) => {
    const { firstName, lastName } = body;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/get_email`, {
        name: `${firstName} ${lastName}`
      });

      if (response.data.success) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);

// ------- Signup -----
export const userSignup = createAsyncThunk(
  "userSignup/signUp",
  async (body: SignupBody, thunkAPI) => {
    const { email, password, password_confirmation, name, temp_sub_id, coupon_code } = body;

    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/register`,
        {
          user: {
            email,
            password,
            password_confirmation,
            name,
            temp_sub_id,
            coupon_code
          }
        }
      );

      if (response.data) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);

// ------- logout -----
export const userLogout = createAsyncThunk(
  "userLogout/logout",
  async (body, thunkAPI) => {
    try {
      const response = await apiClient.delete(
        `${process.env.REACT_APP_API_URL}/authentication_tokens/delete`,
      );

      if (response.data.success) {
        localStorage.removeItem('_authToken');
        return response.data.success;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);

//------- Change Password -----
export const userChangePassword = createAsyncThunk(
  "userChangePassword/changepassword",
  async (body: ChangePasswordBody, thunkAPI) => {
    const { password, password_confirmation } = body
    const token = localStorage.getItem("_authToken");
    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/change_password`,

        {
          user: {
            password,
            password_confirmation
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }

      );

      if (response.data.success) {
        localStorage.removeItem('_authToken');
        return response.data.success;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);


// ------- createSlice for all ----
export const authSlice = createSlice({
  name: "userLogin",
  initialState,
  reducers: {
    // if user is already login
    logoutTrue: (state: any) => {
      state.status = false;
      state.error = "";
      return state;
    },
    loginTrue: (state: any) => {
      state.status = true;
      state.error = "";
      return state;
    },
    clearState: (state: any) => {
      state.response = "";
      state.error = "";
    },
    callToaster: (state: any, action: any) => {
      state.showToast = action?.payload?.show;
      state.showToastMessage = action.payload.msg;
      // return state;
    },
  },

  extraReducers: (builder: any) => {
    builder
      .addCase(userLogin.pending, (state: any) => {
        state.status = false;
      })
      .addCase(userLogin.fulfilled, (state: any, action: any) => {
        state.status = true;
        state.error = "";
        state.response = action.meta.requestStatus;
        state.user = {
          email: action.payload.email,
          password: action.payload.password
        };
      })
      .addCase(userLogin.rejected, (state: any, action: any) => {
        state.status = false;
        state.error = action.error.message;
      });

    // userSignup
    builder
      .addCase(userSignup.pending, (state: any) => {
        state.status = false;
      })
      .addCase(userSignup.fulfilled, (state: any, action: any) => {
        state.status = true;
      })
      .addCase(userSignup.rejected, (state: any, action: any) => {
        state.status = false;
        state.error = action.error.message;
      });

    // userLogout
    builder
      .addCase(userLogout.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(userLogout.fulfilled, (state: any, action: any) => {
        state.status = false;
        state.response = "logout"
      })
      .addCase(userLogout.rejected, (state: any, action: any) => {
        state.status = true;
        state.error = action.error.message;
      });

    // userChangePassword
    builder
      .addCase(userChangePassword.pending, (state: any) => {
        state.status = false;
      })
      .addCase(userChangePassword.fulfilled, (state: any, action: any) => {
        state.status = false;
      })
      .addCase(userChangePassword.rejected, (state: any, action: any) => {
        // state.status = false;
        state.error = action.error.message;
      });

    builder
      .addCase(forgotPassword.pending, (state: any) => {
        state.status1 = true;
      })
      .addCase(forgotPassword.fulfilled, (state: any, action: any) => {
        state.status1 = true;
      })
      .addCase(forgotPassword.rejected, (state: any, action: any) => {
        // state.status = false;
        state.error1 = action.error.message;
      });
  },
});

export const userList = (state: RootState) => state.auth;
export const { loginTrue, clearState, logoutTrue, callToaster } = authSlice.actions;
export default authSlice.reducer;