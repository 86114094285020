import { useState } from "react";
import * as React from "react";
import "./offMarketForm.css";
import { useAppDispatch, useAppSelector } from "../../../hook";
import { Field, Formik, Form, ErrorMessage } from "formik";
import { exportsRecordsAircraft } from "../../../Features/exportsRecordsSlice";
import { ameZipCode } from "../../../Features/ameZipCodeSlice";
import { Loader } from "../RepeatedComponent/Loader";
import dayjs, { Dayjs } from "dayjs";
import { getPilotStates } from "../../../Features/downloadHistorySlice";
import { ToastContainer, toast } from "react-toastify";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import apiClient from "../../../Features/FetchClient";
import { TaskStatus } from "../../../Features/pilotRecordSlice";
import { userList } from "../../../Features/fetchUserSlice";
interface Option {
  label: string;
  value: string;
}

const animatedComponents = makeAnimated();
export interface StateOption {
  readonly value: string;
  readonly label: string;
}

export const OffMarketForm = () => {

  const dispatch = useAppDispatch();
  const [selectedOneMonth, setselectedOneMonth] = useState("30 Days");
  const [loading, setLoading] = useState(0);
  const [manufactureForm, setManufactureForm] = useState({
    value: "",
    lable: "ALL",
  });
  const [manufactureTo, setManufactureTo] = useState({
    value: "",
    lable: "ALL",
  });
  const [location, setLocation] = useState("state");
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [selectedOptionsAircraftTypes, setSelectedOptionsAircraft] = useState<
    Option[]
  >([]);
  const [selectrdEngineTypes, setSelectrdEngineTypes] = useState<Option[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [aircraftManufacturer, setAircraftManufacturer] = useState<string[]>(
    []
  );
  const [modelType, setModelType] = useState<Option[]>([]);
  const [modelTypeOptions, setModelTypeOptions] = useState<Option[]>([]);
  const [options, setOptions] = useState<Option[]>([]);
  const [aircraftModel, setAircraftModel] = useState<string[]>([]);
  const [aircraftTypes, setAircraftTypes] = useState<string[]>([]);
  const [engineTypes, setEngineTypes] = useState<string[]>([]);
  const export_work = useAppSelector(userList);
  // const [startDate, setStartDate] = React.useState<Dayjs | null>(dayjs("MM-DD-YYYY"));

  const [certificateDate, setCertificateDate] = useState<Dayjs | null>(
    dayjs("MM-DD-YYYY")
  );
  const [lastActionDate, setLastActionDate] = useState<Dayjs | null>(
    dayjs("MM-DD-YYYY")
  );
  const [airWorthynessDate, setAirWorthynessDate] = useState<Dayjs | null>(
    dayjs("MM-DD-YYYY")
  );
  const [endDate, setEndDate] = React.useState<Dayjs | null>(
    dayjs("MM-DD-YYYY")
  );
  const [selectedTab, setSelectedTab] = useState("pilot");
  const [modelName, setModelName] = useState<Option[]>([]);

  const marketingDistance = [
    {
      value: "selected",
      label: "Please Select",
      isDisabled: true,
    },
  ];

  // Generating miles from 10 to 200
  for (let miles = 10; miles <= 200; miles += 10) {
    marketingDistance.push({
      value: miles.toString(),
      label: `${miles} Miles`,
      isDisabled: false,
    });
  }

  const geoLocation = [
    { value: "Al", label: "Alaskan" },
    { value: "Ce", label: "Central" },
    { value: "Ea", label: "Eastern" },
    { value: "Fo", label: "Foreign" },
    { value: "Gl", label: "Great Lakes" },
    { value: "Nm", label: "Northwest Mountain" },
    { value: "So", label: "Southern" },
    { value: "Sw", label: "Southwestern" },
    { value: "Wp", label: "Western-Pacific" },
  ];

  const colourStyles = {
    control: (styles: any) => ({
      ...styles,
      background: "white",
      borderColor: "white",
      margin: 0,
      marginLeft: 0,
      borderWidth: "0px", // Changed 'border' to 'borderWidth'
      borderStyle: "solid", // Added 'borderStyle' to specify border style
      fontSize: 14,
      outline: "none !important",
      boxShadow: "none",
      "&:hover": {
        borderColor: "white",
      },
    }),
    input: (styles: any) => ({ ...styles, color: "black" }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      color: "gray",
      fontSize: 14, // Removed quotes around 14
    }),
    multiValue: (styles: any) => ({
      ...styles,
      backgroundColor: "#E35D22",
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 9999,
      background: "white",
      "&:hover": {
        backgroundColor: "red",
      },
    }),
    menuList: (base: any) => ({
      ...base,
      height: 160,
      minHeight: 160,
      padding: 0,
      color: "black",
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "white",
      },
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      color: "white",
      textAlign: "left",
    }),
  };

  const singleSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: "white",
      borderColor: "white",
      margin: 0,
      marginLeft: 0,
      border: "0px solid black",
      fontSize: 13,
      outline: "none !important",
      boxShadow: "none",
      "&:hover": {
        borderColor: "white",
      },
    }),
    input: (styles: any) => ({ ...styles, color: "black" }),
    placeholder: (styles: any) => ({
      ...styles,
      color: "black",
      fontSize: "14",
    }),
    menuList: (base: any) => ({
      ...base,
      height: 200,
      minHeight: 200,
      padding: 0,
      color: "black",
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "white",
      },
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: "black",
      fontSize: "1rem",
    }),
  };

  const TypeAircraft = [
    { value: "2", label: "Balloon" }, //2
    { value: "3", label: "Blimp/Dirigible" }, //3
    { value: "5", label: "Fixed Wing Multi Engine" }, //5
    { value: "4", label: "Fixed Wing Single Engine" }, //4
    { value: "1", label: "Glider" }, //1
    { value: "9", label: "Gyroplane" }, //9
    { value: "H", label: "Hybrid Lift" }, //H
    { value: "8", label: "Powered Parachute" },
    { value: "6", label: "Rotorcraft" }, //6
    { value: "7", label: "Weight-Shift-Control" }, //7
    { value: "O", label: "Other" }, //O
  ];

  const engineTypesName = [
    { value: "7", label: "2 Cycle" },
    { value: "8", label: "4 Cycle" },
    { value: "10", label: "Electric" },
    { value: "0", label: "None" },
    { value: "6", label: "Ramjet" },
    { value: "1", label: "Reciprocating" },
    { value: "11", label: "Rotary" },
    { value: "5", label: "Turbo-fan" },
    { value: "4", label: "Turbo-jet" },
    { value: "2", label: "Turbo-prop" },
    { value: "3", label: "Turbo-shaft" },
    { value: "9", label: "Unknown" },
  ];

  const years: any = [];
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const [endYears, setEndYears] = useState([]);

  const handleStartYearChange = (selectedOption: any) => {
    setManufactureForm(selectedOption);
    if (selectedOption) {
      const filteredEndYears = years.filter(
        (year: any) => parseInt(year.value) >= parseInt(selectedOption.value)
      );
      setEndYears(filteredEndYears);
    } else {
      setEndYears([]);
    }
  };

  for (let year = currentYear; year >= 1930; year--) {
    years.push({
      value: year.toString(),
      label: year.toString(),
    });
  }

  const [zipData, setZipData] = useState<any>({
    zipCode: 0,
    distance: 0,
    success: "",
  });
  const onChangeZipCode = (e: any) => {
    zipData.zipCode = e.target.value;
    const { value } = e.target;
    if (value?.length > 9) {
      e.target.value = value.slice(0, 9); // Limit to 9 characters
    }
    if (zipData.zipCode && zipData.distance) {
      dispatch(ameZipCode(zipData)).then((res: any) => {
      });
    }
  };

  const initialValues1 = {
    medicalExpiring: selectedOneMonth,
    export_file: "CSV",
    pilotype: "owner_name",
    manufactureStartDate: "All",
  };
  const handleSubmitSetData = (values: any) => {
    // setLoading(1);
    if (export_work.can_download) {
      values = {
        aircraftManufacturer,
        aircraftModel,
        aircraftTypes,
        engineTypes,
        ...values,
      };

      let updatedValues = {
        registration_number: values.registration_number || "N",
        registration_number_check:
          values.locations1 === "exactmatch1"
            ? "exact_number"
            : "contain_number",
        name: values.ownerName || "",
        name_check:
          values.locations2 === "exactmatch2" ? "exact_string" : "sub_string",
        address: values.address || "",
        address_check:
          values.locations3 === "exactmatch3" ? "exact_string" : "sub_string",
        city_name: values.city || "",
        city_name_check:
          values.locations4 === "exactmatch4" ? "exact_string" : "sub_string",
        region_or_state: selectedOptions.map((item) => item.value),
        region_type: location,
        // include_airman: includeAirman,

        certificate_date: !isNaN(Number(certificateDate?.get("date")))
          ? certificateDate?.format("MM/DD/YYYY")
          : "",
        certificate_date_check:
          values.lastAction1 === "Least1"
            ? "at_least"
            : "equal_to_or_more_than",
        last_action_date: !isNaN(Number(lastActionDate?.get("date")))
          ? lastActionDate?.format("MM/DD/YYYY")
          : "",
        last_action_date_check:
          values.lastAction2 === "Least2"
            ? "at_least"
            : "equal_to_or_more_than",
        airworthiness_date: !isNaN(Number(airWorthynessDate?.get("date")))
          ? airWorthynessDate?.format("MM/DD/YYYY")
          : "",
        airworthiness_date_check:
          values.lastAction3 === "Least3"
            ? "at_least"
            : "equal_to_or_more_than",
        manufacture_year_from: manufactureForm.value,
        manufacture_year_to: manufactureTo.value,
        aircraft_name: selectedOptionsAircraftTypes.map((ele) => ele.value),
        e_name: selectrdEngineTypes.map((ele) => ele.value),
        expire: selectedOneMonth,
        expiry: values.expiry || "All",
        airman: selectedTab,
        m_name:
          modelName.length > 0 ? modelName.map((ele: any) => ele.label) : [],
        airman_class: values.airmenclass || "",
        model_name:
          modelType.length > 0 ? modelType.map((ele: any) => ele.label) : [],
        sort_type: "registrant_name",
        sort_type_order: values.order || "asc",
        export_file: values.export_file,
        task_type: "aircraft",
      };
      values = updatedValues;
      setLoading(1);
      const payload: any = {
        zipCode: zipData.zipCode,
        distance: zipData.distance,
        success: "", // Ensure that the payload matches the expected shape of ZipCodeState
      };

      dispatch(ameZipCode(payload)).then((res: any) => {
        if (res.payload.length > 0) {
          // Handle the result of the async thunk action
          const zipCodes = res.payload.map((item: any) =>
            item.ZipCode.toString()
          );
          values.zip_code = zipCodes;
        } else {
          setZipResponse([]);
          values.zip_code = [];
        }

        dispatch(exportsRecordsAircraft({ ...values }))
          .then((res: any) => {
            setZipResponse([]);
            console.log(
              {
                taskID: res.payload.task_log_id,
                WorkerId: res.payload.worker_job_id,
              },
              "after api response in AME Patients Lead"
            );
            setLoading(0);
            const body: any = {
              task_log_id: res.payload.task_log_id,
              worker_job_id: res.payload.worker_job_id,
            };
            dispatch(TaskStatus(body))
              .then((res: any) => {
                let data = res.payload;
                if (
                  data.file_info &&
                  data.file_info.path &&
                  data.active_status == false
                ) {
                  if (data.records_found > 0) {
                    toast.success(
                      "Export request has been submitted successfully. Your file will download shortly.",
                      {
                        position: "top-center",
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 8000,
                        pauseOnFocusLoss: true,
                        theme: "dark",
                      }
                    );
                  } else {
                    toast.error("No criteria match for this query.", {
                      position: "top-center",
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      draggablePercent: 8000,
                      pauseOnFocusLoss: true,
                      theme: "dark",
                    });
                  }
                } else if (data.active_status == true) {
                  toast.success(
                    "Export request has been submitted successfully. Your file will download shortly.",
                    {
                      position: "top-center",
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      draggablePercent: 8000,
                      pauseOnFocusLoss: true,
                      theme: "dark",
                    }
                  );
                }
              })
              .catch((err: any) => {
                console.log(err);
              });
          })
          .catch((err: any) => {
            setLoading(0);
            console.log(err, "API error. ");
          });
      });
    } else {
      toast.error(export_work.can_download_msg, {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 8000,
        pauseOnFocusLoss: true,
        theme: "dark",
      });
    }
  };

  React.useEffect(() => {
    let a = {
      name: "",
      surname: "",
    };

    dispatch(getPilotStates()).then((res: any) => {
      if (
        res?.payload &&
        Array.isArray(res.payload?.states) &&
        res.payload?.states.length > 0
      ) {
        let filteredStates = res.payload.states.filter(
          (item: any) => item.state !== ""
        );
        let hello: any = filteredStates.map((item: any) => ({
          value: item.state,
          label: item.state_abbrev,
        }));
        setOptions(hello);
      }
    });
  }, []);
  const [zipResponse, setZipResponse] = useState([]);

  const searchManufacturerType = async () => {
    if (modelName?.length > 0) {
      var modelNamesStr = "";
      for (let model of modelName) {
        modelNamesStr += `manufacturer_names[]=${model.value}&`;
      }
      //manufacturer_names[]=AMSDEN/HAIR
      const result: any = await apiClient.get(
        `${process.env.REACT_APP_API_URL}/get_model_names?${modelNamesStr}`
      );
      if (result.data && Array.isArray(result.data) && result.data.length > 0) {
        const newArr = result.data.map((o: any) => {
          return { value: o, label: o };
        });
        setModelTypeOptions(newArr);
        return newArr;
      } else {
        return [];
      }
    } else {
      return [];
    }
  };
  const searchManufacturerName = async (name: string = "") => {
    if (name.length > 1) {
      const result: any = await apiClient.get(
        `${process.env.REACT_APP_API_URL}/manufacturer_names?q=${name}`
      );

      if (
        result.data &&
        Array.isArray(result.data.manufacturer_names) &&
        result.data.manufacturer_names.length > 0
      ) {
        const newArr = result.data.manufacturer_names.map((o: any) => {
          return { value: o, label: o };
        });
        return newArr;
      } else {
        return [];
      }
    } else {
      return [];
    }
  };
  React.useEffect(() => {
    // dispatch(clearState());
    searchManufacturerType();
  }, [modelName]);

  return (
    <>
      {loading ? <Loader /> : ""}
      <ToastContainer />
      {/* Popup */}
      <Formik initialValues={initialValues1} onSubmit={handleSubmitSetData}>
        {() => (
          <div className="modal-body bg-white p-0">
            <div className="container-fluid searchPlane">
              <div className="row text-black">
                {/* content*/}
                {!export_work?.can_download && (
                  <a
                    href="/subscriptions-list"
                    className=" d-flex justify-content-center w-100 py-2 popupbgcolor"
                  >
                    <center>
                      <div className="Toastify__toast-body w-100">
                        <div className="Toastify__toast-icon Toastify--animate-icon Toastify__zoom-enter">
                          <svg
                            viewBox="0 0 24 24"
                            width="100%"
                            height="100%"
                            fill="#fff"
                          >
                            <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm.25 5a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm2.25 13.5h-4a1 1 0 010-2h.75a.25.25 0 00.25-.25v-4.5a.25.25 0 00-.25-.25h-.75a1 1 0 010-2h1a2 2 0 012 2v4.75a.25.25 0 00.25.25h.75a1 1 0 110 2z"></path>
                          </svg>
                        </div>
                        <div className="text-white notification">
                          {export_work?.can_download_msg}
                        </div>
                      </div>
                    </center>
                  </a>
                )}
                <div className="col-md-12 col-sm-12 ">
                  <div className="col-12  px-4 py-3 text-left font-inter mb-3">
                    <Form>
                      {/* AME Zip code & Marketing Distance */}
                      <div className="row pt-2">
                        <div className="col-md-6 col-sm-12 p-0">
                          <div
                            className="col-12 d-flex  align-items-center custom-border-orange-field px-0"
                            style={{}}
                          >
                            <div className="custom-form-sub-searchPlane">
                              Zip Code
                            </div>
                            <Field
                              type="number"
                              className="form-control bg-white text-black border-0"
                              name="zip_code"
                              placeholder="e.g.12345"
                              style={{ font: "inherit" }}
                              aria-label="Enter Your Zip Code"
                              aria-describedby="basic-addon1"
                              autoComplete="off"
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                if (!e.target.value.startsWith("0")) {
                                  e.target.value = e.target.value.slice(0, 9);
                                  setZipData({
                                    ...zipData,
                                    zipCode: parseInt(e.target.value),
                                  });
                                } else {
                                  e.target.value = "";
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-12 pt-4 pt-md-0 px-0 pl-md-2 pl-lg-3">
                          <div className="col-12 d-flex  align-items-center custom-border-orange-field py-2 px-0">
                            <div className="custom-form-sub-searchPlane">
                              Distance
                            </div>
                            <Select
                              closeMenuOnSelect={true}
                              components={animatedComponents}
                              options={marketingDistance}
                              styles={singleSelectStyles}
                              className="w-100"
                              placeholder="Please Select"
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  text: "white",
                                  primary25: "#E35D22",
                                  primary: "black",
                                },
                              })}
                              onChange={(e: any) =>
                                setZipData({
                                  ...zipData,
                                  distance: e.value,
                                })
                              }
                              name="distance"
                            />
                          </div>
                        </div>
                      </div>

                      {/*  Aircraft Manufacturer &   Aircraft Model */}
                      <div className="row pt-3 px-0">
                        <div className="col-12 col-md-6  px-0 mt-0 newAircraft-filed-m">
                          <div className="col-12 d-flex  align-items-center custom-border-orange-field bg-white py-2 px-0">
                            <div className="custom-form-sub-searchPlane bg-white px-1">
                              Aircraft Manufacturer
                            </div>
                            <AsyncSelect
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              loadOptions={searchManufacturerName}
                              isMulti
                              options={geoLocation}
                              styles={colourStyles}
                              onChange={(e: any) => setModelName(e)}
                              placeholder="Select Manufacture"
                              className="w-100"
                              noOptionsMessage={({ inputValue }) => {
                                if (inputValue && inputValue.length < 2) {
                                  return (
                                    <span style={{ fontSize: "0.9rem" }}>
                                      Please enter 2 or more characters
                                    </span>
                                  );
                                } else {
                                  return (
                                    <span style={{ fontSize: "0.9rem" }}>
                                      No options found
                                    </span>
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 p-0 mt-4 mt-md-0 pl-lg-0 p-0 pl-md-2 pl-lg-3">
                          <div className="col-12 col-md-12 d-flex   align-items-center custom-border-orange-field bg-white py-2 px-0">
                            <div className="custom-form-sub-searchPlane bg-white px-1">
                              Aircraft Model
                            </div>
                            <AsyncSelect
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              isMulti
                              defaultOptions={modelTypeOptions}
                              loadOptions={searchManufacturerType}
                              onChange={(e: any) => setModelType(e)}
                              placeholder="Manufacturer Name first"
                              styles={colourStyles}
                              cacheOptions={true}
                              className="w-100"
                              noOptionsMessage={({ inputValue }) => {
                                if (!inputValue && modelName) {
                                  return (
                                    <span style={{ fontSize: "0.9rem" }}>
                                      No options found
                                    </span>
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      {/* Manufacture start data & Manufacture End data */}
                      <div className="row pt-3">
                        <div className="col-md-6 mt-2 mt-md-0 col-sm-12 p-0 pl-lg-0 ">
                          <div className="col-12 d-flex  align-items-center custom-border-orange-field py-2 px-0 bg-white">
                            <div className="custom-form-sub-searchPlane bg-white">
                              Year of Manufacture From
                            </div>
                            <Select
                              closeMenuOnSelect={true}
                              components={animatedComponents}
                              options={years}
                              styles={singleSelectStyles}
                              className="w-100"
                              placeholder="Start Date"
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  text: "white",
                                  primary25: "#E35D22",
                                  primary: "#E35D22",
                                },
                              })}
                              name="pilot_type"
                              // value={pilot_type}
                              // onChange={(e: any) => setManufactureForm(e)}
                              onChange={handleStartYearChange}
                            />

                            {/* <div className="amePopup-label">
                              <label
                                className="form-check-label pl-2 pl-md-0"
                                htmlFor="outputFormat"
                              ></label>
                            </div> */}
                          </div>
                        </div>
                        <div className="col-md-6 mt-4 mt-md-0 col-sm-12 p-0 pl-md-2 pl-lg-3">
                          <div className="col-12 d-flex  align-items-center custom-border-orange-field py-2 px-0 bg-white">
                            <div className="custom-form-sub-searchPlane bg-white">
                              Year of Manufacture To
                            </div>
                            <Select
                              closeMenuOnSelect={true}
                              components={animatedComponents}
                              options={endYears}
                              styles={singleSelectStyles}
                              placeholder="End Date"
                              className="w-100"
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  text: "white",
                                  primary25: "#E35D22",
                                  primary: "#E35D22",
                                },
                              })}
                              name="pilot_type"
                              onChange={(e: any) => setManufactureTo(e)}
                            />
                          </div>
                        </div>
                      </div>

                      {/* Aircraft Types & Engine Types */}
                      <div className="row pt-3">
                        <div className="col-md-6 mt-2 mt-md-0 col-sm-12 p-0 pl-lg-0">
                          <div className="col-12 d-flex  align-items-center custom-border-orange-field py-2 px-0 bg-white">
                            <div className="custom-form-sub-searchPlane bg-white">
                              Aircraft Types
                            </div>
                            <Select
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              isMulti
                              placeholder="Leave blank for all"
                              options={TypeAircraft}
                              styles={colourStyles}
                              className="w-100"
                              name="region_or_state"
                              onChange={(e: any) =>
                                setSelectedOptionsAircraft(e)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mt-4 mt-md-0 col-sm-12 p-0 pl-md-2 pl-lg-3">
                          <div className="col-12 d-flex  align-items-center custom-border-orange-field py-2 px-0 bg-white">
                            <div className="custom-form-sub-searchPlane bg-white">
                              Engine Types
                            </div>
                            <Select
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              isMulti
                              placeholder="Leave blank for all"
                              options={engineTypesName}
                              styles={colourStyles}
                              className="w-100"
                              name="region_or_state"
                              onChange={(e: any) => setSelectrdEngineTypes(e)}
                            />
                          </div>
                        </div>
                      </div>

                      {/* Output Format */}
                      <div className="row pt-4 pb-4">
                        <div className="col-12  d-flex justify-content-start ame-boxShadow px-0 py-4">
                          <div className="custom-form-sub-searchPlane">
                            Output Format
                          </div>
                          <div className="col-12  d-flex justify-content-around  flex-column flex-sm-row flex-md-row flex-lg-row ">
                            <div className="col-2 amePopup-label d-flex align-items-lg-center">
                              <div>
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="export_file"
                                  id="CSV"
                                  value="CSV"
                                />
                              </div>

                              <label
                                className="form-check-label pl-0 pl-lg-1 d-flex "
                                htmlFor="CSV"
                              >
                                CSV
                              </label>
                            </div>

                            <div className=" col-8 amePopup-label d-flex align-items-lg-center">
                              <div className="default-height">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="export_file"
                                  id="PDF"
                                  value="PDF"
                                />
                              </div>
                              <label
                                className="form-check-label pl-0 pl-lg-1 text-nowrap"
                                htmlFor="PDF"
                              >
                                Avery 5163 Label PDF
                              </label>
                            </div>

                            <div className="amePopup-label mobile-hide">
                              <label
                                className="form-check-label pl-2 pl-md-0"
                                htmlFor="outputFormat"
                              ></label>
                            </div>
                          </div>
                        </div>

                        <p className="text-danger field-errorMessage">
                          <ErrorMessage name="export_file" />
                        </p>
                      </div>
                      {/*  Exports Records */}
                      <div className="text-center ">
                        <div className=" col-12 btn-wrapper btn-primary-wrapper d-xl-inline mt-3 mt-xl-0 ml-xl-3">
                          <button
                            type="submit"
                            className="custom-freeTrial-btn"
                          >
                            Export Records
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};
