import React from 'react'
import { useAppSelector } from './hook';
import { Navigate, Outlet } from 'react-router-dom';

const ValidateLogin = () => {
    const status = useAppSelector((state: any) => state.auth.status);
    
  return (
    <>
    {status?<Outlet/>:<Navigate to="/login" />}
    {/* <Outlet/> */}
    </>
  )
}

export default ValidateLogin