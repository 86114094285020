import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { DateRangePicker } from "react-date-range-ts";
import "react-date-range-ts/dist/styles.css"; // main style file
import "react-date-range-ts/dist/theme/default.css";
import "./newAircraftRecords.css";
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../hook";
import { Field, Form, Formik } from "formik";
import { ameZipCode } from "../../../Features/ameZipCodeSlice";
import React from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { Loader } from "../RepeatedComponent/Loader";
import makeAnimated from "react-select/animated";
import { getPilotStates } from "../../../Features/downloadHistorySlice";
import apiClient from "../../../Features/FetchClient";
import {
  ExportQuickAircraftRecords,
  TaskStatus,
} from "../../../Features/pilotRecordSlice";
import { userList } from "../../../Features/fetchUserSlice";

const animatedComponents = makeAnimated();
export interface StateOption {
  readonly value: string;
  readonly label: string;
}
interface Option {
  label: string;
  value: string;
}
interface Payload {
  m_name?: string[];
  model_name?: string[];
  aircraft_days: string;
  daterange: string;
  e_name?: string[];
  region_or_state?: string[];
  aircraft_region_type: string;
  zip_code: string[];
  include_airman?: string;
  export_file: string;
}
export const NewAircraftRecords = () => {
  const [loading, setLoading] = useState(0);
  const status = useAppSelector((state: any) => state.auth.status);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [zip_code, setZipCode] = useState([""]);
  const [locations, setLocations] = useState("state");
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [engineName, setEngineName] = useState<Option[]>([]);
  const [modelName, setModelName] = useState<Option[]>([]);
  const [modelType, setModelType] = useState<Option[]>([]);
  const [modelTypeOptions, setModelTypeOptions] = useState<Option[]>([]);
  const [aircraftDays, setAirCraftDays] = useState<any>({
    value: "30 Days",
    label: "30 Days",
  });
  const [distance, setDistance] = useState<any>({});
  const [includeAirmen, setIncludeAirmen] = useState<any>(false);
  const [selectedCSV, setSelectedCSV] = useState("CSV");
  const states = useAppSelector((state: any) => state.downloadHistory.states);

  const export_work = useAppSelector(userList);
  useEffect(() => {
    if (!status) {
      navigate("/");
    }
  }, [status, navigate]);
  const geoLocation = [
    { value: "1", label: "Eastern" },
    { value: "2", label: "Southwestern" },
    { value: "3", label: "Central" },
    { value: "4", label: "Western-Pacific" },
    { value: "5", label: "Alaskan" },
    { value: "7", label: "Southern" },
    { value: "8", label: "European" },
    { value: "C", label: "Great Lakes" },
    { value: "E", label: "New England" },
    { value: "S", label: "Northwest Mountain" },
  ];

  const engineTypes = [
    { value: "7", label: "2 Cycle" },
    { value: "8", label: "4 Cycle" },
    { value: "10", label: "Electric" },
    { value: "0", label: "None" },
    { value: "6", label: "Ramjet" },
    { value: "1", label: "Reciprocating" },
    { value: "11", label: "Rotary" },
    { value: "5", label: "Turbo-fan" },
    { value: "4", label: "Turbo-jet" },
    { value: "2", label: "Turbo-prop" },
    { value: "3", label: "Turbo-shaft" },
    { value: "9", label: "Unknown" },
  ];

  const singleSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: "white",
      borderColor: "white",
      margin: 0,
      marginLeft: 0,
      border: "0px solid black",
      fontSize: 13,
      outline: "none !important",
      boxShadow: "none",
      "&:hover": {
        borderColor: "white",
      },
    }),
    input: (styles: any) => ({ ...styles, color: "black" }),
    placeholder: (styles: any) => ({
      ...styles,
      color: "black",
      fontSize: "14",
    }),
    menuList: (base: any) => ({
      ...base,
      padding: 0,
      color: "black",
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "white",
      },
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: "black",
      fontSize: "1rem",
    }),
  };

  const colourStyles = {
    control: (styles: any) => ({
      ...styles,
      background: "white",
      borderColor: "white",
      margin: 0,
      marginLeft: 0,
      borderWidth: "0px", // Changed 'border' to 'borderWidth'
      borderStyle: "solid", // Added 'borderStyle' to specify border style
      fontSize: 14,
      outline: "none !important",
      boxShadow: "none",
      "&:hover": {
        borderColor: "white",
      },
    }),
    input: (styles: any) => ({ ...styles, color: "black" }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      color: "gray",
      fontSize: 14, // Removed quotes around 14
    }),
    multiValue: (styles: any) => ({
      ...styles,
      backgroundColor: "#E35D22",
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 9999,
      background: "white",
      color: "black",
      "&:hover": {
        backgroundColor: "red",
      },
    }),
    menuList: (base: any) => ({
      ...base,
      height: 245,
      minHeight: 245,
      padding: 0,
      color: "black",
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "white",
      },
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      color: "white",
      textAlign: "left",
    }),
  };
  const formattedStates = states
    ? states
        .filter(
          (stateObj: { state: string; state_abbrev: string }) =>
            stateObj.state && stateObj.state_abbrev
        )
        .map((stateObj: { state: string; state_abbrev: string }) => ({
          value: stateObj.state,
          label: stateObj.state_abbrev,
        }))
    : [];

  let zipData: any = {
    zipCode: "",
    distance: "",
  };

  const initialValues = {
    m_name: [],
    model_name: [],
    aircraft_days: "90 Days",
    daterange: "05/02/2024 - 05/02/2024",
    e_name: [7, 8],
    region_or_state: [],
    aircraft_region_type: "state",
    zip_code: [],
    include_airman: true,
    export_file: "CSV",
  };

  const registrationDays = [
    { value: "1 Days", label: "1 Days" },
    { value: "3 Days", label: "3 Days" },
    { value: "7 Days", label: "7 Days" },
    { value: "30 Days", label: "30 Days" },
    { value: "Select Date Range", label: "Select Date Range" },
  ];

  const validationSchema = yup.object().shape({});

  // DATE Ranage
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const handleSubmitSetData = (values: any) => {
    // values.preventDefault();
    const formatDate = (dateString: any) => {
      const date = new Date(dateString);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      const year = date.getFullYear();

      // Format the date as MM/DD/YYYY
      return `${month}/${day}/${year}`;
    };

    const startFormatted = formatDate(selectionRange.startDate);
    const endFormatted = formatDate(selectionRange.endDate);
    const dateRange = `${startFormatted} - ${endFormatted}`;

    const todayDateRange = new Date();
    // Get the current date components
    const month = String(todayDateRange.getMonth() + 1).padStart(2, "0"); // Add 1 because getMonth() returns zero-based month index
    const day = String(todayDateRange.getDate()).padStart(2, "0");
    const year = todayDateRange.getFullYear();

    // Format the date as MM/DD/YYYY
    const formattedDate = `${month}/${day}/${year}`;

    if (export_work.can_download) {
      // setLoading(1);
      let obj: Payload = {
        aircraft_days: aircraftDays?.value,
        daterange:
          aircraftDays?.value === "Select Date Range"
            ? dateRange
            : `${formattedDate} - ${formattedDate}`,
        aircraft_region_type: locations,
        zip_code: [],
        export_file: values.export_file,
      };
      if (modelName.length > 0) {
        let m_name = modelName.map((o: any) => o.value);
        obj = { ...obj, m_name: m_name };
      }

      if (modelType.length > 0) {
        let model_name = modelType.map((o: any) => o.value);
        obj = { ...obj, model_name: model_name };
      }

      if (engineName.length > 0) {
        let e_name = engineName.map((o: any) => o.value);
        obj = { ...obj, e_name: e_name };
      }

      if (selectedOptions.length > 0) {
        let region_or_state = selectedOptions.map((o: any) => o.value);
        obj = { ...obj, region_or_state: region_or_state };
      }

      if (includeAirmen === true) {
        obj = { ...obj, include_airman: includeAirmen.toString() };
      }

      const payload: any = {
        zipCode: values.zip_code,
        distance: distance?.value,
      };
      setLoading(1);
      dispatch(ameZipCode(payload))
        .then((res: any) => {
          if (res.payload.length > 0) {
            // Handle the result of the async thunk action
            const zipCodes = res.payload.map((item: any) =>
              item.ZipCode.toString()
            );
            obj.zip_code = zipCodes;
          }
          dispatch(ExportQuickAircraftRecords(obj))
            .then((res: any) => {
              setLoading(0);
              const body = {
                task_log_id: res.payload.task_log_id,
                worker_job_id: res.payload.worker_job_id,
              };
              dispatch(TaskStatus(body))
                .then((res: any) => {
                  let data = res.payload;
                  if (
                    data.file_info &&
                    data.file_info.path &&
                    data.active_status == false
                  ) {
                    if (data.records_found > 0) {
                      toast.success(
                        "Export request has been submitted successfully. Your file will download shortly.",
                        {
                          position: "top-center",
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 8000,
                          pauseOnFocusLoss: true,
                          theme: "dark",
                        }
                      );
                    } else {
                      toast.error("No criteria match for this query.", {
                        position: "top-center",
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 8000,
                        pauseOnFocusLoss: true,
                        theme: "dark",
                      });
                    }
                  } else if (data.active_status == true) {
                    toast.success(
                      "Export request has been submitted successfully. Your file will download shortly.",
                      {
                        position: "top-center",
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 8000,
                        pauseOnFocusLoss: true,
                        theme: "dark",
                      }
                    );
                  }
                })
                .catch((err: any) => {
                  console.log(err);
                });
            })
            .catch((err: any) => {
              setLoading(0);
              console.log(err, "API error. ");
            });
        })
        .catch((error: any) => {
          setLoading(0);
          // Handle any errors that occur during the async operation
          console.error("Error: in zipdata api", error);
        });
    } else {
      toast.error(export_work.can_download_msg, {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 8000,
        pauseOnFocusLoss: true,
        theme: "dark",
      });
    }
  };

  const handleRadioLoacation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOptions([]);
    setLocations(event.target.value);
  };
  const handleRadioCSV = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCSV(event.target.value);
  };

  const marketingDistance = [
    {
      value: "selected",
      label: "Please select",
      isDisabled: true,
    },
  ];

  // Generating miles from 10 to 200
  for (let miles = 10; miles <= 200; miles += 10) {
    marketingDistance.push({
      value: miles.toString(),
      label: `${miles} Miles`,
      isDisabled: false,
    });
  }

  const onChangeZipCode = (e: any) => {
    zipData.zipCode = e.target.value;
    const { value } = e.target;
    if (value?.length > 9) {
      e.target.value = value.slice(0, 9); // Limit to 9 characters
    }
    if (zipData.zipCode && zipData.distance) {
      dispatch(ameZipCode(zipData)).then((res: any) => {
      });
    }
  };

  const onChangeMarketingDistance = (e: any) => {
    const distance = e.target.value;
    if (zipData.zipCode && distance) {
      zipData.distance = distance;
      dispatch(ameZipCode(zipData)).then((res: any) => {
        const zipCodes = res.payload.map((item: any) => item.ZipCode);
        setZipCode(zipCodes);
      });
    }
  };

  useEffect(() => {
    dispatch(getPilotStates());
  }, []);

  useEffect(() => {
    searchManufacturerType();
  }, [modelName]);

  const searchManufacturerName = async (name: string = "") => {
    if (name.length > 1) {
      const result: any = await apiClient.get(
        `${process.env.REACT_APP_API_URL}/manufacturer_names?q=${name}`
      );

      if (
        result.data &&
        Array.isArray(result.data.manufacturer_names) &&
        result.data.manufacturer_names.length > 0
      ) {
        const newArr = result.data.manufacturer_names.map((o: any) => {
          return { value: o, label: o };
        });
        return newArr;
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  const searchManufacturerType = async () => {
    if (modelName?.length > 0) {
      var modelNamesStr = "";
      for (let model of modelName) {
        modelNamesStr += `manufacturer_names[]=${model.value}&`;
      }
      //manufacturer_names[]=AMSDEN/HAIR
      const result: any = await apiClient.get(
        `${process.env.REACT_APP_API_URL}/get_model_names?${modelNamesStr}`
      );
      if (result.data && Array.isArray(result.data) && result.data.length > 0) {
        const newArr = result.data.map((o: any) => {
          return { value: o, label: o };
        });
        setModelTypeOptions(newArr);
        return newArr;
      } else {
        return [];
      }
    } else {
      setModelTypeOptions([]);
      return [];
    }
  };

  return (
    <>
      {loading ? <Loader /> : ""}
      <ToastContainer />
      <div
        id="hs_cos_wrapper_widget_1695156991887"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module "
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
        {/* Aviation List and Label Services */}
        <div className="sr-multicol-media bg-black text-white sr-padding-full text-center prototype-no-background widget_1695156991887 ">
          <div className="container-fluid content-wrapper newAircraft">
            <div className="row  sr-valign-center">
              {!export_work?.can_download && (
                <div className="col-md-12  mb-0 custom-border-orange px-0">
                  <a
                    href="/subscriptions-list"
                    className=" d-flex justify-content-center w-100 py-2 popupbgcolor"
                  >
                    <center>
                      <div className="Toastify__toast-body w-100">
                        <div className="Toastify__toast-icon Toastify--animate-icon Toastify__zoom-enter">
                          <svg
                            viewBox="0 0 24 24"
                            width="100%"
                            height="100%"
                            fill="#fff"
                          >
                            <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm.25 5a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm2.25 13.5h-4a1 1 0 010-2h.75a.25.25 0 00.25-.25v-4.5a.25.25 0 00-.25-.25h-.75a1 1 0 010-2h1a2 2 0 012 2v4.75a.25.25 0 00.25.25h.75a1 1 0 110 2z"></path>
                          </svg>
                        </div>
                        <div className="text-white">
                          {export_work?.can_download_msg}
                        </div>
                      </div>
                    </center>
                  </a>
                </div>
              )}
              <div className="col-md-12 p-2 pb-4 pt-4 sr-col text-left mt-0 sr-repeater-2 custom-border-orange">
                {loading ? <Loader /> : ""}
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmitSetData}
                >
                  <Form>
                    <div className="container-fluid">
                      {/* Select Aircraft Manufacture Names:  &  Aircraft Manufacturer Type:*/}

                      <div className="row p-3">
                        <div className="col-12 col-md-6 px-3 newAircraft-filed-m ">
                          <div className="col-12 col-md-12 d-flex  align-items-center custom-border-orange-field py-2">
                            <div className="custom-form-sub-heading">
                              Select Aircraft Manufacturer Names
                            </div>
                            <AsyncSelect
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              loadOptions={searchManufacturerName}
                              isMulti
                              styles={colourStyles}
                              onChange={(e: any) => setModelName(e)}
                              noOptionsMessage={({ inputValue }) => {
                                if (inputValue && inputValue.length < 2) {
                                  return (
                                    <span style={{ fontSize: "0.9rem" }}>
                                      Please enter 2 or more characters
                                    </span>
                                  );
                                } else {
                                  return (
                                    <span style={{ fontSize: "0.9rem" }}>
                                      No options found
                                    </span>
                                  );
                                }
                              }}
                              placeholder="Select Manufacture"
                              className="w-100"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 px-3 newAircraft-filed-m">
                          <div className="col-12 col-md-12 d-flex  align-items-center custom-border-orange-field py-2 newAircraft-filed-m">
                            <div className="custom-form-sub-heading">
                              Aircraft Manufacturer Type:
                            </div>
                            <AsyncSelect
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              isMulti
                              defaultOptions={modelTypeOptions}
                              loadOptions={searchManufacturerType}
                              onChange={(e: any) => setModelType(e)}
                              placeholder="Please select Manufacturer Name first"
                              styles={colourStyles}
                              cacheOptions={true}
                              noOptionsMessage={({ inputValue }) => {
                                if (!inputValue && modelName) {
                                  return (
                                    <span style={{ fontSize: "0.9rem" }}>
                                      No options found
                                    </span>
                                  );
                                }
                              }}
                              className="w-100"
                            />
                          </div>
                        </div>
                      </div>
                      {/* Registration & Engine Types:(Leave blank for all) */}
                      <div className="row pt-3 px-3">
                        <div className="col-md-6 col-sm-12 p-0 px-3">
                          <div className="col-12 d-flex  align-items-center custom-border-orange-field py-2">
                            <div className="custom-form-sub-heading">
                              Registration
                            </div>
                            <Select
                              closeMenuOnSelect={true}
                              components={animatedComponents}
                              options={registrationDays}
                              value={aircraftDays}
                              styles={singleSelectStyles}
                              className="w-100"
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  text: "white",
                                  primary25: "#E35D22",
                                  primary: "black",
                                },
                              })}
                              onChange={(e: any) => setAirCraftDays(e)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 px-3 newAircraft-filed-m">
                          {" "}
                          <div className="col-12 custom-border-orange-field py-2 newAircraft-filed-m">
                            <div className="custom-form-sub-heading">
                              Engine Types:(Leave blank for all) *
                            </div>
                            <Select
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              isMulti
                              options={engineTypes}
                              placeholder="Select Engine Types to include"
                              styles={colourStyles}
                              className="w-100 h-100"
                              onChange={(e: any) => setEngineName(e)}
                            />
                          </div>
                        </div>
                      </div>
                      {/* Select Date Range */}
                      {aircraftDays?.value === "Select Date Range" ? (
                        <>
                          <div className="row p-3 newAircraft-filed-m mt-md-3">
                            <div className="col-12 col-md-12 p-0 px-3 newAircraft-filed-m">
                              <div className="col-12 d-flex  align-items-center custom-border-orange-field py-2">
                                <div className="custom-form-sub-heading">
                                  Select Date Range
                                </div>
                                <DateRangePicker
                                  ranges={[selectionRange]}
                                  onChange={(ranges: any) => {
                                    setSelectionRange({
                                      startDate:
                                        ranges.selection.startDate ||
                                        new Date(),
                                      endDate:
                                        ranges.selection.endDate || new Date(),
                                      key: "selection",
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {/* Choose By:*/}
                      <div className="row p-3 newAircraft-filed-m mt-md-3">
                        <div className="col-12 col-md-6  px-3 ">
                          <div
                            className="col-12 d-flex justify-content-between custom-border-orange-field"
                            style={{
                              paddingBottom: "1.1rem",
                              paddingTop: "1.1rem",
                            }}
                          >
                            <div className="custom-form-sub-heading">
                              Choose By:
                            </div>
                            <div className=" col-12 flex-column flex-sm-row flex-md-row flex-lg-row  offmarket-output-format p-0 pl-md-3">
                              <div className="col-6 amePopup-label d-flex align-items-lg-center">
                                <div>
                                  <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="chooseBy"
                                    id="geoLocation"
                                    value="geo_location"
                                    checked={locations === "geo_location"}
                                    onClick={handleRadioLoacation}
                                  />
                                </div>

                                <label
                                  className="form-check-label pl-0 pl-lg-1 text-black text-nowrap"
                                  htmlFor="geoLocation"
                                >
                                  Geo Location
                                </label>
                              </div>

                              <div className="col-6 amePopup-label d-flex align-items-lg-center">
                                <div>
                                  <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="chooseBy"
                                    id="State"
                                    value="state"
                                    checked={locations === "state"}
                                    onClick={handleRadioLoacation}
                                  />
                                </div>

                                <label
                                  className="form-check-label pl-0 pl-lg-1 text-black"
                                  htmlFor="State"
                                >
                                  State
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-md-6 d-flex  align-items-center px-3  pl-3 newAircraft-filed-m">
                          <div className="col-12 custom-border-orange-field py-3 pl-4 ">
                            <div className="custom-form-sub-heading">
                              Include Airman
                            </div>
                            <div className="form-check amePopup-label col-12 pt-2 pt-md-0  d-flex align-items-lg-center">
                              <div>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="airman_class"
                                  id="includeAirman"
                                  value={includeAirmen}
                                  onChange={(e: any) =>
                                    setIncludeAirmen(e.target.checked)
                                  }
                                />
                              </div>

                              <label
                                className="form-check-label pl-0 pl-lg-1 text-black"
                                htmlFor="includeAirman"
                              >
                                Search and Include Airman and Pilot information
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Geo Location */}
                      {locations === "geo_location" ? (
                        <>
                          <div className="row p-3">
                            <div className="col-12 col-md-12 p-0 px-3 newAircraft-filed-m">
                              <div className="col-12 d-flex  align-items-center custom-border-orange-field py-2">
                                <div className="custom-form-sub-heading">
                                  Geo Location
                                </div>
                                <Select
                                  closeMenuOnSelect={false}
                                  components={animatedComponents}
                                  isMulti
                                  options={geoLocation}
                                  placeholder="Leave blank for all"
                                  styles={colourStyles}
                                  value={selectedOptions}
                                  className="w-100"
                                  onChange={(e: any) => setSelectedOptions(e)}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {/* States */}
                      {locations === "state" ? (
                        <>
                          <div className="row p-3 ">
                            <div className="col-12 col-md-12 p-0 px-3">
                              <div className="col-12 d-flex  align-items-center custom-border-orange-field py-2">
                                <div className="custom-form-sub-heading">
                                  State
                                </div>
                                <Select
                                  closeMenuOnSelect={false}
                                  components={animatedComponents}
                                  isMulti
                                  options={formattedStates}
                                  placeholder="Leave blank for all"
                                  styles={colourStyles}
                                  className="w-100"
                                  onChange={(e: any) => setSelectedOptions(e)}
                                />
                              </div>
                            </div>
                          </div>
                          {/* City: zip code  & miles Distance: */}
                          <div className="row p-3">
                            <div className="col-md-6 col-sm-12 p-0 px-3">
                              <div className="col-12 d-flex  align-items-center custom-border-orange-field py-2">
                                <div className="custom-form-sub-heading">
                                  Zip Code
                                </div>
                                <Field
                                  type="text"
                                  className="form-control bg-white text-black border-0 css-1fdsijx-ValueContainer"
                                  name="zip_code"
                                  maxLength={9}
                                  placeholder="e.g.12345"
                                  aria-label="Enter Your Email ID"
                                  aria-describedby="basic-addon1"
                                  autoComplete="off"
                                  onInput={(e: any) => {
                                    let check = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    if (check.charAt(0) === "0") {
                                      check = check.slice(1); // Remove the first character if it's '0'
                                    }

                                    e.target.value = check;

                                    // Remove non-numeric characters
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-md-6 col-sm-12 p-0 px-3 pt-3 pt-md-0">
                              <div className="col-12 d-flex  align-items-center custom-border-orange-field py-2">
                                <div className="custom-form-sub-heading">
                                  Marketing Distance From Zip Code
                                </div>
                                <Select
                                  closeMenuOnSelect={true}
                                  components={animatedComponents}
                                  options={marketingDistance}
                                  styles={singleSelectStyles}
                                  placeholder="Please select"
                                  className="w-100 css-1fdsijx-ValueContainer"
                                  onChange={(e: any) => setDistance(e)}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      text: "white",
                                      primary25: "#E35D22",
                                      primary: "black",
                                    },
                                  })}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {/* Output Format */}
                      <div className="row p-3">
                        <div className="col-12 col-md-12  px-3 ">
                          <div className="col-12 d-flex  custom-border-orange-field py-3 ">
                            <div className="custom-form-sub-heading">
                              Output Format
                            </div>
                            <div className="col-12 col-md-12 flex-column flex-sm-row flex-md-row flex-lg-row offmarket-output-format p-0 pl-md-3">
                              <div className="col-2 col-lg-2 amePopup-label d-flex align-items-lg-center">
                                <div>
                                  <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="export_file"
                                    id="CSV"
                                    value="CSV"
                                    checked={selectedCSV === "CSV"}
                                    onClick={handleRadioCSV}
                                  />
                                </div>

                                <label
                                  className="form-check-label pl-0 pl-lg-1 text-black"
                                  htmlFor="CSV"
                                >
                                  CSV
                                </label>
                              </div>
                              <div className="col-8 col-lg-8 amePopup-label d-flex align-iterm-lg-center">
                                <div>
                                  <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="export_file"
                                    id="PDF"
                                    value="PDF"
                                    checked={selectedCSV === "PDF"}
                                    onClick={handleRadioCSV}
                                  />
                                </div>

                                <label
                                  className="form-check-label pl-0 pl-lg-1 d-flex align-items-lg-center text-black text-nowrap "
                                  htmlFor="PDF"
                                >
                                  Avery 5163 Label PDF
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-center pt-3">
                      <div className=" col-12 btn-wrapper btn-primary-wrapper d-xl-inline mt-3 mt-xl-0 ml-xl-3">
                        <button
                          type="submit"
                          className="exports-Records-btn custom-freeTrial-btn"
                        >
                          Export Records
                        </button>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
