import { Field, Form, Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import * as yup from "yup";
import "./newpilotrecords.css";
import { useAppSelector } from "../../../hook";
import { useEffect, useState } from "react";
import { getPilotStates } from "../../../Features/downloadHistorySlice";
import { Loader } from "../RepeatedComponent/Loader";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useAppDispatch } from "../../../hook";
import { ameZipCode } from "../../../Features/ameZipCodeSlice";
import { Export_Page } from "../../../Features/pilotRecordSlice";
import { TaskStatus } from "../../../Features/pilotRecordSlice";
import { userList } from "../../../Features/fetchUserSlice";

interface Option {
  label: string;
  value: string;
}

interface MultiSelectProps {
  options: Option[];
}
interface Payload {
  pilot_type: string;
  days: string;
  region_or_state?: string[];
  region_type: string; //state,
  zip_code?: string[];
  // distance: distance?.value,
  include_aircraft?: string;
  export_file: string;
}

export const NewPilotRecords = () => {
  const animatedComponents = makeAnimated();
  const states = useAppSelector((state: any) => state.downloadHistory.states);
  const [locations, setLocations] = useState("state");
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [pilot_type, setPilotType] = useState({
    value: "S",
    label: "New Student Pilots",
  });
  const [days, setDays] = useState<any>({ value: "30 Days", label: "30 Days" });
  const [distance, setDistance] = useState<any>({});
  const [includeAircraft, setIncludeAircraft] = useState<any>(false);
  const [selectedCSV, setSelectedCSV] = useState("CSV");
  const [selectedPilotType, setSelectedPilotType] = useState<string>("S");
  const [loading, setLoading] = useState<any>(0);

  const export_work = useAppSelector(userList);

  const geoLocation = [
    { value: "AL", label: "Alaskan" },
    { value: "CE", label: "Central" },
    { value: "EA", label: "Eastern" },
    { value: "FS", label: "Foreign" },
    { value: "GL", label: "Great Lakes" },
    { value: "NM", label: "Northwest Mountain" },
    { value: "SO", label: "Southern" },
    { value: "EU", label: "European" },
    { value: "SW", label: "Southwestern" },
    { value: "WP", label: "Western-Pacific" },
  ];
  const pilotType = [
    { value: "S", label: "New Student Pilots" },
    { value: "P", label: "New Private Pilots" },
    { value: "New Instrument Pilots", label: "New Instrument Pilots" },
    { value: "C", label: "New Commercial Pilots" },
    { value: "A", label: "New ATP Pilots" },
    { value: "New Multi Engine Pilots", label: "New Multi Engine Pilots" },
    { value: "New Type Related Pilots", label: "New Type Related Pilots" },
    { value: "New Turbine Pilots", label: "New Turbine Pilots" },
  ];

  const registrationDays = [
    { value: "30 Days", label: "30 Days" },
    { value: "60 Days", label: "60 Days" },
    { value: "90 Days", label: "90 Days" },
  ];

  const formattedStates = states
    ? states
        .filter(
          (stateObj: { state: string; state_abbrev: string }) =>
            stateObj.state && stateObj.state_abbrev
        )
        .map((stateObj: { state: string; state_abbrev: string }) => ({
          value: stateObj.state,
          label: stateObj.state_abbrev,
        }))
    : [];

  const onChangeZipCode = (e: any) => {
    zipData.zipCode = e.target.value;
    const { value } = e.target;
    if (value?.length > 9) {
      e.target.value = value.slice(0, 9); // Limit to 9 characters
    }
    if (zipData.zipCode && zipData.distance) {
      dispatch(ameZipCode(zipData)).then((res: any) => {
      });
    }
  };

  const singleSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: "white",
      borderColor: "white",
      margin: 0,
      marginLeft: 0,
      border: "0px solid black",
      fontSize: 13,
      outline: "none !important",
      boxShadow: "none",
      "&:hover": {
        borderColor: "white",
      },
    }),
    input: (styles: any) => ({ ...styles, color: "black" }),
    placeholder: (styles: any) => ({
      ...styles,
      color: "black",
      fontSize: "14",
    }),
    menuList: (base: any) => ({
      ...base,
      padding: 0,
      color: "black",
      backgroundColor: "white",
      "&:hover": {
        color: "black",
        backgroundColor: "white",
      },
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: "black",
      fontSize: "1rem",
    }),
  };

  const colourStyles = {
    control: (styles: any) => ({
      ...styles,
      background: "white",
      borderColor: "white",
      margin: 0,
      marginLeft: 0,
      borderWidth: "0px", // Changed 'border' to 'borderWidth'
      borderStyle: "solid", // Added 'borderStyle' to specify border style
      fontSize: 14,
      outline: "none !important",
      boxShadow: "none",
      "&:hover": {
        borderColor: "white",
      },
    }),
    input: (styles: any) => ({ ...styles, color: "black" }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      color: "gray",
      fontSize: 14, // Removed quotes around 14
    }),
    multiValue: (styles: any) => ({
      ...styles,
      backgroundColor: "#E35D22",
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 9999,
      background: "white",
      color: "black",
      "&:hover": {
        backgroundColor: "red",
      },
    }),
    menuList: (base: any) => ({
      ...base,
      height: 250,
      minHeight: 250,
      padding: 0,
      color: "black",
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "white",
      },
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      color: "white",
      textAlign: "left",
    }),
  };

  const [zipData, setZipData] = useState<any>({
    zipCode: 0,
    distance: 0,
    success: "",
  });

  const dispatch = useAppDispatch();

  const defaultValue = {
    pilot_type: "S",
    days: "30 Days",
    region_or_state: [],
    region_type: "state", //state,
    zip_code: [""],
    distance: "",
    // include_aircraft: "false",
    export_file: "CSV",
  };

  const handleSubmit = (values: any) => {
    if (export_work.can_download) {
      const obj: Payload = {
        pilot_type: pilot_type.value,
        days: days.value,
        region_or_state: selectedOptions.map((o: any) => o.value.toString()),
        region_type: values?.region_type, //state,
        zip_code: [],
        // distance: distance?.value,
        include_aircraft: includeAircraft.toString(),
        export_file: selectedCSV,
      };
      const payload: any = {
        zipCode: values.zip_code,
        distance: distance?.value,
      };
      if (selectedOptions.length === 0) {
        delete obj.region_or_state;
      }
      if (obj.include_aircraft === "false") {
        delete obj.include_aircraft;
      }

      setLoading(1);
      dispatch(ameZipCode(payload))
        .then((res: any) => {
          if (res.payload.length > 0) {
            // Handle the result of the async thunk action
            const zipCodes = res.payload.map((item: any) =>
              item.ZipCode.toString()
            );
            obj.zip_code = zipCodes;
          }
          dispatch(Export_Page(obj))
            .then((res: any) => {
              const body: any = {
                task_log_id: res.payload.task_log_id,
                worker_job_id: res.payload.worker_job_id,
              };
              dispatch(TaskStatus(body))
                .then((res: any) => {
                  let data = res.payload;
                  if (
                    data.file_info &&
                    data.file_info.path &&
                    data.active_status == false
                  ) {
                    if (data.records_found > 0) {
                      toast.success(
                        "Export request has been submitted successfully. Your file will download shortly.",
                        {
                          position: "top-center",
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 8000,
                          pauseOnFocusLoss: true,
                          theme: "dark",
                        }
                      );
                    } else {
                      toast.error("No criteria match for this query.", {
                        position: "top-center",
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 8000,
                        pauseOnFocusLoss: true,
                        theme: "dark",
                      });
                    }
                  } else if (data.active_status == true) {
                    toast.success(
                      "Export request has been submitted successfully. Your file will download shortly.",
                      {
                        position: "top-center",
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 8000,
                        pauseOnFocusLoss: true,
                        theme: "dark",
                      }
                    );
                  }
                })
                .catch((err: any) => {
                  console.log(err);
                });
              // if(Array.isArray(res.count) && res.count.length > 0) {
              setLoading(0);
              setLocations("state");
              setPilotType({ value: "S", label: "New Student Pilots" });
              setDays({ value: "30 Days", label: "30 Days" });
              setIncludeAircraft(false);
              setSelectedCSV("CSV");
              // }
            })
            .catch((err: any) => {
              setLoading(0);
              console.log(err, "API error. ");
            });
        })
        .catch((error: any) => {
          setLoading(0);
          // Handle any errors that occur during the async operation
          console.error("Error: in zipdata api", error);
        });
    } else {
      toast.error(export_work.can_download_msg, {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 8000,
        pauseOnFocusLoss: true,
        theme: "dark",
      });
    }
  };

  const marketingDistance = [
    {
      value: "selected",
      label: "Please select",
      isDisabled: true,
    },
  ];

  // Generating miles from 10 to 200
  for (let miles = 10; miles <= 200; miles += 10) {
    marketingDistance.push({
      value: miles.toString(),
      label: `${miles} Miles`,
      isDisabled: false,
    });
  }

  const validationSchema = yup.object().shape({
    max_records: yup.string().trim().required("Please select maximum records."),
  });

  useEffect(() => {
    let a = {
      name: "",
      surname: "",
    };

    dispatch(getPilotStates());
  }, []);
  const handleRadioLoacation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocations(event.target.value);
  };
  const handleRadioCSV = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCSV(event.target.value);
  };

  return (
    <>
      {loading ? <Loader /> : ""}
      <ToastContainer />
      <div
        id="hs_cos_wrapper_widget_1695156991887"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module "
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
        {/* Aviation List and Label Services */}
        <div className="sr-multicol-media bg-black text-white sr-padding-full text-center prototype-no-background widget_1695156991887 ">
          <div className="container-fluid content-wrapper">
            <div className="row   sr-valign-center">
              <div></div>
              {!export_work?.can_download && (
                <div className="col-md-12  mb-0 custom-border-orange px-0">
                  <a
                    href="/subscriptions-list"
                    className=" d-flex justify-content-center w-100 py-2 popupbgcolor"
                  >
                    <center>
                      <div className="Toastify__toast-body w-100">
                        <div className="Toastify__toast-icon Toastify--animate-icon Toastify__zoom-enter">
                          <svg
                            viewBox="0 0 24 24"
                            width="100%"
                            height="100%"
                            fill="#fff"
                          >
                            <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm.25 5a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm2.25 13.5h-4a1 1 0 010-2h.75a.25.25 0 00.25-.25v-4.5a.25.25 0 00-.25-.25h-.75a1 1 0 010-2h1a2 2 0 012 2v4.75a.25.25 0 00.25.25h.75a1 1 0 110 2z"></path>
                          </svg>
                        </div>
                        <div className="text-white">
                          {export_work?.can_download_msg}
                        </div>
                      </div>
                    </center>
                  </a>
                </div>
              )}
              <div className="col-md-12 p-2 pb-4 pt-4 sr-col text-left mt-0 sr-repeater-2 custom-border-orange">
                <div className="content-wrapper newPilot">
                  <div className="container-fluid text-black bg-black p-0">
                    <div>
                      <Formik
                        initialValues={defaultValue}
                        onSubmit={handleSubmit}
                        // validationSchema={validationSchema}
                      >
                        <div className="col-12 px-0 px-md-4 py-3 text-left font-inter mb-3 bg-white text-black ">
                          <Form>
                            <div className="row px-3 ">
                              {/*  */}

                              <div className="col-md-6 col-sm-12 p-0 px-3">
                                <div className="col-12 d-flex  align-items-center custom-border-orange-field py-2">
                                  <div className="custom-form-sub-heading">
                                    Select Pilot Type
                                  </div>
                                  <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    options={pilotType}
                                    styles={singleSelectStyles}
                                    className="w-100"
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25: "#E35D22",
                                        primary: "black",
                                      },
                                    })}
                                    name="pilot_type"
                                    value={pilot_type}
                                    onChange={(e: any) => setPilotType(e)}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-12 pt-4 pt-md-0 px-3">
                                <div className="col-12 d-flex  align-items-center custom-border-orange-field py-2">
                                  <div className="custom-form-sub-heading">
                                    Registration
                                  </div>
                                  <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    options={registrationDays}
                                    styles={singleSelectStyles}
                                    value={days}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25: "#E35D22",
                                        primary: "black",
                                      },
                                    })}
                                    className="w-100"
                                    name="days"
                                    onChange={(e: any) => setDays(e)}
                                  />
                                </div>
                              </div>
                            </div>

                            {/* Choose By:*/}
                            <div className="row p-3 newAircraft-filed-m mt-md-3">
                              <div className="col-12 col-md-6  px-3 ">
                                <div
                                  className="col-12 d-flex justify-content-between custom-border-orange-field "
                                  style={{
                                    paddingBottom: "1.1rem",
                                    paddingTop: "1.1rem",
                                  }}
                                >
                                  <div className="custom-form-sub-heading">
                                    Choose By:
                                  </div>
                                  <div className="col-12 flex-column flex-sm-row flex-md-row flex-lg-row offmarket-output-format p-0 pl-md-3">
                                    <div className="col-6 amePopup-label d-flex align-items-lg-center">
                                      <div>
                                        <Field
                                          className="form-check-input"
                                          type="radio"
                                          name="region_type"
                                          id="geo_location"
                                          value="geo_location"
                                          checked={locations === "geo_location"}
                                          onClick={handleRadioLoacation}
                                        />
                                      </div>

                                      <label
                                        className="form-check-label pl-0 pl-lg-1 text-black text-nowrap"
                                        htmlFor="geo_location"
                                      >
                                        Geo Location
                                      </label>
                                    </div>

                                    <div className="col-6 amePopup-label d-flex align-items-lg-center">
                                      <div>
                                        <Field
                                          className="form-check-input"
                                          type="radio"
                                          name="region_type"
                                          id="state"
                                          value="state"
                                          checked={locations === "state"}
                                          onClick={handleRadioLoacation}
                                        />
                                      </div>

                                      <label
                                        className="form-check-label pl-0 pl-lg-1 text-black"
                                        htmlFor="state"
                                      >
                                        State
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-12 col-md-6 d-flex  align-items-center px-3  pl-3 newAircraft-filed-m pt-3 pt-md-0">
                                <div className="col-12 custom-border-orange-field py-3 pl-4 ">
                                  <div className="custom-form-sub-heading">
                                    Include Aircraft
                                  </div>
                                  <div className="form-check amePopup-label col-12 pt-2 pt-md-0  d-flex align-items-lg-center">
                                    <div>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        // name="include_aircraft"
                                        id="includeAirman"
                                        value={includeAircraft}
                                        // checked={checkboxes.includeAirman}
                                        onChange={(e: any) =>
                                          setIncludeAircraft(e.target.checked)
                                        }
                                      />
                                    </div>

                                    <label
                                      className="form-check-label pl-0 pl-lg-1 text-black"
                                      htmlFor="includeAirman"
                                    >
                                      Include Aircraft
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Geo Location */}
                            {locations === "geo_location" ? (
                              <>
                                <div className="row p-3">
                                  <div className="col-12 col-md-12 p-0 px-3 newAircraft-filed-m">
                                    <div className="col-12 d-flex  align-items-center custom-border-orange-field py-2">
                                      <div className="custom-form-sub-heading">
                                        Geo Location
                                      </div>
                                      <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        options={geoLocation}
                                        styles={colourStyles}
                                        className="w-100"
                                        name="region_or_state"
                                        onChange={(e: any) =>
                                          setSelectedOptions(e)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}

                            {/* States */}
                            {locations === "state" ? (
                              <>
                                <div className="row p-3 ">
                                  <div className="col-12 col-md-12 p-0 px-3">
                                    <div className="col-12 d-flex  align-items-center custom-border-orange-field py-2">
                                      <div className="custom-form-sub-heading">
                                        State
                                      </div>
                                      <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        options={formattedStates}
                                        placeholder="Leave blank for all"
                                        styles={colourStyles}
                                        className="w-100"
                                        name="region_or_state"
                                        onChange={(e: any) =>
                                          setSelectedOptions(e)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* City: zip code  & miles Distance: */}
                                <div className="row p-3">
                                  <div className="col-md-6 col-sm-12 p-0 px-3">
                                    <div className="col-12 d-flex  align-items-center custom-border-orange-field py-2">
                                      <div className="custom-form-sub-heading">
                                        Zip Code
                                      </div>
                                      <Field
                                        type="text"
                                        className="form-control bg-white text-black border-0 css-1fdsijx-ValueContainer"
                                        name="zip_code"
                                        placeholder="e.g.12345"
                                        aria-label="Enter Your Email ID"
                                        maxLength={9}
                                        aria-describedby="basic-addon1"
                                        autoComplete="on"
                                        onInput={(e: any) => {
                                          let check = e.target.value.replace(
                                            /[^0-9]/g,
                                            ""
                                          );
                                          if (check.charAt(0) === "0") {
                                            check = check.slice(1); // Remove the first character if it's '0'
                                          }

                                          e.target.value = check;

                                          // Remove non-numeric characters
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-6 col-sm-12 pt-4 pt-md-0 px-3">
                                    <div className="col-12 d-flex  align-items-center custom-border-orange-field py-2">
                                      <div className="custom-form-sub-heading">
                                        Marketing Distance From Zip Code
                                      </div>
                                      <Select
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        options={marketingDistance}
                                        styles={singleSelectStyles}
                                        className="w-100 css-1fdsijx-ValueContainer"
                                        placeholder="Please Select"
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 0,
                                          colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25: "#E35D22",
                                            primary: "black",
                                          },
                                        })}
                                        name="distance"
                                        onChange={(e: any) => setDistance(e)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}

                            {/* Output Format */}
                            <div className="row p-3">
                              <div className="col-12 col-md-12  px-3 ">
                                <div className="col-12 d-flex  custom-border-orange-field py-3">
                                  <div className="custom-form-sub-heading">
                                    Output Format
                                  </div>
                                  <div className="col-12 col-md-12  flex-column flex-sm-row flex-md-row flex-lg-row offmarket-output-format p-0 pl-md-3">
                                    <div className="col-2 col-lg-2 amePopup-label d-flex align-items-lg-center">
                                      <div>
                                        <Field
                                          className="form-check-input"
                                          type="radio"
                                          name="export_file"
                                          id="CSV"
                                          value="CSV"
                                          checked={selectedCSV === "CSV"}
                                          onClick={handleRadioCSV}
                                        />
                                      </div>

                                      <label
                                        className="form-check-label pl-0 pl-lg-1 text-black"
                                        htmlFor="CSV"
                                      >
                                        CSV
                                      </label>
                                    </div>

                                    <div className="col-8 col-lg-8 amePopup-label d-flex align-iterm-lg-center">
                                      <div>
                                        <Field
                                          className="form-check-input"
                                          type="radio"
                                          name="export_file"
                                          id="PDF"
                                          value="PDF"
                                          checked={selectedCSV === "PDF"}
                                          onClick={handleRadioCSV}
                                        />
                                      </div>

                                      <label
                                        className="form-check-label pl-0 pl-lg-1 d-flex align-items-lg-center text-black text-nowrap"
                                        htmlFor="PDF"
                                      >
                                        Avery 5163 Label PDF
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="text-center pt-2 ">
                              <div className=" col-12 btn-wrapper btn-primary-wrapper d-xl-inline mt-3 mt-xl-0 ml-xl-3">
                                <button
                                  type="submit"
                                  className="exports-Records-btn custom-freeTrial-btn"
                                >
                                  Export Records
                                </button>
                              </div>
                            </div>
                          </Form>
                        </div>
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
