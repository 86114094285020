import { useEffect } from "react";
import { NavigationType, useLocation, useNavigationType } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  const useBackButton = () => {
    const navType = useNavigationType();
    return navType === NavigationType.Pop;
  };
  
  const isPop = useBackButton();

  const scrollToTop = () =>  window.scrollTo({
    top: 0,
    behavior: "smooth", // Optional: Adds smooth scrolling animation
  });
 
  useEffect(() => {
    setTimeout(() => {
      {
        if(pathname !== '/')
      scrollToTop();}
    }, 50);
    
  }, [pathname, isPop]);

  useEffect(() => {
    window.addEventListener("beforeunload", scrollToTop);
    
    return () => {
      window.removeEventListener("beforeunload", scrollToTop);
    };
  }, []);
 
 
  return null;
}