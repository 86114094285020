import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import apiClient from "./FetchClient";

export interface ContactUsData {
  success: string;
  first_name: string;
  last_name: string;
  message: string;
  phone_number: string;
  email: string;
  tokencap: string;
}

const initialState: ContactUsData = {
  success: "",
  first_name: "",
  last_name: "",
  message: "",
  phone_number: "",
  email: "",
  tokencap: "",
};

// -------  Contact Us   -----
export const contactUs = createAsyncThunk(
  "contactUs/contact-Us",
  async (body: ContactUsData, thunkAPI) => {
    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/contact_us`, body
      );

      if (response.data) {
        return response.data
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);

// ------- contactUs Slice----
export const contactUsSlice = createSlice({
  name: "contactUs",
  initialState,
  reducers: {
    changeZindexToTrue: (state: any) => {
      // console.log("addAIData", state, action);
      state.postCardFormSubmition = true;
    },
    changeZindexToFalse: (state: any) => {
      // console.log("addAIData", state, action);
      state.postCardFormSubmition = false;
    },
  },
  extraReducers: (builder: any) => {

    // downloadFile
    builder
      .addCase(contactUs.pending, (state: any) => {
        state.status = false;
      })
      .addCase(contactUs.fulfilled, (state: any, action: any) => {
        state.status = true;
        state.error = "";
        state.response = action.meta.requestStatus;
      })
      .addCase(contactUs.rejected, (state: any, action: any) => {
        state.status = false;
        state.error = action.error.message;
      });
  },
});

export const userList = (state: RootState) => state.contactUs;
export const {
  changeZindexToFalse, changeZindexToTrue
} = contactUsSlice.actions;
export default contactUsSlice.reducer;