import { Link } from "react-router-dom";
import { Breadcrumbs } from "../RepeatedComponent/Breadcrumb";

const Terms = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling animation
    });
  };

  return (
    <>
      <div className="sr-multicol-media bg-black pt-1 pt-lg-4 text-center   prototype-no-background widget_1600979911688 ">
        <div className="container-fluid content-wrapper pt-2 pt-lg-4">
          <div className="row d-flex justify-content-center pt-1 pt-md-3 pt-lg-5">
            <div className="col-md-10">
              <Breadcrumbs textColor={"text-white"} />
            </div>
          </div>
        </div>
      </div>
      <div
        id="hs_cos_wrapper_widget_1604012269835"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
        {/* Privacy Policy content */}
        <div
          className="sr-multicol-media bg-black sr-padding-half text-center text-white
          prototype-no-background widget_1604012269835 "
          id="research-property"
        >
          <div className="container-fluid content-wrapper">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <h2 className=" section-heading font-inter">
                  Terms of Service
                </h2>
                <div className="row  sr-valign-center">
                 {/* info */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-3">
                    <div className="content-wrapper">
                      <div className="container bg-black text-white">
                        <div className="row  justify-content">
                          <div className="col-12 p-0">
                           <div className="d-flex">
                           <b className="tearm-para-header pr-1">Effective Date: </b> <p style={{fontSize:"1rem"}}>September 1, 2024 </p>
                           </div>

                            <p className="pt-2 ">
                                Welcome to PlaneLists.com. By accessing or using
                                our website and services, you agree to the
                                following terms and conditions.Please read them carefully before using
                                our services.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Acceptance of Terms  */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-3">
                    <div className="content-wrapper">
                      <div className="container custom-para-shadow bg-black border text-white">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center mt-3 pt-2 pb-2 pl-0">
                            <div className="border px-4 py-2 privacy-para-header">
                              Acceptance of Terms
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-4 py-4">
                            <p>
                              By accessing PlaneLists.com or using any of our
                              services, you accept and agree to be bound by
                              these Terms of Service (TOS). If you do not agree
                              to these terms, you must not use our website or
                              services.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Services Provided*/}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-5">
                    <div className="content-wrapper">
                      <div className="container custom-para-shadow bg-black border text-white">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center mt-3 pt-2 pb-2 pl-0">
                            <div className="border px-4 py-2 privacy-para-header">
                              Services Provided
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-4 py-4">
                            <p>
                              PlaneLists.com offers aviation mailing lists and
                              related services, including access to up-to-date
                              databases of U.S. aircraft owners, pilots, A&Ps,
                              drone operators, and other aviation professionals
                              and businesses. These databases can be searched,
                              sorted, and manipulated for marketing, research,
                              and other business purposes.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Eligibility */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-5">
                    <div className="content-wrapper">
                      <div className="container custom-para-shadow bg-black border text-white">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center mt-3 pt-2 pb-2 pl-0">
                            <div className="border px-4 py-2 privacy-para-header">
                              Eligibility
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-4 py-4">
                            <p>
                              To use PlaneLists.com services, you must be at
                              least 18 years old and capable of entering into a
                              legally binding agreement. By using the website,
                              you represent that you meet these eligibility
                              requirements.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Use of Data */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-5">
                    <div className="content-wrapper">
                      <div className="container custom-para-shadow bg-black border text-white">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center mt-3 pt-2 pb-2 pl-0">
                            <div className="border px-4 py-2 privacy-para-header">
                              Use of Data
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-4 py-4">
                            <p>
                              Users of our databases agree to comply with all
                              applicable laws, including data privacy and
                              marketing regulations, when accessing, using, or
                              distributing any information obtained through
                              PlaneLists.com. You are responsible for ensuring
                              that your use of the data is legal and does not
                              violate any third-party rights.
                            </p>
                            <p>
                              You agree that the information provided in our
                              databases is solely for lawful purposes related to
                              your business and marketing efforts. Misuse of our
                              data, including for spamming, unlawful marketing,
                              or unauthorized distribution, is strictly
                              prohibited.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*  Accuracy of Information */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-5">
                    <div className="content-wrapper">
                      <div className="container custom-para-shadow bg-black border text-white">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center mt-3 pt-2 pb-2 pl-0">
                            <div className="border px-4 py-2 privacy-para-header">
                              Accuracy of Information
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-4 py-4">
                            <p>
                              While PlaneLists.com strives to provide accurate
                              and up-to-date information, we do not guarantee
                              the accuracy, completeness, or reliability of the
                              data. The information provided in our databases is
                              regularly updated, but we cannot guarantee it will
                              always be current or free from errors.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Acceptance of Terms  */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-5">
                    <div className="content-wrapper">
                      <div className="container custom-para-shadow bg-black border text-white">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center mt-3 pt-2 pb-2 pl-0">
                            <div className="border px-4 py-2 privacy-para-header">
                              Acceptance of Terms
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-4 py-4">
                            <p>You agree not to:</p>
                            <div className="container unorder-list">
                              <ul>
                                <p>
                                  <li>
                                    Use our data for illegal purposes or in
                                    violation of any applicable laws.
                                  </li>
                                </p>
                                <p>
                                  <li>
                                    Resell, lease, or distribute our data
                                    without express permission.
                                  </li>
                                </p>
                                <p>
                                  <li>
                                    Use automated means (such as bots, scrapers,
                                    or spiders) to access our services.
                                  </li>
                                </p>
                                <p>
                                  <li>
                                    Interfere with the operation of our website
                                    or services in any way.
                                  </li>
                                </p>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*  Intellectual Property  */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-5">
                    <div className="content-wrapper">
                      <div className="container custom-para-shadow bg-black border text-white">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center mt-3 pt-2 pb-2 pl-0">
                            <div className="border px-4 py-2 privacy-para-header">
                              Intellectual Property
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-4 py-4">
                            <p>
                              All content and materials on PlaneLists.com,
                              including databases, software, text, graphics,
                              logos, and images, are protected by copyright,
                              trademark, and other intellectual property laws.
                              You may not reproduce, distribute, or create
                              derivative works from our content without prior
                              written consent.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Payment Terms   */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-5">
                    <div className="content-wrapper">
                      <div className="container custom-para-shadow bg-black border text-white">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center mt-3 pt-2 pb-2 pl-0">
                            <div className="border px-4 py-2 privacy-para-header">
                              Payment Terms
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-4 py-4">
                            <p>
                              All fees for our services are clearly stated at
                              the time of purchase. Payments must be made in
                              full before accessing or using our premium
                              services. All purchases are final, and we do not
                              offer refunds unless otherwise agreed upon in
                              writing.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Limitation of Liability  */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-5">
                    <div className="content-wrapper">
                      <div className="container custom-para-shadow bg-black border text-white">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center mt-3 pt-2 pb-2 pl-0">
                            <div className="border px-4 py-2 privacy-para-header">
                              Limitation of Liability
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-4 py-4">
                            <p>
                              PlaneLists.com is not liable for any damages,
                              including but not limited to, direct, indirect,
                              incidental, or consequential damages, arising from
                              your use or inability to use our services. This
                              includes any inaccuracies in the data,
                              interruptions in service, or unauthorized use of
                              your account or data.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Termination */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-5">
                    <div className="content-wrapper">
                      <div className="container custom-para-shadow bg-black border text-white">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center mt-3 pt-2 pb-2 pl-0">
                            <div className="border px-4 py-2 privacy-para-header">
                              Termination
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-4 py-4">
                            <p>
                              We reserve the right to terminate or suspend
                              access to our services, at our sole discretion,
                              without prior notice or liability, for any reason,
                              including but not limited to, a violation of these
                              Terms of Service.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Privacy Policy */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-5">
                    <div className="content-wrapper">
                      <div className="container custom-para-shadow bg-black border text-white">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center mt-3 pt-2 pb-2 pl-0">
                            <div className="border px-4 py-2 privacy-para-header">
                              Privacy Policy
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-4 py-4">
                            <p>
                              Your use of PlaneLists.com is also governed by our{" "}
                              <Link to="/privacy-policy" onClick={scrollToTop}>
                                {" "}
                                Privacy Policy
                              </Link>
                              . The Privacy Policy explains how we collect, use,
                              and protect your personal data.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Amendments */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-5">
                    <div className="content-wrapper">
                      <div className="container custom-para-shadow bg-black border text-white">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center mt-3 pt-2 pb-2 pl-0">
                            <div className="border px-4 py-2 privacy-para-header">
                              Amendments
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-4 py-4">
                            <p>
                              PlaneLists.com reserves the right to modify or
                              update these Terms of Service at any time. Any
                              changes will be effective immediately upon posting
                              to the website. Your continued use of the services
                              after any changes indicates your acceptance of the
                              revised terms
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*  Governing Law  */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-5">
                    <div className="content-wrapper">
                      <div className="container custom-para-shadow bg-black border text-white">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center mt-3 pt-2 pb-2 pl-0">
                            <div className="border px-4 py-2 privacy-para-header">
                              Governing Law
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-4 py-4">
                            <p>
                              These Terms of Service shall be governed and
                              construed in accordance with the laws of the South
                              Carolina, USA, without regard to its conflict of
                              law provisions
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Contact Us   */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-5">
                    <div className="content-wrapper">
                      <div className="container custom-para-shadow bg-black border text-white">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center mt-3 pt-2 pb-2 pl-0">
                            <div className="border px-4 py-2 privacy-para-header">
                              Contact Us
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-4 py-4">
                            <p>
                              If you have any questions or concerns regarding
                              these Terms of Service, please contact us at:
                            </p>
                            <br />
                            <p>
                              <b>Email: </b>
                              support@planelists.com
                            </p>
                            <p>
                              <b>Phone: </b>
                              704-608-5012
                            </p>
                            <p>
                              <b>Address: </b>
                              336 Airport Road Ridgeland, SC 29936
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
