import "./App.css";
import "./Assets/css/custom.css";
import "./Assets/css/footer.min.css";
import "./Assets/css/hero_video.css";
import "./Assets/css/sr-base.css";
import "./Assets/css/stat2.css";
import "./Assets/css/style.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./app/Components/HomePage/HomePage";
import HeaderPage from "./app/Components/Header/HeaderPage";
import Footer from "./app/Components/Footer/Footer";
import AviationServices from "./app/Components/Off-MarketAircraftLeads/AviationServices";
import { Pricing } from "./app/Components/Subscription/Pricing";
import { QuickSearch } from "./app/Components/QuickSearch/QuickSearch";
import { YourDownloadsHistory } from "./app/Components/History/YourDownloadsHistory";
import { YourPaymentsHistory } from "./app/Components/History/YourPaymentsHistory";
import { AmeMap } from "./app/Components/AME-PatientLeads/AmeMap";
import { Login } from "./app/Components/Login/Login";
import { Register } from "./app/Components/Register/Register";
import { About } from "./app/Components/FooterLinks/About";
import { Contact } from "./app/Components/FooterLinks/Contact";
import { PrivacyPolicy } from "./app/Components/FooterLinks/PrivacyPolicy";
import { Blog } from "./app/Components/FooterLinks/Blog";
import SearchPage from "./app/Components/QuickSearch/Search";
import { useAppDispatch } from "./hook";
import { loginTrue } from "./Features/authSlice";
import { AMEHealthPopup } from "./app/Components/RepeatedComponent/AMEHealthPopup";
import { CancelSubscription } from "./app/Components/Subscription/CancelSubscription";
import { Personal_InformationPopup } from "./app/Components/RepeatedComponent/Personal_InformationPopup";
import { ChangePassword } from "./app/Components/ChangePassword/ChangePassword";
import { EnquiryForm } from "./app/Components/EnquiryForm/EnquiryForm";
import NewAirMen from "./app/Components/Search/NewAirMen";
import NewAirCraft from "./app/Components/Search/NewAirCraft";
import Userdashboard from "./app/Components/dashboard/Userdashboard";
import CreatePostcard from "./app/Components/CreatePostcard/CreatePostcard";
import { NewAircraftRecords } from "./app/Components/QuickSearch/NewAircraftRecords";
import { NewPilotRecords } from "./app/Components/QuickSearch/NewPilotRecords";
import Terms from "./app/Components/FooterLinks/Terms";
import ValidateLogin from "./ValidateLogin";

import { RetrieveUserIdPopUp } from "./app/Components/RepeatedComponent/RetrieveUserIdPopUp";
import { ForgetUserIdOrPassword } from "./app/Components/ForgetUserIdOrPassword/ForgetUserIdOrPassword";
import ScrollToTop from "./app/Components/Helpers/scrollToTop";

function App() {
  const dispatch = useAppDispatch();
  const token = localStorage.getItem("_authToken");

  if (token) {
    dispatch(loginTrue());
  }
  return (
    <>
      <div
        id="hs_cos_wrapper_main_flexible_column"
        className="hs_cos_wrapper hs_cos_wrapper_widget_container hs_cos_wrapper_type_widget_container"
        style={{}}
        data-hs-cos-general-type="widget_container"
        data-hs-cos-type="widget_container"
      >
        <BrowserRouter>
          <HeaderPage />
          <ScrollToTop /> 
          <Routes>
            {/* without login */}
            <Route path="/" element={<HomePage />} />
            <Route path="/quick-search" element={<QuickSearch />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/ame-map" element={<AmeMap />} />
            <Route
              path="/aviation-research-services"
              element={<AviationServices />}
            />

            {/* Footer Links */}
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/blog" element={<Blog />} />

            <Route path="*" element={<HomePage />} />
            <Route path="/retrieve-userId" element={<RetrieveUserIdPopUp />} />
            <Route
              path="/forgot-id-password"
              element={<ForgetUserIdOrPassword />}
            />
            {/* with login */}
            <Route path="/" element={<ValidateLogin />}>
              <Route path="/subscriptions-list" element={<Pricing />} />
              <Route
                path="/cancel-subscriptions"
                element={<CancelSubscription />}
              />
              <Route path="/search" element={<SearchPage />} />
              <Route path="/airmenrecords" element={<NewAirMen />} />
              <Route path="/aircraftrecords" element={<NewAirCraft />} />
              <Route path="/create-postcard" element={<CreatePostcard />} />

              {/* Admin*/}
              <Route
                path="/new-aircraft-records"
                element={<NewAircraftRecords />}
              />
              <Route path="/new-pilot-records" element={<NewPilotRecords />} />

              <Route path="/enquiry-form" element={<EnquiryForm />} />

              {/* History */}
              <Route
                path="/your-payment-history"
                element={<YourPaymentsHistory />}
              />
              <Route
                path="/your-downloads-history"
                element={<YourDownloadsHistory />}
              />
              <Route path="/personalInfo" element={<Userdashboard />} />
              <Route
                path="/PersonalInfo-modal"
                element={<Personal_InformationPopup />}
              />
              <Route path="/AME-modal" element={<AMEHealthPopup />} />

              {/* Footer Links */}
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/user-dashboard" element={<Userdashboard />} />
            </Route>
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
