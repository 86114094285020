import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hook";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { sendPostCardData } from "../../../Features/PlansSlice";
import { Loader } from "../RepeatedComponent/Loader";
import { fetchUser } from "../../../Features/fetchUserSlice";
import { changeZindexToFalse } from "../../../Features/contactUsSlice";

export const PostCardpayment = (props: any) => {

  const closeRef: any = useRef(null);
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const elements: any = useElements();
  const [loading, setLoading] = useState<any>(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState<any>(false);
  const [selectedTab, setSelectedTab] = useState("credit");
  const orderDetails = props?.orderDetails;
  const userEmail = useAppSelector(
    (state: any) => state?.user?.userInfo?.user?.email
  );

  useEffect(() => {
    dispatch(fetchUser());
  }, []);

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        fontSize: "12px",
        color: "#424770",
        letterSpacing: "0.025em",
        fontFamily: "Source Code Pro, monospace",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };


  const handleOnSubmit = async (event: any) => {
    setLoading(1);
    if (!stripe || !elements) {
      return;
    }

    const cardElement: any = elements.getElement(CardElement);
    try {
      const { error, token } = await stripe.createToken(cardElement);
      if (error) {
        toast.error(error.message, {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 8000,
          pauseOnFocusLoss: true,
          theme: "dark",
        });
        setLoading(0);
        return;
      } else {
        if (!isTermsAccepted) {
          toast.error("Please accept terms and conditions", {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 8000,
            pauseOnFocusLoss: true,
            theme: "dark",
          });
          setLoading(0);
          return;
        }

        const { firstName, lastName, address, address2, city, state, zipCode } = props.orderDetails.sendersDetail;
        const sendersDetail = { firstName, lastName, address, address2, city, state, zipCode };        

        const obj: any =
          {
            stripe: {
              stripeEmail: userEmail,
              stripeToken: token.id,
              amount:props.orderDetails.totalAmount,
            },
            postcard: {
              size: "46S",
              front: `<!DOCTYPE html><html><body>${props.front}</body></html>`,
              back: `<!DOCTYPE html><html><body>${props.back}</body></html>`,
              mailClass: "FirstClass",
              mailDate: props.orderDetails.mailDate.mailDate,
              returnAddress:sendersDetail,
              recipients: props.orderDetails.recipientslist,
            },
          };
          console.log("obj",obj);

        dispatch(sendPostCardData(obj)).then((res: any) => {
          setLoading(0);
          if (res.payload.success) {
            toast.dismiss();
            toast.success(
              "Payment Successful: Your Postcard Order is Complete!",
              {
                position: "top-center",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 8000,
                pauseOnFocusLoss: true,
                theme: "dark",
                onClose: () => {
                  window.location.reload();
                },
              }
            );
            elements?.getElement(CardElement).clear();
            // resetForm();
            setTimeout(()=>{
              window.location.reload();
            },2000)
            closeRef.current.click();
          } else {
            toast.error("Something went wrong. Please try again", {
              position: "top-center",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 8000,
              pauseOnFocusLoss: true,
              theme: "dark",
            });
          }
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      {loading ? <Loader /> : ""}
      <form onSubmit={handleOnSubmit}>
        <div
          className="modal fade postcardmodal"
          role="dialog"
          aria-labelledby="mySmallModalLabel"
          aria-modal="true"
          data-backdrop="static"
          data-bs-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog modal-lg modal-custom-size">
            <div className="modal-content" style={{ borderRadius: "1rem" }}>
              <div className="modal-header modal-order-summary-header">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-8 text-left">
                      <h5
                        className="modal-title  modal-order-summary-header-text "
                        id="exampleModalLabel"
                      >
                        Order Summary
                      </h5>
                    </div>
                  </div>
                </div>

                <img
                  src="images/modal-img/cross-img.svg"
                  className="close custom-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  ref={closeRef}
                  onClick={()=>{ dispatch(changeZindexToFalse())}}
                ></img>
              </div>
              <div className="modal-body bg-light ">
                <div className="container-fluid subscription-pricing">
                  <div className="row text-black">
                    <div className="col-md-6 col-sm-12">
                      {/* DETAILS */}
                      <div className="col-12 custom-card-border px-4 py-3 text-left font-inter mb-3">
                        <div className="header">DETAILS</div>
                        <div className="sub-header">Pay Now</div>
                        <div className="d-flex">
                          <p className="para col-9 p-0 pt-2">
                            Total Recipients
                          </p>
                          <p className="custom-text-color ml-auto">
                            {orderDetails?.totalRecipients}
                          </p>
                        </div>
                        <div className="d-flex">
                          <p className="para col-9 p-0 ">
                            Price Per Recipient
                          </p>
                          <p className="custom-text-color ml-auto">
                            {orderDetails?.chargesPerRecipients}
                          </p>
                        </div>

                        <p className="sub-para col-9 p-0 pt-2 ">
                          {/* Lorem ipsum dolor sit amet consectetur, adipisicing elit. Accusamus voluptates, aliquam perferendis mollitia */}
                        </p>
                        <hr></hr>
                        <p className="custom-text-color ml-auto">
                          Total: ${orderDetails?.totalAmount}
                        </p>
                      </div>
                      <div className="col-12 custom-card-border px-4 py-3 text-left font-inter mb-3">
                        <div className="d-flex">
                          <div className="mr-3">
                            <img
                              src="images/modal-img/thumbs-up-black-icon 1.svg"
                              className="sub-header-thumbs-up"
                            />
                          </div>
                          <div className="sub-header font-weight-bold ">
                            We Are Here to Help You
                            <p className="para pt-2">
                              If you have questions with our product, please
                              contact our Customer Care team at
                              <Link
                                to=""
                                className="pl-1"
                                style={{
                                  textDecoration: "none",
                                  borderBottom: "none",
                                }}
                              >
                            support@planelists.com
                              </Link>
                              . We are available 7 days a week to assist you.
                            </p>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="mr-3">
                            <img
                              src="images/modal-img/lock-icon (1) 1.svg"
                              className="sub-header-lock"
                            />
                          </div> 
                          <div className="sub-header font-weight-bold ">
                            Your Search History and Reports are Privately Stored
                            <p className="para pt-2">
                              We saved your search history and created reports
                              for your future use. You can access your stored
                              reports at any time.
                            </p>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                    {/* SECURE CHECKOUT */}
                    <div className="col-md-6 col-sm-12 ">
                      <div className="col-12 custom-card-border px-4 py-3 text-left font-inter ">
                        <div className="header">SECURE CHECKOUT</div>
                        <div className="checkout-sub-header pt-3 pb-2">
                          CHOOSE YOUR PAYMENT METHOD
                        </div>
                        {/* card payment */}
                        <div className="payment-method d-flex align-items-center">
                          <div className=" col-md-10 d-flex ml-1">
                            <div>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="paymentGetway"
                                id="credit"
                                value="credit"
                                checked={selectedTab === "credit"}
                              />
                            </div>

                            <label
                              className="  pt-1 credit-text  text-nowrap"
                              htmlFor="credit"
                            >
                              Credit/Debit Card
                            </label>
                          </div>
                        </div>

                        {/* Credit card */}
                        {selectedTab === "credit" ? (
                          <div className="mt-3">
                            <div className="credit-form">
                              EMAIL (THIS WILL BE YOUR LOGIN)
                            </div>
                            <div>
                              <div className="form-row">
                                <div className="form-group col-md-12 m-0 mt-2">
                                  <input
                                    className="form-control input-form bg-secondary"
                                    type="text"
                                    name="email"
                                    id="email"
                                    disabled
                                    value={userEmail}
                                    placeholder="Email"
                                  />
                                  <p className="text-danger field-errorMessage-checkout"></p>
                                </div>
                              </div>
                              <CardElement
                                className="my-3"
                                options={CARD_ELEMENT_OPTIONS}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {/* <StripePayment/> */}
                        <div className="text-center mb-3">
                          <div className="d-flex">
                            <div
                              className=""
                              style={{
                                width: "11px",
                                marginTop: "-2",
                              }}
                            >
                              <input
                                type="checkbox"
                                id="checkAgree"
                                name="checkAgree"
                                className="mob-checkboxPayment"
                                value={isTermsAccepted}
                                onChange={(event: any) =>
                                  setIsTermsAccepted(event.target.checked)
                                }
                              />
                            </div>
                            <div className="col-11 pl-2">
                              <label
                                className="i-understand"
                                htmlFor="checkAgree"
                              >
                                I understand that PlaneLists.com is not a
                                consumer reporting agency. I agree to the
                                PlaneLists.com Terms and Conditions and Privacy
                                Policy. 
                              </label>
                            </div>
                          </div>

                          <div className="col-12 btn-wrapper btn-primary-wrapper d-xl-inline mt-3 mt-xl-0 ml-xl-3">
                            <button
                              type="button"
                              onClick={handleOnSubmit}
                              disabled={!stripe}
                              className="custom-freeTrial-btn"
                            >
                              CONTINUE TO PAY
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
