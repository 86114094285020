import { useEffect, useState, useRef } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ToastContainer, toast } from "react-toastify";
import { Field, Formik, Form, FormikHelpers } from "formik";
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../hook";
import { ameZipCode } from "../../../Features/ameZipCodeSlice";
import { getPilotStates } from "../../../Features/downloadHistorySlice";
import { exportsRecords } from "../../../Features/exportsRecordsSlice";
import { Loader } from "../RepeatedComponent/Loader";
import { TaskStatus } from "../../../Features/pilotRecordSlice";
import { userList } from "../../../Features/fetchUserSlice";
import "../RepeatedComponent/ameHealthPopup.css";
interface CheckboxState {
  firstClass: boolean;
  secondClass: boolean;
  thirdClass: boolean;
  airTrafficController: boolean;
  aircraftMechanics: boolean;
  parachuteRiggers: boolean;
  dropZoneManagement: boolean;
  jumpPilots: boolean;
  [key: string]: boolean;
}
interface Pilot {
  firstClass: boolean;
  secondClass: boolean;
  thirdClass: boolean;
  allClasses: boolean;
}
interface Option {
  label: string;
  value: string;
}
interface zip {
  zipCode: number;
  distance: number;
  success: string;
}
interface Payload {
  last_name: string;
  last_name_check: string;
  first_name: string;
  first_name_check: string;
  address: string;
  address_check: string;
  city_name: string;
  city_name_check: string;
  region_type: string;
  zip_code: string[];
  airman: string;
  airman_class?: string[];
  expire: string;
  sort_type: string;
  sort_type_order: string;
  export_file: string;
  task_type: string;
}

export const AMEpatientLeadForm = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(0);
  const [distance, setDistance] = useState<any>("selected");
  const [location, setLocation] = useState("state");
  const [selectedTab, setSelectedTab] = useState("pilot");
  const [options, setOptions] = useState<Option[]>([]);
  const export_work = useAppSelector(userList);
  var intervalId: any = "";

  const animatedComponents = makeAnimated();

  const singleSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: "white",
      borderColor: "white",
      margin: 0,
      marginLeft: 0,
      border: "0px solid black",
      fontSize: 13,
      outline: "none !important",
      boxShadow: "none",
      "&:hover": {
        borderColor: "white",
      },
    }),
    input: (styles: any) => ({ ...styles, color: "black" }),
    placeholder: (styles: any) => ({
      ...styles,
      color: "black",
      fontSize: "14",
    }),
    menuList: (base: any) => ({
      ...base,
      padding: 0,
      color: "black",
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "white",
      },
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: "black",
      fontSize: "1rem",
    }),
  };

  const [zipData, setZipData] = useState<zip>({
    zipCode: 0,
    distance: 0,
    success: "",
  });

  const [zipResponse, setZipResponse] = useState<string[]>([]);

  const marketingDistance = [
    {
      value: "selected",
      label: "Please Select",
      isDisabled: true,
    },
  ];

  // Generating miles from 10 to 200
  for (let miles = 10; miles <= 200; miles += 10) {
    marketingDistance.push({
      value: miles.toString(),
      label: `${miles} Miles`,
      isDisabled: false,
    });
  }

  useEffect(() => {
    let a = {
      name: "",
      surname: "",
    };

    dispatch(getPilotStates()).then((res: any) => {
      if (
        res.payload &&
        Array.isArray(res.payload.states) &&
        res.payload.states.length > 0
      ) {
        let filteredStates = res.payload.states.filter(
          (item: any) => item.state !== ""
        );
        let hello: any = filteredStates.map((item: any) => ({
          value: item.state,
          label: item.state_abbrev,
        }));

        setOptions(hello);
      }
    });
  }, []);

  const onChangeMarketingDistance = () => {
    // Assuming zipData is of type ZipData, specify its type or provide a type assertion
    if (zipData && zipData.zipCode > 9999 && zipData.distance) {

      const payload: any = {
        zipCode: zipData.zipCode,
        distance: distance?.value,
        success: "", // Ensure that the payload matches the expected shape of ZipCodeState
      };
      dispatch(ameZipCode(payload))
        .then((res: any) => {
          if (res.payload.length > 0) {
            // Handle the result of the async thunk action
            toast.dismiss();
            const zipCodes = res.payload.map((item: any) =>
              item.ZipCode.toString()
            );
            const convertedString = zipCodes;
            setZipResponse(convertedString);
          } else {
            setZipResponse([]);
          }
        })
        .catch((error: any) => {
          // Handle any errors that occur during the async operation
          console.error("Error: in zipdata api", error);
        });
    }
  };

  useEffect(() => {
    return () => clearInterval(intervalId);
  }, []);

  const [checkboxes, setCheckboxes] = useState<Pilot>({
    firstClass: false,
    secondClass: false,
    thirdClass: false,
    allClasses: false,
  });

  const [allRadioCheck, setAllRadioCheck] = useState<CheckboxState>({
    firstClass: false,
    secondClass: false,
    thirdClass: false,
    airTrafficController: false,
    aircraftMechanics: false,
    parachuteRiggers: false,
    dropZoneManagement: false,
    jumpPilots: false,
    // Add more checkboxes as needed
  });

  const defaultValue: CheckboxState = {
    firstClass: false,
    secondClass: false,
    thirdClass: false,
    airTrafficController: false,
    aircraftMechanics: false,
    parachuteRiggers: false,
    dropZoneManagement: false,
    jumpPilots: false,
  };

  const initialValues: Pilot = {
    ...defaultValue,
    // location:"State",
    firstClass: false,
    secondClass: false,
    thirdClass: false,
    allClasses: false,
  };
  const initialValues1 = {
    ...initialValues,
    zipCode: "",
    distance: "",
    airmenType: "pilot", // Assuming default value is 'pilot'
    firstClass: false,
    secondClass: false,
    thirdClass: false,
    allClasses: false,
    medicalExpiring: "30 Days",
    outputFormat: "CSV",
    location1: "Contains1",
  };

  // Validation schema using yup
  const validationSchema = yup.object().shape({
    firstName: yup.string(),
    lastName: yup.string(),
    firstClass: yup.string().required("Please select an option"),
    secondClass: yup.string().required("Please select an option"),
    thirdClass: yup.string().required("Please select an option"),
    allClasses: yup.string(),
    airTrafficController: yup.string(),
    aircraftMechanics: yup.string(),
    parachuteRiggers: yup.string(),
    dropZoneManagement: yup.string(),
    jumpPilots: yup.string(),
    medicalExpiring: yup.string(),
  });

  function getClassList(classes: any) {
    const classList = [];
    if (classes.firstClass) classList.push("1st");
    if (classes.secondClass) classList.push("2nd");
    if (classes.thirdClass) classList.push("3rd");
    return classList;
  }
  const formikRef = useRef(null);
  //  Handle form submission
  const handleSubmit = (values: any, { resetForm }: FormikHelpers<any>) => {
    const zipCodePayload: any = {
      zipCode: values.zipCode,
      distance: distance?.value,
      success: "", // Ensure that the payload matches the expected shape of ZipCodeState
    };
    if (export_work.can_download) {
      let airmenclass = getClassList(checkboxes);
      let updatedValues: Payload = {
        last_name: values.lastName || "",
        last_name_check:
          values.locations1 === "exactmatch1" ? "exact_string" : "sub_string",
        first_name: values.firstName || "",
        first_name_check:
          values.locations2 === "exactmatch2" ? "exact_string" : "sub_string",
        address: values.address || "",
        address_check:
          values.locations3 === "exactmatch3" ? "exact_string" : "sub_string",
        city_name: values.city || "",
        city_name_check:
          values.locations4 === "exactmatch4" ? "exact_string" : "sub_string",
        region_type: location || "",
        zip_code: zipResponse || [],
        airman: selectedTab || "",
        expire: values.medicalExpiring,
        sort_type: "last_name_suffix",
        sort_type_order: values.order || "asc",
        export_file: values.outputFormat,
        task_type: "pilot",
      };
      if (airmenclass.length > 0) {
        updatedValues = { ...updatedValues, airman_class: airmenclass };
      }
      values = updatedValues;
      setLoading(1);
      dispatch(ameZipCode(zipCodePayload)).then((res: any) => {
        if (res.payload.length > 0) {
          // Handle the result of the async thunk action
          const zipCodes = res.payload.map((item: any) =>
            item.ZipCode.toString()
          );
          values.zip_code = zipCodes;
        }
        dispatch(exportsRecords({ ...values }))
          .then((res: any) => {
            // console.log(
            //   {
            //     taskID: res.payload.task_log_id,
            //     WorkerId: res.payload.worker_job_id,
            //   },
            //   " api response in AME Patients Lead"
            // );
            setLoading(0);
            setCheckboxes({
              firstClass: false,
              secondClass: false,
              thirdClass: false,
              allClasses: false,
            });
            setDistance("selected");
            setSelectedTab("pilot");
            const body: any = {
              task_log_id: res.payload.task_log_id,
              worker_job_id: res.payload.worker_job_id,
            };
            dispatch(TaskStatus(body))
              .then((res: any) => {
                let data = res.payload;
                if (
                  data.file_info &&
                  data.file_info.path &&
                  data.active_status == false
                ) {
                  if (data.records_found > 0) {
                    toast.success(
                      "Export request has been submitted successfully. Your file will download shortly.",
                      {
                        position: "top-center",
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 8000,
                        pauseOnFocusLoss: true,
                        theme: "dark",
                      }
                    );
                  } else {
                    toast.error("No criteria match for this query.", {
                      position: "top-center",
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      draggablePercent: 8000,
                      pauseOnFocusLoss: true,
                      theme: "dark",
                    });
                  }
                } else if (data.active_status == true) {
                  toast.success(
                    "Export request has been submitted successfully. Your file will download shortly.",
                    {
                      position: "top-center",
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      draggablePercent: 8000,
                      pauseOnFocusLoss: true,
                      theme: "dark",
                    }
                  );
                }
                resetForm();
              })
              .catch((err: any) => {
                console.log(err);
                setLoading(0);
                toast.error(
                  "You don't have any active subscription to export records.",
                  {
                    position: "top-center",
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 8000,
                    pauseOnFocusLoss: true,
                    theme: "dark",
                  }
                );
              });
          })
          .catch((err) => {
            setLoading(0);
            toast.error(
              "You don't have any active subscription to export records.",
              {
                position: "top-center",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 8000,
                pauseOnFocusLoss: true,
                theme: "dark",
              }
            );
          });
      });
    } else {
      toast.error(export_work.can_download_msg, {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 8000,
        pauseOnFocusLoss: true,
        theme: "dark",
      });
    }
  };

  const checkedAllClass = (event: any) => {
    const { name, checked } = event.target;

    // Update individual checkbox state
    setCheckboxes((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
    // If "All Class" checkbox is checked, update all other checkboxes accordingly
    if (name === "allClasses") {
      setCheckboxes({
        firstClass: checked,
        secondClass: checked,
        thirdClass: checked,
        allClasses: checked,
      });
    } else {
      // If any other checkbox is unchecked, uncheck "All Class" checkbox
      if (!checked) {
        setCheckboxes((prevState) => ({
          ...prevState,
          allClasses: false,
        }));
      }
    }
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTab(event.target.value);
    const { value } = event.target;

    if (value === "all") {
      const updatedCheckboxes: CheckboxState = { ...allRadioCheck };
      for (const key in updatedCheckboxes) {
        if (Object.prototype.hasOwnProperty.call(updatedCheckboxes, key)) {
          updatedCheckboxes[key] = true;
        }
      }
      setAllRadioCheck(updatedCheckboxes);
    } else {
      const updatedCheckboxes: CheckboxState = { ...allRadioCheck };
      for (const key in updatedCheckboxes) {
        if (Object.prototype.hasOwnProperty.call(updatedCheckboxes, key)) {
          updatedCheckboxes[key] = false;
        }
      }
      setAllRadioCheck(updatedCheckboxes);
    }
  };

  return (
    <>
      {loading ? <Loader /> : ""}
      <ToastContainer />
      <Formik
        innerRef={formikRef}
        initialValues={initialValues1}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <div className="container-fluid bg-white searchPlane">
          <div className="row text-black">
            {/* content*/}
            {!export_work?.can_download && (
              <a
                href="/subscriptions-list"
                className=" d-flex justify-content-center w-100 py-2 popupbgcolor"
              >
                <center>
                  <div className="Toastify__toast-body w-100">
                    <div className="Toastify__toast-icon Toastify--animate-icon Toastify__zoom-enter">
                      <svg
                        viewBox="0 0 24 24"
                        width="100%"
                        height="100%"
                        fill="#fff"
                      >
                        <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm.25 5a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm2.25 13.5h-4a1 1 0 010-2h.75a.25.25 0 00.25-.25v-4.5a.25.25 0 00-.25-.25h-.75a1 1 0 010-2h1a2 2 0 012 2v4.75a.25.25 0 00.25.25h.75a1 1 0 110 2z"></path>
                      </svg>
                    </div>
                    <div className="text-white notification">
                      {export_work?.can_download_msg}
                    </div>
                  </div>
                </center>
              </a>
            )}
            <div className="col-md-12 col-sm-12 ">
              <div className="col-12  px-4 py-2 text-left font-inter mb-3">
                <Form>
                  {/* Zip Code & Distance*/}
                  <div className="row pt-3">
                    <div className="col-md-6 col-sm-12 p-0 px-0">
                      <div className="col-12 d-flex  align-items-center custom-border-orange-field">
                        <div className="custom-form-sub-searchPlane">
                          Zip Code
                        </div>
                        <Field
                          type="text"
                          className="form-control bg-white text-black border-0 "
                          name="zipCode"
                          maxLength={9}
                          placeholder="e.g.12345"
                          style={{ font: "inherit", paddingLeft: "0px" }}
                          aria-label="Enter Your Email ID"
                          aria-describedby="basic-addon1"
                          autoComplete="off"
                          onInput={(e: any) => {
                            let check = e.target.value.replace(/[^0-9]/g, "");
                            if (check.charAt(0) === "0") {
                              check = check.slice(1); // Remove the first character if it's '0'
                            }

                            e.target.value = check;

                            // Remove non-numeric characters
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12 p-0 px-0 px-sm-1 pt-3 pt-md-0 pl-lg-3">
                      <div className="col-12 d-flex  align-items-center custom-border-orange-field py-2">
                        <div className="custom-form-sub-searchPlane">
                          Distance
                        </div>
                        <Select
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          options={marketingDistance}
                          styles={singleSelectStyles}
                          placeholder="Please select"
                          value={distance}
                          className="w-100"
                          onChange={(e: any) => setDistance(e)}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              text: "white",
                              primary25: "#E35D22",
                              primary: "black",
                            },
                          })}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Airmen Type */}
                  <div className="row pt-4">
                    <div className="col-12 d-flex justify-content-around ame-boxShadow px-0 py-4">
                      <div className="custom-form-sub-searchPlane">
                        Airmen Type
                      </div>
                      <div className="form-check amePopup-label  d-flex align-items-lg-center">
                        <div>
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="airmenType"
                            id="pilot"
                            value="pilot"
                            checked={selectedTab === "pilot"}
                            onChange={handleRadioChange}
                          />
                        </div>

                        <label
                          className="form-check-label pl-0 pl-lg-1"
                          htmlFor="pilot"
                        >
                          Pilot
                        </label>
                      </div>
                      <div className="form-check amePopup-label d-flex align-items-lg-center">
                        <div>
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="airmenType"
                            id="non_pilot"
                            value="non_pilot"
                            checked={selectedTab === "non_pilot"}
                            onChange={handleRadioChange}
                          />
                        </div>

                        <label
                          className="form-check-label  pl-0 pl-lg-1"
                          htmlFor="non_pilot"
                        >
                          Non-Pilot
                        </label>
                      </div>
                    </div>
                  </div>

                  <div
                    className="row mx-0 mx-sm-3 popUp-tab-transition"
                    id="airmenMedical"
                  >
                    <div className="col-12  p-2 ame-sub-boxShadow">
                      <div className="col-12 pt-2 sub-heading">
                        Airmen Medical Class
                      </div>
                      <div className="col-12 d-flex ">
                        <div className="form-check amePopup-label col-6  d-flex align-items-lg-center">
                          <div>
                            <Field
                              className="form-check-input"
                              type="checkbox"
                              name="firstClass"
                              id="firstClassCheckbox"
                              checked={checkboxes.firstClass}
                              onChange={checkedAllClass}
                            />
                          </div>
                          <label
                            className="form-check-label pl-0 pl-lg-2 "
                            htmlFor="firstClassCheckbox"
                          >
                            1st Class
                          </label>
                        </div>
                        <div className="form-check amePopup-label col-6   d-flex align-items-lg-center">
                          <div>
                            <Field
                              className="form-check-input"
                              type="checkbox"
                              name="secondClass"
                              id="secondClassCheckbox"
                              checked={checkboxes.secondClass}
                              onChange={checkedAllClass}
                            />
                          </div>
                          <label
                            className="form-check-label pl-0 pl-lg-2"
                            htmlFor="secondClassCheckbox"
                          >
                            2nd Class
                          </label>
                        </div>
                      </div>
                      <div className="col-12  d-flex ">
                        <div className="form-check amePopup-label  col-6   d-flex align-items-lg-center">
                          <div>
                            <Field
                              className="form-check-input"
                              type="checkbox"
                              name="thirdClass"
                              id="thirdClassCheckbox"
                              checked={checkboxes.thirdClass}
                              onChange={checkedAllClass}
                            />
                          </div>

                          <label
                            className="form-check-label pl-0 pl-lg-2 "
                            htmlFor="thirdClassCheckbox"
                          >
                            3rd Class
                          </label>
                        </div>
                        <div className="form-check amePopup-label  col-6   d-flex align-items-lg-center">
                          <div>
                            <Field
                              className="form-check-input"
                              type="checkbox"
                              name="allClasses"
                              id="allClassesCheckbox"
                              checked={checkboxes.allClasses}
                              onChange={checkedAllClass}
                            />
                          </div>
                          <label
                            className="form-check-label pl-0 pl-lg-2 "
                            htmlFor="allClassesCheckbox"
                          >
                            All Class
                          </label>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>

                  {/* Medical Expiring Within: */}

                  <div className="row pt-4">
                    <div className="col-12 d-flex offmarket-output-format  flex-wrap ame-boxShadow px-0 py-4">
                      <div className="custom-form-sub-searchPlane">
                        Medical Expiring Within
                      </div>
                      <div className=" amePopup-label col-6 d-flex align-items-lg-center">
                        <div className="pl-4">
                          <Field
                            className="form-check-input "
                            type="radio"
                            name="medicalExpiring"
                            id="30 Days"
                            value="30 Days"
                            // checked={true}
                          />
                        </div>
                        <label
                          className="form-check-label pl-0 pl-lg-1"
                          htmlFor="30 Days"
                        >
                          30 Days
                        </label>
                      </div>
                      <div className=" amePopup-label  col-6 d-flex  align-items-lg-center">
                        <div className="pl-4">
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="medicalExpiring"
                            id="60 Days"
                            value="60 Days"
                          />
                        </div>
                        <label
                          className="form-check-label pl-0 pl-lg-1"
                          htmlFor="60 Days"
                        >
                          60 Days
                        </label>
                      </div>
                      <div className=" amePopup-label  col-6 d-flex  align-items-lg-center">
                        <div className="pl-4">
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="medicalExpiring"
                            id="90 Days"
                            value="90 Days"
                          />
                        </div>
                        <label
                          className="form-check-label pl-0 pl-lg-1"
                          htmlFor="90 Days"
                        >
                          90 Days
                        </label>
                      </div>
                      <div className=" amePopup-label  col-6 d-flex  align-items-lg-center">
                        <div className="pl-4">
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="medicalExpiring"
                            id="all"
                            value="all"
                          />
                        </div>
                        <label
                          className="form-check-label pl-0 pl-lg-1"
                          htmlFor="all"
                        >
                          All
                        </label>
                      </div>
                      <div className=" amePopup-label  col-6 d-flex  align-items-lg-center">
                        <div className="pl-4">
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="medicalExpiring"
                            id="expired"
                            value="Expired"
                          />
                        </div>
                        <label
                          className="form-check-label pl-0 pl-lg-1"
                          htmlFor="expired"
                        >
                          Expired
                        </label>
                      </div>
                      <div className=" amePopup-label  col-6 d-flex   align-items-center"></div>
                    </div>
                  </div>

                  {/* Output Format */}
                  <div className="row pt-4 pb-4">
                    <div className="col-12 d-flex justify-content-around  flex-column flex-sm-row flex-md-row flex-lg-row  ame-boxShadow px-0 py-4">
                      <div className="custom-form-sub-searchPlane">
                        Output Format
                      </div>
                      <div className="col-2 ml-3 ml-md-0   amePopup-label d-flex align-items-lg-center ">
                        <div>
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="outputFormat"
                            id="CSV"
                            value="CSV"
                            // checked={true}
                          />
                        </div>
                        <label
                          className="form-check-label pl-0 pl-lg-1"
                          htmlFor="CSV"
                        >
                          CSV
                        </label>
                      </div>
                      <div className="col-8  ml-3 ml-md-0 amePopup-label  d-flex align-items-lg-center">
                        <div>
                          {" "}
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="outputFormat"
                            id="PDF"
                            value="PDF"
                          />
                        </div>
                        <label
                          className="form-check-label pl-0 pl-lg-1 text-nowrap"
                          htmlFor="PDF"
                        >
                          Avery 5163 Label PDF
                        </label>
                      </div>
                      <div className="amePopup-label mobile-hide">
                        <label
                          className="form-check-label pl-2 pl-md-0"
                          htmlFor="outputFormat"
                        ></label>
                      </div>
                    </div>
                  </div>

                  <div className="text-center ">
                    <div className=" col-12 btn-wrapper btn-primary-wrapper d-xl-inline mt-3 mt-xl-0 ml-xl-3">
                      <button
                        type="submit"
                        className="custom-freeTrial-btn"
                        data-dismiss="modal"
                      >
                        Export Records
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Formik>
    </>
  );
};
