export const CheckMarkIcon = (props: any) => {
    const { color } = props.style;

    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="24"
        fill="none"
        viewBox="0 0 20 24"
      >
        <path
          fill={color}
          fillRule="evenodd"
          d="M0 12.347c3.923-.052 4.727-.202 7.492 3.167 2.12-3.497 4.477-6.524 7.028-9.164 2.511-2.6 2.53-2.607 5.48-2.563-5.018 5.546-9.113 11.48-12.388 17.768-1.753-3.734-4.2-6.878-7.612-9.208z"
          clipRule="evenodd"
        ></path>
      </svg>
    );
  };
  