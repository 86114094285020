import { useRef, useState } from "react";
import { useAppDispatch } from "../../../hook";
import { toast } from "react-toastify";
import { cancelPlan, currentPlan } from "../../../Features/PlansSlice";
import { Loader } from "../RepeatedComponent/Loader";
import { useNavigate } from "react-router";

export const CancelSubscriptionPopup = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(0);
  const closeRef: any = useRef(null);

  const cancelSubscription = () => {
    setLoading(1);
    dispatch(cancelPlan()).then((res: any) => {
      setLoading(0);
      if (res.payload?.success) {
        toast.success(res.payload?.message, {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 8000,
          pauseOnFocusLoss: true,
          theme: "dark",
        });
        navigate("/subscriptions-list");
        closeRef.current.click();
        dispatch(currentPlan());
      } else {
        toast.error("You have already cancelled subscription auto renewal.", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 8000,
          pauseOnFocusLoss: true,
          theme: "dark",
        });
        navigate("/subscriptions-list");
        closeRef.current.click();
      }
    });
  };

  return (
    <>
      {loading ? <Loader /> : ""}
      <div
        className="modal fade"
        id="cancelSubscriptions"
        role="dialog"
        aria-labelledby="cancelSubscriptionsLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content cancel-Subscription">
            <div className="modal-header">
              <div className="col-12 d-flex font-inter p-0">
                <h4
                  className="modal-title font-inter"
                  id="cancelSubscriptionsLabel"
                >
                  Cancel Subscription
                </h4>
              </div>

              <div className="cross-img-black">
                <img
                  src="images/modal-img/cross-img-black.svg"
                  className="close custom-close "
                  data-dismiss="modal"
                  aria-label="Close"
                  ref={closeRef}
                  style={{ height: "4rem" }}
                ></img>
              </div>
            </div>
            <div className="modal-body text-left">
              Are you sure you want to cancel your subscription?
            </div>
            <div className="modal-footer border-0">
              <button type="button" className="btn " data-dismiss="modal">
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={cancelSubscription}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
