export const PendingTasksAlert = (props:any) => {
    return ( 
        <div className="Toastify">
            <div className="Toastify__toast-container Toastify__toast-container--bottom-right">
            <div id="1" data-in="true" className="Toastify__toast Toastify__toast-theme--dark Toastify__toast--info" style={{ background: "#ffffff", color: "#000", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>


                    <div role="alert" className="Toastify__toast-body">
                        <div className="Toastify__toast-icon Toastify--animate-icon Toastify__zoom-enter">
                            <svg viewBox="0 0 24 24" width="100%" height="100%" fill="#F16722">
                                <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm.25 5a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm2.25 13.5h-4a1 1 0 010-2h.75a.25.25 0 00.25-.25v-4.5a.25.25 0 00-.25-.25h-.75a1 1 0 010-2h1a2 2 0 012 2v4.75a.25.25 0 00.25.25h.75a1 1 0 110 2z"></path>
                            </svg>
                        </div>
                    <div>{props?.totalTasks} Export in Progress.</div>
                    </div>
                <div className="Toastify__progress-bar--wrp" data-hidden="true">
                    <div className="Toastify__progress-bar--bg Toastify__progress-bar-theme--dark Toastify__progress-bar--info"></div>
                    <div role="progressbar" aria-hidden="true" aria-label="notification timer" className="Toastify__progress-bar Toastify__progress-bar--controlled Toastify__progress-bar-theme--dark Toastify__progress-bar--info" style={{"animationPlayState": "paused","transform": "scaleX(0)"}}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}