import React, { useState, useRef, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { usePapaParse } from "react-papaparse";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
// Data table import
import { DataTable, DataTableRowEditCompleteEvent } from "primereact/datatable";
import { Column, ColumnEditorOptions } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
// Import PrimeReact CSS files
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import { Breadcrumbs } from "../RepeatedComponent/Breadcrumb";
import { useAppDispatch, useAppSelector } from "../../../hook";
import { ToastContainer, toast } from "react-toastify";
import { getPilotStates } from "../../../Features/downloadHistorySlice";
import { Loader } from "../RepeatedComponent/Loader";
import { PostCardpayment } from "./PostCardpayment";
import "./CreatePostcard.css";
import "../Login/login.css";
import {
  changeZindexToFalse,
  changeZindexToTrue,
} from "../../../Features/contactUsSlice";

const CreatePostcard = () => {
  interface Person {
    firstName: string;
    lastName: string;
    title: string;
    serviceName: string;
    number: string;
    websiteName: string;
    address: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
    // Add other properties as needed
  }
  const [sendData, setSendData] = useState<Person[]>([]);
  const states = useAppSelector((state: any) => state.downloadHistory.states);
  const postCardFormSubmition = useAppSelector(
    (state: any) => state.contactUs?.postCardFormSubmition || false
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [btnClick, setBtnClick] = useState(true);
  const [error, setError] = useState(false);
  const status = useAppSelector((state: any) => state.auth.status);
  const stripePromise = loadStripe(
    `${process.env.REACT_APP_STRIPE_KEY_POSTCARD_TEST}`
  );
  const [totalAmt, setTotalAmount] = useState(0);
  const buttonRef1 = useRef<HTMLButtonElement | null>(null); // Ref for first button
  const buttonRef2 = useRef<HTMLButtonElement | null>(null);
  const formikRef = useRef<any>(null);

  const [sendersDetail, setSendersDetail] = useState({
    firstName: "",
    lastName: "",
    title: "",
    serviceName:
      "AVIATION EXPERTISE | TAILORED EVALUATIONS | EXPEDITED APPOINTMENTS",
    number: "",
    websiteName: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const [mailDate, setMailDate] = useState({
    mailDate: "",
  });

  useEffect(() => {
    dispatch(getPilotStates());
  }, []);

  useEffect(() => {
    if (!status) {
      navigate("/");
    }
  }, [status, navigate]);

  // defaultValue form values
  const defaultValue = {
    firstName: "",
    lastName: "",
    title: "",
    serviceName:
      "AVIATION EXPERTISE | TAILORED EVALUATIONS | EXPEDITED APPOINTMENTS",
    number: "",
    websiteName: "",
    address: "",
    address2: "",
    state: "",
    city: "",
    zipCode: "",
    dateofmail: "",
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const numberValidation = yup
    .string() // treat input as a string
    .test("is-valid-format", "Invalid number format (555-555-5555)", (val) => {
      // Check if the value matches the format of a number with optional dashes
      return /^\d{3}-\d{3}-\d{4}$/.test(val ?? "");
    });
  // Validation schema using yup
  const validationSchema = yup.object().shape({
    // postcardFile: yup.mixed().required('File is required'),
    firstName: yup
      .string()
      .trim()
      .required("First Name is required")
      .matches(/\S+/, "Name must not contain only white spaces"),
    dateofmail: yup
      .date()
      .required("Date is required")
      .min(
        new Date(today.getTime() + 7 * 86400000),
        "Date must be at least 7 days in the future"
      ),

    lastName: yup
      .string()
      .trim()
      .required("Last Name is required")
      .matches(/\S+/, "Name must not contain only white spaces"),
    title: yup
      .string()
      .trim()
      .required("Title is required")
      .matches(/\S+/, "Title must not contain only white spaces"),
    number: numberValidation,
    serviceName: yup.string().uppercase().trim(),
    websiteName: yup
      .string()
      .trim()
      .matches(/\S+/, "Website must not contain only white spaces"),
    address: yup.string().trim().required("Street is required"),
    address2: yup.string().trim(),
    city: yup.string().trim().required("City is required"),
    state: yup.string().trim().required("State is required"),
    zipCode: yup
      .string()
      .trim()
      .required("Zip Code is required")
      .min(5, "Zip Code must be at least 5 characters long"),
  });

  useEffect(() => {
    let result = sendData.length * 0.97;

    const decimalPart = (result.toString().split(".")[1] || "").length;

    if (decimalPart <= 2) {
      setTotalAmount(result);
      // return result;
    } else {
      result = result + 0.01;
      result = parseFloat(result.toFixed(2));
      setTotalAmount(result);
      // return result;
    }
  }, [sendData]);

  /// Handle form submission
  const handleFormButtonClick = (e: any) => {
    let abc = Object.keys(formikRef.current.errors).length === 0;
    if (!formikRef.current.dirty || !abc) {
      toast.dismiss();
      toast.error("Please fill your details correctly", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 8000,
        pauseOnFocusLoss: true,
        theme: "dark",
      });
    }
  };
  const handleSubmit = async (values: any) => {
    if (sendData.length > 0 && !error) {
      if (buttonRef2.current) {
        buttonRef2.current.click();
      }
      setMailDate({ mailDate: `${values.dateofmail}` });
      setSendersDetail({
        firstName: `${values.firstName}`,
        lastName: `${values.lastName}`,
        title: `${values.title}`,
        serviceName: `${values.serviceName}`,
        number: `${values.number}`,
        websiteName: `${values.websiteName}`,
        address: `${values.address}`,
        address2: `${values.address2}`,
        city: `${values.city}`,
        state: `${values.state}`,
        zipCode: `${values.zipCode}`,
      });
      dispatch(changeZindexToTrue());
    } else {
      toast.dismiss();
      toast.error("Please upload Recipients List", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        autoClose: 1500,
        draggablePercent: 8000,
        pauseOnFocusLoss: true,
        theme: "dark",
      });
      dispatch(changeZindexToFalse());
    }
  };

  const changeData = (oldData: any) => {
    let upDatedData = oldData
      .filter((ele: any) => {
        return (
          ele.length >= 5 &&
          ele[0] &&
          ele[1] &&
          ele[2] &&
          ele[4] &&
          ele[5] &&
          ele[6]
        );
      })
      .map((ele: any) => {
        return {
          firstName: ele[0],
          lastName: ele[1],
          address: ele[2],
          address2: ele[3],
          city: ele[4],
          state: ele[5],
          zipCode: ele[6],
          variables: [],
        };
      });

    if (upDatedData.length > 0) {
      setSendData(upDatedData);
      setError(false);
    } else {
      setError(true);
      setSendData([]);
      setTableData([]);
      toast.dismiss();
      toast.error("Kindly review the format", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 8000,
        pauseOnFocusLoss: true,
        theme: "dark",
      });
    }
  };

  const dropAreaRef = useRef<HTMLDivElement>(null);
  const { readString } = usePapaParse();
  const [tableData, setTableData] = useState<any>();
  const [tableHeader, setTableHeader] = useState<any>();

  const sampleCSVfile = (event: any) => {
    event.preventDefault();
    const url = "/Csvfile/PostCardSampleCSV.csv";
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "PostCardSampleCSV.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    let file = event.target.files?.[0];

    if (file) {
      const fileName = file.name;
      const extension = fileName.split(".").pop()?.toLowerCase();

      // Check if the file extension is not CSV
      if (extension !== "csv") {
        toast.dismiss();
        toast.error("Please upload a CSV file.", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 8000,
          pauseOnFocusLoss: true,
          theme: "dark",
        });
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target && e.target.result) {
          const csvData = e.target.result as string;
          readString(csvData, {
            complete: (results: any) => {
              toast.dismiss();
              toast.success("CSV file Uploaded Successfully", {
                position: "top-center",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 8000,
                pauseOnFocusLoss: true,
                theme: "dark",
              });

              if (Array.isArray(results?.data)) {
                changeData(results?.data.slice(1));
                setTableHeader(results?.data?.[0]);
                setTableData(results?.data.slice(1));
              } else {
                console.error("CSV data is not an array.");
              }
              if (event.target) {
                event.target.value = "";
              }
            },
          });
        }
      };

      reader.readAsText(file);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    // Handle the dropped files here
    handleFileUpload({
      target: { files },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const handleButtonClick = () => {
    document.getElementById("file-input")?.click();
  };

  const orderSummary = {
    totalRecipients: sendData.length,
    chargesPerRecipients: "$0.97",
    totalAmount: totalAmt,
    recipientslist: sendData,
    sendersDetail: sendersDetail,
    mailDate: mailDate,
  };
  let htmlfrontTemplateNew = `<div style="width:6.25in;height:4.5in;display:flex;align-items:center;justify-content:center">
        <div
      style="
        width: 5.75in;
        height: 4in;
        margin:25px auto!important;
        position: relative;
        background: white;
      "
    >
      <div style="position: absolute; z-index: 0">
        <img
        src="${
          process.env.REACT_APP_IMAGE_URL
        }/images/PostCardImages/Doc-pilot.png"
          style="max-width: 100%; max-height: 100%"
        />
      </div>
      <!-- title -->
      <div style="padding: 30px 0px 20px 20px; position: relative; z-index: 1">
        <p
          style="
            font-size: 21px;
            margin: 0;
            color: #000000;
            text-align: left;
            font-weight: 500;
            font-family: 'Poppins', sans-serif;
          "
        >
          Secure Your Wings with
        </p>
        <h3
          style="
            font-size: 24px;
            margin: 0;
            line-height: 29px;
            color: #172859;
            font-weight: bold;
            font-family: 'Poppins', sans-serif;
            text-align: left;
          "
        >
          AVIATION MEDICAL EXAMS
        </h3>
      </div>
      <!-- sub title-->
      <div style="position: relative">
        <div style="position: absolute; z-index: 0">
            <img src="${
              process.env.REACT_APP_IMAGE_URL
            }/images/PostCardImages/blue_absract.svg" />
        </div>
        <div
          style="
            padding: 5px 0px 0px 21px;
            color: white;
            width: 400px;
            z-index: 1;
            position: relative;
          "
        >
          <p style="font-size: 15px;line-height: 17px; margin: 0; text-align: left; font-family: 'Poppins', sans-serif;">
            Specialized Examinations Tailored
          </p>
          <p style="font-size: 15px; line-height: 17px; margin: 0; text-align: left; font-family: 'Poppins', sans-serif;">
            for Pilots Needs!
          </p>
        </div>
      </div>
      <!-- Name & Title -->
      <div style="position: relative; padding: 8px 0px 0px 0px;">
        <div
          style="
              position: absolute;
              width: 130px;
              z-index: 1;
              left: 100px;
              top: 41px;
          "
        >
          <p
            style="
              font-size: 10px;
              margin: 0;
              color: white;
              font-weight: 600;
              text-align: left;
              font-family: 'Poppins', sans-serif;
              line-height: 13px;
            "
          >
          ${
            sendersDetail.firstName
              ? `${sendersDetail.firstName} ${sendersDetail.lastName}`
              : "{{FirstName LastName}}"
          }
          </p>
          <p
            style="
              font-size: 10px;
              margin: 0;
              color: white;
              text-align: left;
              font-family: 'Poppins', sans-serif;
              line-height: 13px;
            "
          >
          ${sendersDetail.title ? sendersDetail.title : "{{Title}}"}
          </p>
        </div>
        <div style="padding:${
          postCardFormSubmition ? "13px 0px 0px 21px" : "13px 0px 13px 21px;"
        }; color: white; display:flex;">
            <img src="${
              process.env.REACT_APP_IMAGE_URL
            }/images/PostCardImages/Doc_icon_small.svg" />
        </div>
      </div>
      <!-- AVIATION EXPERTISE -->
      <div
        style="
          height: 40px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #172859;
        "
      >
        <p style="font-size: 13px; margin: 0; font-weight: 600; color: white; font-family: 'Poppins', sans-serif;">
            ${sendersDetail.serviceName} 
        </p>
      </div>
      <!-- Schedule An Appointment -->
      <div
        style="
          margin-top: 15px;
          height: 50px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #960900;
        "
      >
        <div>
          <p
            style="
              font-size: 13px;
              margin: 0;
              font-weight: 700;
              color: white;
              font-family: 'Poppins', sans-serif;
              text-align: center;
            "
          >
          ${
            sendersDetail.number && sendersDetail.websiteName
              ? `Please Call Or Visit Our Website To Schedule An Appointment Today!`
              : sendersDetail.number
              ? `Contact us to Schedule your Aviation Medical Exam Today!!`
              : `Please Call Or Visit Our Website To Schedule An Appointment Today!`
          }
          </p>
          <p
            style="
              font-size: 13px;
              margin: 0;
              font-weight: 700;
              color: white;
              text-align: center;
              font-family: 'Poppins', sans-serif;
            "
          >
          ${
            sendersDetail.number && sendersDetail.websiteName
              ? `${sendersDetail.number} | ${sendersDetail.websiteName}`
              : sendersDetail.number
              ? `${sendersDetail.number}`
              : `{{Phone}} | {{Website}}`
          }
          </p>
        </div>
      </div>
    </div>
    </div>
  `;

  let htmlbackTemplateNew = `
  <div style="width:6.25in;height:4.5in;display:flex;align-items:center;justify-content:center">
    <div
      style="
        margin:25px auto!important;
        width: 5.75in;
        height: 4in;
        position: relative;
        z-index: ${postCardFormSubmition ? "9999" : "1"};
        background:white;
      "
    >
      <div style="position: absolute; z-index: 0">
      <img src="${
        process.env.REACT_APP_IMAGE_URL
      }/images/PostCardImages/Plane_img.png"/>
     
      </div>
      <!-- title  -->
      <div style="padding: 20px 0px 0px 30px; position: relative; z-index: 1">
        <div style="padding: 0px">
          <h3
            style="
              font-size: 25px;
              margin: 0px;
              line-height: 40px;
              color: white;
              font-weight: bold;
              font-family: 'Poppins', sans-serif;
              text-align: left;
            "
          >
            FLY WITH CONFIDENCE
          </h3>
          <p
            style="
              font-size: 12px;
              padding: 0px 0px;
              color: white;
              text-align: justify;
              width: 50%;
              text-align: left;
              line-height: 17px;
              font-family: Arial, sans-serif;
            "
          >
            We are dedicated to providing pilots with expert aviation medical
            examinations, ensuring their flying privileges are maintained
            confidently. Our experienced Aviation Medical Examiners offer
            tailored evaluations and streamlined processes, prioritizing pilot
            safety and well-being throughout the certification journey.
          </p>
        </div>
      </div>

      <!-- Name & Title -->
      <div
        style="
          display: flex;
          position: relative;
          margin-top: ${postCardFormSubmition ? "45px" : "55px"};
        "
      >
        <div style="position: relative; padding: 0px 0px 0px 0px; width: 50%;">
          <div
            style="
            position: absolute;
            width: 120px;
            z-index: 1;
            left: 130px;
            top: 29px;
            "
          >
            <p
              style="
                font-size: 10px;
                margin: 0px;
                word-wrap: break-word;
                color: white;
                font-weight: 600;
                font-family: 'Poppins', sans-serif;
                text-align: left;
                line-height: 13px;
              "
            >
            ${
              sendersDetail.firstName
                ? `${sendersDetail.firstName} ${sendersDetail.lastName}`
                : "{{FirstName LastName}}"
            }
            </p>
            <p
              style="
                font-size: 10px;
                margin: 0px;
                word-wrap: break-word;
                color: white;
                font-family: 'Poppins', sans-serif;
                text-align: left;
                line-height: 13px;
              "
            >
            ${sendersDetail.title ? sendersDetail.title : "{{Title}}"}
            </p>
          </div>
          <div style="padding: 0px 0px 0px 30px; color: white;">
            <img src="${
              process.env.REACT_APP_IMAGE_URL
            }/images/PostCardImages/Doc_icon_large.svg"/>
          </div>
        </div>
        <!-- Address -->
        <div
          style="
            position: relative;
            padding: 0px 0px 10px 0px;
            width: 47%;
            margin-left:30px;
            display: flex;
            max-height:150px;
            flex-direction: column;
          "
        >
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              width: 100%;
            "
          >
            <div style="width: 70%">
              <p
                style="
                  font-size: 9px;
                  margin: 0px;
                  word-wrap: break-word;
                  color: black;
                  font-family: 'Poppins', sans-serif;
                  text-align: left;
                  line-height: 11px;
                  padding-bottom: 10px;
                "
              >
              ${sendersDetail.firstName ? sendersDetail.firstName : "John"}  ${
    sendersDetail.lastName ? sendersDetail.lastName : "Doe"
  } <br>
                  ${
                    sendersDetail.address
                      ? `${sendersDetail.address}`
                      : `123 Runway,<br>`
                  }
                  ${sendersDetail.address2 ? `, <br> ` : ""}
                  ${
                    sendersDetail.address ? sendersDetail.address2 : "Address2"
                  }<br>
                  ${sendersDetail.city ? sendersDetail.city : "Kitty Hawk"},
                    ${sendersDetail.state ? sendersDetail.state : "NC "}
                ${sendersDetail.zipCode ? sendersDetail.zipCode : "28196"}
              </p>
            </div>

                    <!-- seals for postcard -->
          <div style="width: 30%">
            <img src="${
              process.env.REACT_APP_IMAGE_URL
            }/images/PostCardImages/postCardSeal.svg"/>
          </div>

         
          </div>
     <div style="text-align: left">
                <img src="${
                  process.env.REACT_APP_IMAGE_URL
                }/images/PostCardImages/tuneImg.svg" style="padding-bottom: 5px;"/>
              <p
                style="
                  font-size: 10px;
                  margin: 0px;
                  word-wrap: break-word;
                  color: black;
                  font-weight: 600;
                  font-family: 'Poppins', sans-serif;
                  text-align: left;
                  line-height: 13px;
                "
              >
              ${
                sendData?.[0]?.firstName && sendData?.[0]?.lastName
                  ? sendData?.[0]?.firstName + " " + sendData?.[0]?.lastName
                  : "First Name,Last Name"
              } <br>
                ${
                  sendData?.[0]?.address && sendData?.[0]?.address
                    ? sendData?.[0]?.address + ","
                    : "Address"
                }
                <br>
                ${
                  sendData?.[0]?.address && sendData?.[0]?.address
                    ? sendData?.[0]?.address2
                    : `Address2 <br>`
                }
                
                  ${
                    sendData?.[0]?.state &&
                    sendData?.[0]?.city &&
                    sendData?.[0]?.zipCode
                      ? sendData?.[0]?.city +
                        "," +
                        sendData?.[0]?.state +
                        " " +
                        sendData?.[0]?.zipCode
                      : "City, State ZipCode"
                  }
      
              </p>
            </div>
        </div>
        
      </div>
    </div>
    </div>
`;
  // table data code

  const deleteSelectedProducts = (index: any) => {
    const deleteRow = index.rowIndex;
    let recipientList = sendData.filter((val, index) => index !== deleteRow);
    setSendData(recipientList);
  };

  const textEditor = (options: ColumnEditorOptions) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          options.editorCallback!(e.target.value)
        }
      />
    );
  };

  const allowEdit = (rowData: Person) => {
    return rowData.firstName !== "Blue Band";
  };

  const onRowEditComplete = (e: DataTableRowEditCompleteEvent) => {
    let _products = [...sendData];
    let { newData, index } = e;

    _products[index] = newData as Person;

    setSendData(_products);
  };

  return (
    <>
      {" "}
      <ToastContainer />
      {loading ? <Loader /> : ""}
      <div className="bg-black text-white">
        {/* Breadcrumb */}
        <>
          <Formik
            innerRef={formikRef}
            initialValues={defaultValue}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            <Form className="p-3">
              <div className="sr-multicol-media bg-black pt-1 pt-lg-4 text-center prototype-no-background widget_1600979911688 ">
                <div className="container-fluid content-wrapper pt-2 pt-lg-4">
                  <div className="row d-flex justify-content-center pt-1 pt-md-3 pt-lg-5">
                    <div className="col-md-10">
                      <Breadcrumbs textColor={"text-white"} />
                    </div>
                  </div>
                </div>
              </div>

              {/*  Create Postcard */}
              <div className="sr-multicol-media bg-black pt-1 pt-lg-4 text-start prototype-no-background widget_1600979911688 ">
                <div className="container-fluid">
                  <div className="row pt-md-3">
                    <div className="col-md-10 offset-md-1">
                      <h2 className=" section-heading font-inter text-white  text-center">
                        Create Postcard
                      </h2>

                      <div
                        id="hs_cos_wrapper_widget_1604012269835"
                        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module "
                        style={{}}
                        data-hs-cos-general-type="widget"
                        data-hs-cos-type="module"
                      >
                        <div
                          className="sr-multicol-media bg-black   text-start prototype-no-background widget_1604012269835 "
                          id="investors"
                        >
                          <div className="sr-multicol-media bg-black   text-start prototype-no-background widget_1600979911688 ">
                            <div className="container-fluid content-wrapper p-0 ">
                              <div className="bg-black text-white">
                                <div className="container-fluid px-0  text-center mob-textsize">
                                  {/* <img src='images/finalpostcard.png' /> */}
                                  Instructions: Lorem ipsum dolor sit amet
                                  consectetur, adipisicing elit. Accusamus
                                  voluptates, aliquam perferendis mollitia.
                                </div>
                                <div className="container-fluid  text-start mt-4 p-0">
                                  <h5 className="font-inter mb-1 pr-2"></h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Step 1 Choose template*/}
              <div className="sr-multicol-media bg-black pt-1 pt-lg-4 text-start prototype-no-background widget_1600979911688">
                <div className="container-fluid">
                  <div className="row ">
                    <div className="col-md-10 offset-md-1">
                      <div className=" bg-black container-fluid content-wrapper  text-start p-0">
                        <h5 className="font-inter">
                          {/* Step 3: Select a Template{" "} */}
                          Step 1: Choose a Template .
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="row  px-4 pb-4   ">
                    <div
                      className="col-md-10 offset-md-1 text-center whiteborder px-4 "
                      style={{ overflow: "auto" }}
                    >
                      <div className="bg-black container-fluid content-wrapper px-0 pt-4 bg-black  d-flex flex-wrap justify-content-around">
                        <div className=" col-12 d-flex pl-1 justify-content-center p-0 text-white">
                          {/* btn */}
                          {/* <div
                            className={
                              btnClick
                                ? " col-sm-6  text-white  col-lg-6 text-center custom-btn mt-md-0 mt-3  personalInfo-btn-clicked  custom-btn-information"
                                : " col-sm-6  text-white col-lg-6 text-center custom-btn mt-md-0 mt-3 custom-btn-information"
                            }
                            onClick={() => {
                              setBtnClick(true);
                            }}
                          >
                            Template 1
                          </div> */}
                          <div
                            className={
                              btnClick
                                ? "col-sm-12  text-white  col-lg-12 text-center custom-btn mt-md-0 mt-3  personalInfo-btn-clicked "
                                : "col-sm-6  text-white col-lg-6 text-center custom-btn mt-md-0 mt-3 custom-btn-information"
                            }
                            style={{ cursor: "default", borderRadius: "5px" }}
                            onClick={() => {
                              // setBtnClick(true);
                            }}
                          >
                            Template A
                          </div>

                          {/* <div
                            className={
                              btnClick
                                ? "  col-sm-6   text-white col-lg-6 text-center custom-btn mt-md-0 mt-3 custom-btn-Certificates"
                                : "col-sm-6  text-white col-lg-6 text-center custom-btn personalInfo-btn-clicked custom-btn-Certificates mt-md-0 mt-3 "
                            }
                            onClick={() => {
                              setBtnClick(false);
                            }}
                          >
                            Template 2
                          </div> */}
                        </div>
                      </div>
                      {/* <div className="col-12  offset-md-1 text-center p-0  "> */}
                      <div className="h4 text-white pb-2 pt-4 ">
                        Example of how Postcard is being Received
                      </div>
                      <div className="pb-4 d-flex justify-content-lg-center">
                        {btnClick ? (
                          <div style={{ width: "800px", height: "auto" }}>
                            <div
                              className="pb-4 d-flex  "
                              style={{ justifyContent: "center" }}
                              dangerouslySetInnerHTML={{
                                __html: htmlfrontTemplateNew,
                              }}
                            />
                            <div
                              className="d-flex "
                              style={{ justifyContent: "center" }}
                              dangerouslySetInnerHTML={{
                                __html: htmlbackTemplateNew,
                              }}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>

                      {/* </div> */}
                    </div>

                    {/* Dynamic Template*/}
                  </div>
                </div>
              </div>

              {/* Step 2 Provide your Informations */}
              <div className="sr-multicol-media bg-black pt-4 pt-md-2 text-start prototype-no-background widget_1600979911688">
                <div className="container-fluid">
                  <div className="row pt-md-3">
                    <div className="col-md-10 offset-md-1">
                      <div className="bg-black container-fluid content-wrapper  text-start p-0">
                        <h5 className="font-inter">
                          Step 2: Provide your Information{" "}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="row text-center px-4 pb-4   ">
                    <div className="col-md-10 offset-md-1 pb-3 p-4  custom-form-fields">
                      {/* First Name & Last Name */}
                      <div className="row mb-md-3">
                        <div className="col-md-6 col-12 pt-3  pt-md-0">
                          <div className=" col-12 d-flex pl-1  align-items-center custom-border-orange-field py-2">
                            <div className="custom-form-sub-heading">
                              Firstname*
                            </div>
                            <Field
                              className="form-control bg-white text-black border-0"
                              name="firstName"
                              placeholder=" Please enter first name"
                              aria-label="Enter Your Email ID"
                              aria-describedby="basic-addon1"
                              autoComplete="on"
                              value={sendersDetail.firstName}
                              maxLength={50}
                              onInput={(e: any) =>
                                setSendersDetail({
                                  ...sendersDetail,
                                  firstName: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="pt-1 pt-md-0">
                            <p className="text-danger text-left   field-errorMessage ">
                              <ErrorMessage name="firstName" />
                            </p>
                          </div>
                        </div>

                        <div className="col-12 col-md-6 pt-3  pt-md-0">
                          <div className=" col-12 d-flex pl-1  align-items-center custom-border-orange-field py-2">
                            <div className="custom-form-sub-heading">
                              Lastname*
                            </div>
                            <Field
                              className="form-control bg-white text-black border-0"
                              name="lastName"
                              placeholder=" Please enter last name"
                              aria-label="Enter Your Email ID"
                              aria-describedby="basic-addon1"
                              autoComplete="on"
                              value={sendersDetail.lastName}
                              maxLength={50}
                              onInput={(e: any) =>
                                setSendersDetail({
                                  ...sendersDetail,
                                  lastName: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="pt-1 pt-md-0">
                            <p className="text-danger text-left   field-errorMessage ">
                              <ErrorMessage name="lastName" />
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* Title  & content 1*/}
                      <div className="row mb-md-3">
                        <div className="col-md-6 col-12 pt-3  pt-md-0">
                          <div className=" col-12 d-flex pl-1  align-items-center custom-border-orange-field py-2">
                            <div className="custom-form-sub-heading">
                              Title*
                            </div>
                            <Field
                              className="form-control bg-white text-black border-0"
                              name="title"
                              placeholder="Please enter title"
                              aria-label="Enter Your Email ID"
                              aria-describedby="basic-addon1"
                              autoComplete="on"
                              value={sendersDetail.title}
                              maxLength={50}
                              onInput={(e: any) =>
                                setSendersDetail({
                                  ...sendersDetail,
                                  title: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="pt-1 pt-md-0">
                            <p className="text-danger text-left   field-errorMessage ">
                              <ErrorMessage name="title" />
                            </p>
                          </div>
                        </div>

                        <div className="col-12 col-md-6 pt-3  pt-md-0">
                          <div className=" col-12 d-flex pl-1  align-items-center custom-border-orange-field py-2">
                            <div className="custom-form-sub-heading">
                              Services
                            </div>
                            <Field
                              className="form-control bg-white text-black border-0"
                              name="serviceName"
                              placeholder="Please enter the type of services you offer"
                              aria-label="Enter Your Email ID"
                              aria-describedby="basic-addon1"
                              autoComplete="on"
                              value={sendersDetail.serviceName.toUpperCase()}
                              maxLength={64}
                              onInput={(e: any) =>
                                setSendersDetail({
                                  ...sendersDetail,
                                  serviceName: e.target.value,
                                })
                              }
                            />
                          </div>
                          {/* <div className="pt-1 pt-md-0">
                            <p className="text-danger text-left   field-errorMessage ">
                              <ErrorMessage name="serviceName" />
                            </p>
                          </div> */}
                        </div>
                      </div>
                      {/* Phone No. &   Website*/}
                      <div className="row mb-md-3">
                        <div className="col-md-6 col-12 pt-3  pt-md-0">
                          <div className=" col-12 d-flex pl-1  align-items-center custom-border-orange-field py-2">
                            <div className="custom-form-sub-heading">
                              Phone No.*
                            </div>
                            <Field
                              className="form-control bg-white text-black border-0"
                              name="number"
                              placeholder="Please enter number"
                              aria-label="Enter Your Email ID"
                              aria-describedby="basic-addon1"
                              autoComplete="on"
                              value={sendersDetail.number}
                              maxLength={12}
                              onInput={(e: any) =>
                                setSendersDetail({
                                  ...sendersDetail,
                                  number: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="pt-1 pt-md-0">
                            <p className="text-danger text-left   field-errorMessage ">
                              <ErrorMessage name="number" />
                            </p>
                          </div>
                        </div>

                        <div className="col-12 col-md-6 pt-3  pt-md-0">
                          <div className=" col-12 d-flex pl-1  align-items-center custom-border-orange-field py-2">
                            <div className="custom-form-sub-heading">
                              Website
                            </div>
                            <Field
                              // type="number"
                              className="form-control bg-white text-black border-0"
                              name="websiteName"
                              placeholder=" Please enter Website name"
                              aria-label="Enter Your Email ID"
                              aria-describedby="basic-addon1"
                              autoComplete="on"
                              value={sendersDetail.websiteName}
                              maxLength={50}
                              onInput={(e: any) =>
                                setSendersDetail({
                                  ...sendersDetail,
                                  websiteName: e.target.value,
                                })
                              }
                            />
                          </div>
                          {/* <div className="pt-1 pt-md-0">
                            <p className="text-danger text-left   field-errorMessage ">
                              <ErrorMessage name="websiteName" />
                            </p>
                          </div> */}
                        </div>
                      </div>
                      {/*  */}
                      <div className="row  mb-md-3">
                        <div className="col-12 col-md-6  pt-3  pt-md-0">
                          <div className=" col-12 d-flex pl-1  align-items-center custom-border-orange-field py-2">
                            <div className="custom-form-sub-heading">
                              Address1*
                            </div>
                            <Field
                              // type="number"
                              className="form-control bg-white text-black border-0"
                              name="address"
                              placeholder=" Please enter address1"
                              aria-label="Enter Your Email ID"
                              aria-describedby="basic-addon1"
                              autoComplete="on"
                              value={sendersDetail.address}
                              maxLength={50}
                              onInput={(e: any) =>
                                setSendersDetail({
                                  ...sendersDetail,
                                  address: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="pt-1 pt-md-0">
                            <p className="text-danger text-left   field-errorMessage ">
                              <ErrorMessage name="address" />
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 pt-3  pt-md-0">
                          <div className=" col-12 d-flex pl-1  align-items-center custom-border-orange-field py-2">
                            <div className="custom-form-sub-heading">
                              Address2
                            </div>
                            <Field
                              // type="number"
                              className="form-control bg-white text-black border-0"
                              name="address2"
                              placeholder=" Please enter address2"
                              aria-label="Enter Your Email ID"
                              aria-describedby="basic-addon1"
                              value={sendersDetail.address2}
                              maxLength={50}
                              onInput={(e: any) =>
                                setSendersDetail({
                                  ...sendersDetail,
                                  address2: e.target.value,
                                })
                              }
                              autoComplete="on"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row  mb-md-2 ">
                        {/* City */}
                        <div className="col-md-4 col-12 pt-3  pt-md-0">
                          <div className=" col-12 d-flex pl-1  align-items-center custom-border-orange-field py-2">
                            <div className="custom-form-sub-heading">City*</div>
                            <Field
                              // type="number"
                              className="form-control bg-white text-black border-0"
                              name="city"
                              placeholder=" Please enter city"
                              aria-label="Enter Your Email ID"
                              aria-describedby="basic-addon1"
                              value={sendersDetail.city}
                              maxLength={50}
                              onInput={(e: any) =>
                                setSendersDetail({
                                  ...sendersDetail,
                                  city: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="pt-1 pt-md-0">
                            <p className="text-danger text-left   field-errorMessage ">
                              <ErrorMessage name="city" />
                            </p>
                          </div>
                        </div>
                        {/* State */}
                        <div className="col-12 col-md-4  pt-3  pt-md-0">
                          <div className=" col-12 d-flex pl-0  align-items-center custom-border-orange-field py-2">
                            <div className="custom-form-sub-heading">
                              State*
                            </div>

                            <Field
                              as="select"
                              name="state"
                              className="form-control bg-white text-black border-0"
                              placeholder=" Please enter Zipcode"
                              aria-label="Enter Your Email ID"
                              aria-describedby="basic-addon1"
                              value={sendersDetail.state}
                              onInput={(e: any) =>
                                setSendersDetail({
                                  ...sendersDetail,
                                  state: e.target.value,
                                })
                              }
                            >
                              {states && states.length ? (
                                <>
                                  <option value="">Select State</option>
                                  {states.map(
                                    (state: any) =>
                                      state.state && (
                                        <option
                                          key={state.state}
                                          value={state.state_abbrev}
                                        >
                                          {state.state_abbrev}
                                        </option>
                                      )
                                  )}
                                </>
                              ) : (
                                <option value="">Select State</option>
                              )}
                            </Field>
                          </div>
                          <div className="pt-1 pt-md-0">
                            <p className="text-danger text-left   field-errorMessage ">
                              <ErrorMessage name="state" />
                            </p>
                          </div>
                        </div>

                        {/* Zipcode */}
                        <div className="col-md-4 col-12 pt-3  pt-md-0">
                          <div className=" col-12 d-flex pl-1  align-items-center custom-border-orange-field py-2">
                            <div className="custom-form-sub-heading">
                              Zipcode*
                            </div>
                            <Field
                              // type="number"
                              className="form-control bg-white text-black border-0"
                              name="zipCode"
                              placeholder=" Please enter Zipcode"
                              aria-label="Enter Your Email ID"
                              aria-describedby="basic-addon1"
                              autoComplete="on"
                              minLength={5}
                              maxLength={9}
                              value={sendersDetail.zipCode}
                              // onChange={handlePincode}
                              onInput={(e: any) => {
                                let check = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                                if (check.charAt(0) === "0") {
                                  check = check.slice(1); // Remove the first character if it's '0'
                                }
                                setSendersDetail({
                                  ...sendersDetail,
                                  zipCode: check,
                                });
                                // Remove non-numeric characters
                              }}
                            />
                          </div>
                          <div className="pt-1 pt-md-0">
                            <p className="text-danger text-left   field-errorMessage ">
                              <ErrorMessage name="zipCode" />
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="raw justify-content-center d-none">
                        <div className=" d-flex justify-content-center align-items-center">
                          <button
                            ref={buttonRef1}
                            type="submit"
                            className="custom-btn-begin-search border-1 font-inter"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Step 3: Upload the Recipients */}
              <div className="sr-multicol-media bg-black pt-1 text-start prototype-no-background widget_1600979911688 ">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-10 offset-md-1">
                      <div
                        id="hs_cos_wrapper_widget_1604012269835"
                        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module "
                        style={{}}
                        data-hs-cos-general-type="widget"
                        data-hs-cos-type="module"
                      >
                        <div
                          className="sr-multicol-media bg-black   text-start prototype-no-background widget_1604012269835 "
                          id="investors"
                        >
                          <div className="sr-multicol-media bg-black   text-start prototype-no-background widget_1600979911688 ">
                            <div className="container-fluid content-wrapper p-0 ">
                              <div className="bg-black text-white">
                                <div className="container-fluid  text-start mt-4 p-0">
                                  <h5 className="font-inter mb-1 pr-2">
                                    Step 3: Upload the Recipients{"    "}(
                                    <a href="#" onClick={sampleCSVfile}>
                                      Download Sample File
                                    </a>
                                    )
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Drag and Drop */}
                          <div className="container-fluid pt-0 pt-md-2 p-1">
                            <div className="">
                              <div className="container-fluid bg-black text-white p-0">
                                <div
                                  className="headingheight p-5 bg-black text-white"
                                  ref={dropAreaRef}
                                  onDragOver={handleDragOver}
                                  onDrop={handleDrop}
                                >
                                  <div className="headingmain">
                                    Choose a file or drag and drop{" "}
                                    <span className="name">CSV file</span> here
                                    <div className="mb-3 d-flex align-content-center justify-content-center mt-md-5 mt-2">
                                      <div className="btn-wrapper amecol btn-wrapper-sm btn-primary-wrapper d-xl-inline mt-3 d-flex mt-xl-0 ">
                                        <Link
                                          to="#"
                                          className="custom-login-btn bg-theme border-0"
                                          onClick={handleButtonClick}
                                        >
                                          <img
                                            src="images/ameimg/cloud1.svg"
                                            alt="upload"
                                            className="mr-2 text-center"
                                          />{" "}
                                          <span className="amef">
                                            Browse File
                                          </span>
                                        </Link>
                                      </div>
                                    </div>
                                    <div className=" d-xl-inline text-white mt-3 mt-xl-0 text-center ">
                                      <div className=" d-flex justify-content-center"></div>
                                    </div>
                                  </div>
                                  <input
                                    type="file"
                                    name="postcardFile"
                                    accept=".csv"
                                    onChange={handleFileUpload}
                                    style={{ display: "none" }}
                                    id="file-input"
                                  />
                                </div>
                                <p className="text-danger text-left   field-errorMessage ">
                                  <ErrorMessage name="postcardFile" />
                                </p>
                              </div>
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*   Data Table Edit & delete  */}
                  <div className="container-fluid">
                    <div className="row pt-md-3">
                      <div className="col-md-10 offset-md-1 p-0">
                        {error ? (
                          ""
                        ) : (
                          <div>
                            {tableData?.length > 0 && (
                              <div className="container-fluid mt-4 p-0">
                                <div className="card">
                                  <DataTable
                                    value={sendData}
                                    editMode="row"
                                    dataKey="id"
                                    onRowEditComplete={onRowEditComplete}
                                    paginator
                                    rows={5}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    tableStyle={{ minWidth: "50rem" }}
                                    //  style={{ width: "20%",
                                    //   background:" #E35D22",
                                    //   color: "white"}}
                                  >
                                    <Column
                                      field="firstName"
                                      header="First Name"
                                      editor={(options) => textEditor(options)}
                                      style={{ width: "15%" }}
                                    ></Column>
                                    <Column
                                      field="lastName"
                                      header="Last Name"
                                      editor={(options) => textEditor(options)}
                                      style={{ width: "15%" }}
                                    ></Column>
                                    <Column
                                      field="address"
                                      header="Address1"
                                      editor={(options) => textEditor(options)}
                                      style={{ width: "20%" }}
                                    ></Column>
                                    <Column
                                      field="address2"
                                      header="Address2"
                                      editor={(options) => textEditor(options)}
                                      style={{ width: "20%" }}
                                    ></Column>
                                    <Column
                                      field="city"
                                      header="City"
                                      editor={(options) => textEditor(options)}
                                      style={{ width: "10%" }}
                                    ></Column>
                                    <Column
                                      field="state"
                                      header="State"
                                      editor={(options) => textEditor(options)}
                                      style={{ width: "10%" }}
                                    ></Column>
                                    <Column
                                      field="zipCode"
                                      header="Zip Code"
                                      editor={(options) => textEditor(options)}
                                      style={{ width: "15%" }}
                                    ></Column>
                                    <Column
                                      rowEditor={allowEdit}
                                      header="Edit"
                                      headerStyle={{
                                        width: "5%",
                                        minWidth: "8rem",
                                      }}
                                      bodyStyle={{ textAlign: "center" }}
                                    ></Column>

                                    <Column
                                      header="Delete"
                                      headerStyle={{
                                        width: "5%",
                                        minWidth: "8rem",
                                      }}
                                      bodyStyle={{ textAlign: "center" }}
                                      // Assuming deleteSelectedProducts is a function handling deletion
                                      body={(rowData, index) => (
                                        <Button
                                          className="data-table-trash"
                                          style={{}}
                                          onClick={() =>
                                            deleteSelectedProducts(index)
                                          } // Assuming deleteSelectedProducts takes index as an argument
                                          type="button"
                                        >
                                          <img
                                            src="./images/trash-can-icon.svg"
                                            style={{
                                              height: "20px",
                                            }}
                                            alt="Delete"
                                          />
                                        </Button>
                                      )}
                                    />
                                  </DataTable>
                                </div>
                              </div>
                            )}{" "}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Step 4: Postcard Date  */}
              <div className="sr-multicol-media bg-black pt-1 pt-lg-4 text-start prototype-no-background widget_1600979911688">
                <div className="container-fluid">
                  <div className="row ">
                    <div className="col-md-10 offset-md-1">
                      <div className=" bg-black container-fluid content-wrapper  text-start p-0">
                        <h5 className="font-inter">
                          Step 4: Postcard Send Date
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="row text-center px-4 pb-4   ">
                    <div className="col-md-10 offset-md-1 pb-3 p-2 themeborder custom-orange-border-search">
                      <div className="d-flex name pt-2 px-4 col-12 col-md-4 create-post-card-date">
                        <Field
                          type="date"
                          className="form-control themeborder2 bg-black py-4 text-white date-input"
                          aria-describedby="basic-addon1"
                          name="dateofmail"
                        />
                      </div>
                      <p className="text-danger text-left mt-2 px-4 bg-black field-errorMessage ">
                        <ErrorMessage name="dateofmail" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Pay Now */}
              <div className="sr-multicol-media bg-black pt-1 pt-lg-4 text-start prototype-no-background widget_1600979911688">
                <div className="container-fluid">
                  <div className="row ">
                    <div className="col-md-10 offset-md-1">
                      <div className=" bg-black container-fluid content-wrapper  text-start p-0">
                        <h5 className="font-inter">Step 5: Pay Now </h5>
                      </div>
                    </div>
                  </div>

                  <div className="row text-center px-4 pb-4   ">
                    <div className="col-md-10 offset-md-1 pb-3 p-2 themeborder custom-orange-border-search">
                      <div className="d-flex name pt-2">
                        <div className="col-8 text-left text-white h5">
                          Total Recipients
                        </div>
                        <div className="col-4 name text-right h5">
                          {sendData.length}
                        </div>
                      </div>
                      <div className="d-flex name pt-2">
                        <div className="col-8 text-left text-white h5">
                          Price per Recipient
                        </div>
                        <div className="col-4 name text-right h5 ">$0.97</div>
                      </div>
                      <div className="d-flex name pt-2">
                        <div className="col-8 text-left name h5">
                          Total Price
                        </div>
                        <div className="col-4 text-right name h5">
                          ${totalAmt}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row text-center px-4 pb-4   ">
                    <div className="col-md-10 offset-md-1 pb-3 p-2  text-right">
                      {" "}
                      <button
                        className="btn btn-primary"
                        onClick={handleFormButtonClick}
                        type="submit"
                      >
                        Pay Now
                      </button>
                      <button
                        className="d-none"
                        // disabled
                        ref={buttonRef2}
                        data-toggle="modal"
                        data-target=".postcardmodal"
                      >
                        hello
                      </button>
                    </div>
                  </div>
                  {/* modal start from */}

                  <Elements stripe={stripePromise}>
                    <PostCardpayment
                      orderDetails={orderSummary}
                      front={htmlfrontTemplateNew}
                      back={htmlbackTemplateNew}
                    />
                  </Elements>
                </div>
              </div>
            </Form>
          </Formik>
        </>
      </div>
    </>
  );
};

export default CreatePostcard;
