import { useLocation } from "react-router-dom";
import "./breadcrumb.css";
import { Link } from "react-router-dom";

export const Breadcrumbs = (props:any) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling animation
    });
  };
  const location = useLocation();
  // Customize the content based on the location.pathname
  const getPageContent = () => {
    switch (location.pathname) {
      case "/":
        return {
          currentPage: "Home",
        };
      case "/about":
        return {
          currentPage: "About Us",
        };
        case "/terms":
        return {
          currentPage: "Terms of Service",
        };
      case "/subscriptions-list":
        return {
          currentPage: "Subscriptions Info",
        };
        case "/subscriptions-list":
        return {
          currentPage: "Cancel Subscriptions",
        };
        case "/create-postcard":
          return {
            currentPage: "Create Postcard",
          };
      case "/aviation-research-services":
        return {
          currentPage: "Off-Market Aircraft Leads",
        };

      case "/search":
        return {
          currentPage: "Search",
        };
case "/forgot-password":
  return {
    currentPage: "Forgot Password",
  }
      case "/change-password":
        return {
          currentPage: "Change Password",
        };

      case "/aviation-list-services":
        return {
          currentPage: "Aviation List and Label Services",
        };

      case "/ame-map":
        return {
          currentPage: "AME Patient Leads",
        };

      // Admin
      // Quick Search
      case "/quick-search":
        return {
          currentPage: "Quick Search",
        };
      // Begin Your Search
      case "/search-airmen-records":
        return {
          title: "Search Airmen Records",
          textUp: "1",
          description:
            " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s",
          buttonContent: "Quick Search for Airmen & Aircraft",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
          // Other specific content for Page 2
        };

      case "/search-airmen-certificate-records":
        return {
          title: "Search Airmen Certificate Records",
          description:
            " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s",
          background: "url('images/home-banner.png')",
          buttonContent: "Quick Search for Airmen & Aircraft",
          backgroundSize: "cover",
          // Other specific content for Page 2
        };

      case "/search-aircraft-records":
        return {
          textUp: "1",
          title: "Search Aircraft Records",
          description:
            "An absolute must for aviation and aircraft sales professionals.",
          background: "url('images/home-banner.png')",
          buttonContent: "Quick Search for Airmen & Aircraft",
          backgroundSize: "cover",
          // Other specific content for Page 2
        };
      case "/user-dashboard":
        return {
          currentPage: "Dashboard",
        };
        case "/login":
        return {
          currentPage: "Login",
        };
        case "/register":
        return {
          currentPage: "Register",
        };
        case "/changepassword/":
        return {
          currentPage: "Change Password",
        };
      // history
      case "/your-subscription-history":
        return {
          title: "Your Subscription History",
          description:
            " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
          // Other specific content for Page 2
        };
      case "/your-payment-history":
        return {
          currentPage: "Your Payment History",
        };

      case "/your-downloads-history":
        return {
          currentPage: "Your Downloads History",
        };

      // planeList DataBase
      case "/plane-lists-databases":
        return {
          title: "PlaneLists.com Databases",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
          // Other specific content for Page 2
        };
      case "/login":
        return {
          title: "Login",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
          // Other specific content for Page 2
        };
      case "/register":
        return {
          title: "Register",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
          // Other specific content for Page 2
        };
      case "/active-airmen-pilots":
        return {
          title: "Active Airmen & Pilots ",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
          // Other specific content for Page 2
        };
      case "/AMEs":
        return {
          title: "AMEs",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
          // Other specific content for Page 2
        };
      case "/marketing-genius":
        return {
          title: "Marketing Genius ",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
          // Other specific content for Page 2
        };
      case "/historical-data":
        return {
          title: "Historical Data ",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
          // Other specific content for Page 2
        };
      case "/US-Aircraft-Owners":
        return {
          title: "US Aircraft Owners ",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
          // Other specific content for Page 2
        };

      // Footer Links
      case "/about":
        return {
          title: "About US",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
        };
      case "/contact":
        return {
          currentPage: "Contact US",
        };
      case "/privacy-policy":
        return {
          currentPage: "Privacy Policy",
        };
        case "/aircraftrecords":
        return {
          currentPage: "Aircrafts Records",
        };
        case "/airmenrecords":
          return {
            currentPage: "Airmen Records",
          };
      case "/blog":
        return {
          currentPage: "Blogs",
        };
        case "/forgot-id-password":
        return {
          currentPage:"Forgot Email ID or Password",
        };
      // Add more cases for different pages as needed
      default:
        return {
          title: "Default Title",
          description: "Default Description...",
          // Default content
        };
    }
  };

  const { title, description, background, buttonContent, currentPage } =
    getPageContent();
  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb d-flex align-items-center">
          <li className="breadcrumb-item text-center"> 
            <Link to="/" onClick={scrollToTop}   className={`${props.textColor || `text-black`}`}>
              Home
            </Link>
          </li>
          <li className="breadcrumb-item active d-flex align-items-center" aria-current="page">
            <Link
              to={location.pathname}
              // className={`${props.textColor || `text-black`}`}
              onClick={scrollToTop}
            >
              {currentPage}
            </Link>
          </li>
        </ol>
      </nav>
    </>
  );
};
