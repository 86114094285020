import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import apiClient from "./FetchClient";

interface plans {
  subscriptions: []
}

const initialState: plans = {
  subscriptions: []
};

// ------- plans -----
export const plans = createAsyncThunk(
  "plans/get",
  async (body, thunkAPI) => {
    try {
      const authToken = localStorage.getItem("_authToken");
      if (!authToken) {
        // Handle the case where auth token is not available
        throw new Error("Authentication token not found");
      }

      const response = await apiClient.get(
        `${process.env.REACT_APP_API_URL}/get_subscription_plans`,
      );

      if (response.data.success) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);

export const currentPlan = createAsyncThunk(
  "plans/getCurrent",
  async (body, thunkAPI) => {
    try {
      const authToken = localStorage.getItem("_authToken");
      if (!authToken) {
        // Handle the case where auth token is not available
        throw new Error("Authentication token not found");
      }

      const response = await apiClient.get(
        `${process.env.REACT_APP_API_URL}/get_current_plan`,
      );

      if (response.data.success) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);

export const cancelPlan = createAsyncThunk(
  "plans/cancel",
  async (body, thunkAPI) => {
    try {
      const authToken = localStorage.getItem("_authToken");
      if (!authToken) {
        // Handle the case where auth token is not available
        throw new Error("Authentication token not found");
      }

      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/cancel_subscription`, {}
      );

      if (response?.data.success) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);

export const subscribePlan = createAsyncThunk(
  "plans/subscribe",
  async (body: any, thunkAPI) => {
    try {
      const authToken = localStorage.getItem("_authToken");
      if (!authToken) {
        // Handle the case where auth token is not available
        throw new Error("Authentication token not found");
      }

      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/create_subscription`, body
      );

      if (response.data.success) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);

export const sendPostCardData = createAsyncThunk(
  "plans/dendpostcard",
  async (body: any, thunkAPI) => {
    try {
      const authToken = localStorage.getItem("_authToken");
      if (!authToken) {
        // Handle the case where auth token is not available
        throw new Error("Authentication token not found");
      }

      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/send_postcard`, body
      );

      if (response.data.success) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);

export const plansSlice = createSlice({
  name: "plans",
  initialState,
  reducers: {
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(plans.pending, (state: any) => {
        state.status = false;
      })
      .addCase(plans.fulfilled, (state: any, action: any) => {
        state.status = true;
        state.error = "";
        state.subscriptions = action.payload.subscriptions;
        state.response = action.meta.requestStatus;
      })
      .addCase(plans.rejected, (state: any, action: any) => {
        state.status = false;
        state.error = action.error.message;
      });

    //Create Subscription
    builder
      .addCase(subscribePlan.pending, (state: any) => {
        state.status = false;
      })
      .addCase(subscribePlan.fulfilled, (state: any, action: any) => {
        state.status = true;
        state.error = "";
        state.message = action.payload.message;
        state.response = action.meta.requestStatus;
      })
      .addCase(subscribePlan.rejected, (state: any, action: any) => {

        state.status1 = false;
        state.error1 = action.error.message;
      });

    builder
      .addCase(sendPostCardData.pending, (state: any) => {
        state.status1 = false;
      })
      .addCase(sendPostCardData.fulfilled, (state: any, action: any) => {
        state.status1 = true;
        state.error1 = "";
        state.message1 = action.payload.message;
        state.response1 = action.meta.requestStatus;
      })
      .addCase(sendPostCardData.rejected, (state: any, action: any) => {
        state.status1 = false;
        state.error1 = action.error.message;
      });


    //current Plan
    builder
      .addCase(currentPlan.pending, (state: any) => {
        state.status = false;
      })
      .addCase(currentPlan.fulfilled, (state: any, action: any) => {
        state.status = true;
        state.error = "";
        state.message = action.payload;
        state.response = action.payload;
      })
      .addCase(currentPlan.rejected, (state: any, action: any) => {
        state.status = false;
        state.error = action.error.message;
      });

    //cancel Plan
    builder
      .addCase(cancelPlan.pending, (state: any) => {
        state.status = false;
      })
      .addCase(cancelPlan.fulfilled, (state: any, action: any) => {
        state.status = true;
        state.error = "";
        state.cancel = action.payload;
        state.response = action.meta.requestStatus;
      })
      .addCase(cancelPlan.rejected, (state: any, action: any) => {
        state.status = false;
        state.error = action.error.message;
      });
  },
});


export const userList = (state: RootState) => state.plans;
export default plansSlice.reducer;
