import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import apiClient from "./FetchClient";
interface User {
  success: "",
  can_download: boolean,
  can_download_msg: string
}
const initialState: User = {
  success: "",
  can_download: true,
  can_download_msg: "You don't have any active subscription to export records."
};

// ------- Fetch User  -----
export const fetchUser = createAsyncThunk(
  "fetchUser/user",
  async (body, thunkAPI) => {
    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/fetch_current_user`,
        {
        }
      );

      if (response.data.success) {

        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);

// ------- fetch User Slice ----
export const fetchUserSlice = createSlice({
  name: "fetchUser",
  initialState,
  reducers: {
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchUser.pending, (state: any) => {
        state.status = false;
      })
      .addCase(fetchUser.fulfilled, (state: any, action: any) => {
        if (action.payload.loggedin_user.current_subscription.subscription?.is_active && action.payload.loggedin_user.current_subscription.plan) {
          if (action.payload.loggedin_user.current_subscription.plan.tenure === 'month') {
            if (!action.payload.loggedin_user.current_subscription.plan.total_query || (action.payload.loggedin_user.current_subscription.plan.total_query - action.payload.loggedin_user.current_subscription.subscription.consume_query > 0)) {
              state.can_download = true;
              state.can_download_msg = "";
            }
            else {
              state.can_download = false;
              state.can_download_msg = "Your downloading limit has been exceeded";
            }

          }
          else {
            state.can_download = true;
            state.can_download_msg = "";
          }
        }
        else {
          state.can_download = false;
          state.can_download_msg = "You don't have any active subscription to export records."
        }
        state.status = true;
        state.error = "";
        state.userInfo = action.payload.loggedin_user;
        state.user = {
          pending_tasks: action.payload.loggedin_user.pending_tasks,
        }
        state.response = action.meta.requestStatus;
      })
      .addCase(fetchUser.rejected, (state: any, action: any) => {
        state.status = false;
        state.error = action.error.message;
      });

  },
});

export const userList = (state: RootState) => state.user;
export default fetchUserSlice.reducer;
