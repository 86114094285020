import { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { FaUserAlt } from "react-icons/fa";
import { TfiEmail } from "react-icons/tfi";
import { BiSolidLock, } from "react-icons/bi";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import "../Login/login.css";
import {
  callToaster,
  loginTrue,
  userLogin,
  userSignup,
} from "../../../Features/authSlice";
import { useAppDispatch, useAppSelector } from "../../../hook";
import { FreeTrial } from "../RepeatedComponent/FreeTrial";
import { Loader } from "../RepeatedComponent/Loader";
import { ToastContainer, toast } from "react-toastify";
import { Breadcrumbs } from "../RepeatedComponent/Breadcrumb";

export const Register = () => {
  const [token, setToken] = useState("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(0);
  const status = useAppSelector((state: any) => state.auth.status);

  // defaultValue form values
  const defaultValue = {
    name: "",
    lastname:"",
    email: "",
    password: "",
    password_confirmation: "",
    coupon_code: "",
  };

  // Validation schema using yup
  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .trim()
      .required("First Name is required")
      .matches(/^[A-Za-z]+$/, "First Name must contain only letters")
      .matches(/\S+/, "Name must not contain only white spaces"),
      lastname: yup
      .string()
      .trim()
      .required("Last Name is required")
      .matches(/^[A-Za-z]+$/, "Last Name must contain only letters")
      .matches(/\S+/, "Name must not contain only white spaces"),
    email: yup
      .string()
      .trim()
      .matches(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/, "Invalid email address")
      .required("Email is required"),
    password: yup
      .string()
      .trim()
      .required("Password is required")
      .matches(/^.{8,}$/, "Password must contain at least 8 characters"),
    password_confirmation: yup
      .string()
      .trim()
      .oneOf(
        [yup.ref("password"), ""],
        "Password and confirmation password do not match"
      )
      .required("Confirm Password is required"),
    coupon_code: yup.string(),
  });

  /// Handle form submission
  const handleSubmit = (values: any) => {
    if (!token) {
      toast.error("ReCaptcha Validation Failed", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 8000,
        pauseOnFocusLoss: true,
        theme:"dark"
      });
      return;
    }
    
    let updatedValues = {
      ...values,
      name: values.name + " " + values.lastname,
    };
    delete updatedValues.firstName;
    delete updatedValues.lastName;
    setLoading(1);

    dispatch(userSignup(updatedValues)).then((res: any) => {
      if (res.payload.id) {
        dispatch(userLogin(updatedValues)).then((res: any) => {
          dispatch(loginTrue());
          setLoading(0);
          if (res.payload) {
            
            updatedValues = {
              ...updatedValues,
              msg: "Thank you for registering! Your account has been created successfully.",
              show: true,
            };
            dispatch(callToaster(updatedValues));
            navigate("/subscriptions-list");
          }
        });
      } else {
        toast.error("Email already exists", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 8000,
          pauseOnFocusLoss: true,
          theme:"dark"
        });
        setLoading(0);
      }
    }); // Dispatching the signInUser action with form values
  };

  const toggleShowPassword = () => {
    setShowPassword(true);
  };

  const toggleHidePassword = () => {
    setShowPassword(false);
  };

  

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling animation
    });
  };

  const handleRecaptchaChange = (token: any) => {
    setToken(token);
    // Add any logic for handling reCAPTCHA verification
  };

  useEffect(() => {
    if (status) {
      navigate("/user-dashboard");
      // let a:any={show:true,msg:'Login Successfully'};
      // dispatch(callToaster(a))
    }
  }, [status, navigate]);

  return (
    <>
      {loading ? <Loader /> : ""}
      <div
        className="sr-multicol-media bg-white pt-1 pt-lg-4 text-center prototype-no-background 
      widget_1600979911688 "
      >
        <div className="container-fluid content-wrapper pt-2 pt-lg-4">
          <div
            className="row d-flex justify-content-center pt-1 pt-md-3 pt-lg-5"
            style={{ backgroundColor: "black", color: "white" }}
          >
            <div className="col-md-10 dashtext ">
              <Breadcrumbs textColor={"text-white"} />
            </div>
          </div>
        </div>
      </div>
      {/* <h2 
      className=" section-heading font-inter text-white text-center dash-heading" 
      style={{ backgroundColor: "black", color:'white' }} >
               
               
               </h2> */}
      <div className="bg-black d-flex justify-content-center ">
        <img
          src="images/logo.svg"
          alt="PlaneLists.com"
        />
      </div>
      <Formik
        initialValues={defaultValue}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {/* <Screen /> */}
        <div className="bg-body-tertiary d-flex flex-row gray-bg bg-black">
          <div className=" container">
            <div className="row justify-content-center py-md-3 ">
              <div className="col-md-10 mt-sm-5 p-0  login-container overflow-hidden">
                <div className="d-flex flex-column flex-md-row ">
                  <div className=" card p-2 m-3 m-sm-0 bg-black border">
                    <div className="card-body">
                      <Form>
                        <div className="p-md-1 p-lg-5 p-2">
                          <p
                            style={{
                              textAlign: "center",

                              color: "white",
                              fontSize: "1.5rem",
                              marginBottom: "2.25rem",
                              fontWeight: "bold",
                            }}
                          >
                            Try Plane<span className="themecolor">Lists</span>
                            .com <br />
                            <p className="mt-3">
                              <span style={{ color: "orange" }}>FREE </span> for
                              7 days!
                            </p>
                          </p>

                          <div className="input-group">
                            <span
                              className="input-group-text mobile-icon-size"
                              id="basic-addon1"
                            >
                              <FaUserAlt />
                            </span>
                            <Field
                              type="text"
                              className="form-control small"
                              placeholder="First Name"
                              aria-label="First Name"
                              aria-describedby="basic-addon1"
                              name="name"
                            />
                          </div>
                          <p className="text-danger field-errorMessage mb-3">
                            <ErrorMessage name="name" />
                          </p>

                          {/* <p className="text-danger field-errorMessage mb-3">
                            <ErrorMessage name="email" />
                          </p> */}
                          
                          <div className="input-group">
                            <span
                              className="input-group-text mobile-icon-size"
                              id="basic-addon1"
                            >
                              <FaUserAlt />
                            </span>
                            <Field
                              type="text"
                              className="form-control small"
                              placeholder="Last Name"
                              aria-label="Last Name"
                              aria-describedby="basic-addon1"
                              name="lastname"
                            />
                          </div>
                          <p className="text-danger field-errorMessage mb-3">
                            <ErrorMessage name="lastname" />
                          </p>
                          <div className="input-group">
                            <span
                              className="input-group-text mobile-icon-size"
                              id="basic-addon1"
                            >
                              <TfiEmail />
                            </span>
                            <Field
                              type="email"
                              name="email"
                              className="form-control small"
                              placeholder="Enter Your Email ID"
                              aria-label="Enter Your Email ID"
                              aria-describedby="basic-addon1"
                            />
                          </div>
                          <p className="text-danger field-errorMessage mb-3">
                            <ErrorMessage name="email" />
                          </p>

                          <div className="input-group">
                            <span
                              className="input-group-text mobile-icon-size"
                              id="basic-addon1"
                            >
                              <BiSolidLock />
                            </span>
                            <Field
                              type={showPassword ? "text" : "password"}
                              className="form-control small"
                              placeholder="Password"
                              aria-label="Password"
                              aria-describedby="basic-addon1"
                              name="password"
                            />
                            <div className="eye-icon-container">
                              {showPassword ? (
                                <img
                                  src="./images/open-eye.svg"
                                  className="eye-icon-size"
                                  onClick={toggleHidePassword}
                                />
                              ) : (
                                <img
                                  src="./images/close-eye.svg"
                                  className="eye-icon-size"
                                  onClick={toggleShowPassword}
                                />
                              )}
                            </div>
                          </div>
                          <p className="text-danger field-errorMessage mb-3">
                            <ErrorMessage name="password" />
                          </p>

                          <div className="input-group">
                            <span
                              className="input-group-text mobile-icon-size"
                              id="basic-addon1"
                            >
                              <BiSolidLock />
                            </span>
                            <Field
                              type={showPassword ? "text" : "password"}
                              className="form-control small"
                              placeholder="Confirm Password"
                              aria-label="Confirm Password"
                              aria-describedby="basic-addon2"
                              name="password_confirmation"
                            />
                            <div className="eye-icon-container">
                              {showPassword ? (
                                <img
                                  src="./images/open-eye.svg"
                                  className="eye-icon-size"
                                  onClick={toggleHidePassword}
                                />
                              ) : (
                                <img
                                  src="./images/close-eye.svg"
                                  className="eye-icon-size"
                                  onClick={toggleShowPassword}
                                />
                              )}
                            </div>
                          </div>
                          <p className="text-danger field-errorMessage mb-3">
                            <ErrorMessage name="password_confirmation" />
                          </p>
                          {/* <div className="input-group">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              <BiSolidCoupon />
                            </span>
                            <Field
                              type="text"
                              className="form-control small"
                              placeholder="Enter coupon code if any"
                              aria-label="Enter coupon code if any"
                              aria-describedby="basic-addon1"
                              name="coupon_code"
                            />
                          </div> */}
                          <div className="row mb-3  d-flex justify-content-center ">
                            <div
                              className="g-recaptcha col-12 ml-2"
                              data-theme="light"
                              data-sitekey="XXXXXXXXXXXXX"
                              // style={{
                              //   transform: "scale(0.77)",
                              //   WebkitTransform: "scale(0.77)",
                              //   transformOrigin: "0 0",
                              //   WebkitTransformOrigin: "0 0",
                              // }}
                            >
                              <ReCAPTCHA
                                sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
                                onChange={handleRecaptchaChange}
                              />
                            </div>

                            <Field
                              type="hidden"
                              className="form-control small"
                              name="tokencap"
                              value={token}
                              autoComplete="on"
                            />
                            {/* </div> */}
                          </div>
                          <p className="text-danger field-errorMessage mb-3">
                            <ErrorMessage name="tokencap" />
                          </p>

                          <div className="d-flex justify-content-center">
                            <button
                              type="submit"
                              className="btn btn-primary w-100 justify-content-center"
                              onClick={() => {
                                scrollToTop();
                              }}
                            >
                              <span className="text-white custom-btn-mobile">Register</span>
                            </button>
                          </div>
                          <div className="d-flex justify-content-lg-center     flex-md-column  flex-lg-row
    align-items-md-center col-12 pt-3">
                            <Link to="/login" onClick={scrollToTop}>
                              <p className="not-a-member text-white">
                                Already have an account?
                              </p>
                            </Link>
                            <Link to="/login" onClick={scrollToTop}>
                              <p className="register pl-1">Login</p>
                            </Link>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>

                  <div className=" d-none d-md-block bg-black border">
                    <img
                      src="images/HomePageImg/newRegisterImg.jpg"
                      style={{ height: "100%" }}
                      alt="Registration Image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Formik>
      <FreeTrial />
      <ToastContainer />
    </>
  );
};
