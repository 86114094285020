import "./forgetPassword.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { RetrieveUserIdPopUp } from "../RepeatedComponent/RetrieveUserIdPopUp";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../Login/login.css";
import { ToastContainer, toast } from "react-toastify";
import { useEffect, useState } from "react";
import { Loader } from "../RepeatedComponent/Loader";
import "react-toastify/dist/ReactToastify.css";
import { useAppDispatch, useAppSelector } from "../../../hook";
import { clearState, forgotPassword } from "../../../Features/authSlice";

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const status = useAppSelector((state: any) => state.auth.status);

  const [loading, setLoading] = useState(0);
  const [defaultValue, setDefaultValue] = useState({
    email: "",
  });
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required("Email is required")
      .email("Invalid email address"),
  });
  const handleSubmit = (values: any) => {
    setLoading(1);
    dispatch(forgotPassword(values)).then((res: any) => {
      if (res.payload.success) {
        toast.success(
          "Reset password instructions have been sent to your email.",
          {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 8000,
            pauseOnFocusLoss: true,
            theme: "dark",
            onClose: () => navigate("/login"),
          }
        );
      } else {
        toast.error("Email doesn't exist.", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 8000,
          pauseOnFocusLoss: true,
          theme: "dark",
        });
      }
      setLoading(0);
    }).catch((err) => console.log(err, "error in api net"));
  };

  useEffect(() => {
    if (status) {
      navigate("/user-dashboard");
      // let a:any={show:true,msg:'Login Successfully'};
      // dispatch(callToaster(a))
    }
  }, [status, navigate]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling animation
    });
  };

  return (
    <>
      {/* ForgotPassword */}
      {loading ? <Loader /> : ""}
      <RetrieveUserIdPopUp />
      <div className="col-12 pt-5  pb-5 d-flex justify-content-center forget-userId bg-black border border-top-0">
        <div className="d-flex justify-content-center flex-column">
          <h2
            className="section-heading font-inter text-white text-center dash-heading"
            style={{ backgroundColor: "black", color: "white" }}
          >
            Reset Password
          </h2>

          <p className="text-center sub-heading">
            Please enter your Email ID to begin retrieving your password.
          </p>
          <div>
            <p
              className="text-center sub-heading pb-4 forget-link"
              style={{ textDecoration: "underline", color: "#F16722" }}
              role="button"
              data-toggle="modal"
              data-target="#retrieveUserId"
            >
              Don't know your Email ID?
            </p>
          </div>

          <div className="">
            <Formik
              initialValues={defaultValue}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className="">
                  <div className="d-flex justify-content-center ">
                    <div className="col-12 col-md-12 col-lg-9 p-1">
                      <div className="input-group mb-1 ">
                        <span
                          className="input-group-text  col-2"
                          id="basic-addon1"
                        >
                          <img
                            src="/images/envelope icon.svg"
                            id="email-res-img"
                            alt=""
                          />
                        </span>
                        <Field
                          type="email"
                          className="form-control py-sm-4 py-md-4"
                          name="email"
                          placeholder="Enter Your Email ID"
                          aria-label="Enter Your Email ID"
                          aria-describedby="basic-addon1"
                          autoComplete="on"
                        />
                      </div>
                      <p className="text-danger field-errorMessage mb-3 text-left ">
                        <ErrorMessage name="email" />
                      </p>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center ">
                    <div className="col-12 col-md-12 col-lg-9 p-1 pb-0">
                      <button
                        type="submit"
                        className="btn btn-primary w-100 justify-content-center"
                        onClick={scrollToTop}
                      >
                        <span className="text-white custom-btn-mobile">
                          Continue
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
