import axios from "axios";

const Api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Add a request interceptor
Api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("_authToken");

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
// Add a response interceptor
Api.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      originalRequest.url ===
        `${process.env.REACT_APP_API_URL}/authentication_tokens/create`
    ) {
      window.location.href = "/login";
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      if (localStorage.getItem("_authToken")) {
        localStorage.removeItem("_authToken");
        window.location.href = "/login";
      } else {
        window.location.href = "/login";
        localStorage.removeItem("_authToken");
      }
      return axios(originalRequest).then((res: any) => {});
    }
  }
);
export default Api;
